import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import profileBptj from "../assets/images/profileBptj.png";
import profileDefault from "../assets/images/profileDefault.png";

const ProfileMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const { user } = useSelector((state) => state.Auth);

  return (
    <React.Fragment>
      <div className="profile-info">
        <p className="name">{user?.userName}</p>
        <p className="role">
          {user?.role === "super-admin"
            ? "Super Admin"
            : user?.role === "admin"
            ? "Admin"
            : user?.role === "visitor"
            ? "Supervisor"
            : user?.role || ""}
        </p>
      </div>
      <Dropdown
        isOpen={isOpen}
        toggle={toggleDropdown}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="header-profile-user border"
            src={
              user?.role === "super-admin"
                ? profileBptj
                : user?.role === "visitor"
                ? profileDefault
                : user.institusi?.logo || profileDefault
            }
            alt="Logo"
          />
        </DropdownToggle>
        <DropdownMenu right>
          <p className="dropdown-item">
            <i className="mdi mdi-email-outline font-size-17 align-middle mr-1"></i>
            <span>{user.email}</span>
          </p>
          <Link to="/logout" className="dropdown-item">
            <i className="mdi mdi-logout font-size-17 align-middle mr-1"></i>
            <span>Logout</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileMenu;
