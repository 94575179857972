export const GET_DETAIL_REQUEST = "GET_DETAIL_REQUEST";
export const GET_DETAIL_SUCCESS = "GET_DETAIL_SUCCESS";
export const GET_DETAIL_FAILURE = "GET_DETAIL_FAILURE";
export const GET_PREVIEW_DETAIL_REQUEST = "GET_PREVIEW_DETAIL_REQUEST";
export const GET_PREVIEW_DETAIL_SUCCESS = "GET_PREVIEW_DETAIL_SUCCESS";
export const GET_PREVIEW_DETAIL_FAILURE = "GET_PREVIEW_DETAIL_FAILURE";
export const CREATE_ACTIVITY_STATUS_REQUEST = "CREATE_ACTIVITY_STATUS_REQUEST";
export const CREATE_ACTIVITY_STATUS_SUCCESS = "CREATE_ACTIVITY_STATUS_SUCCESS";
export const CREATE_ACTIVITY_STATUS_FAILURE = "CREATE_ACTIVITY_STATUS_FAILURE";
export const UPDATE_ACTIVITY_STATUS_REQUEST = "UPDATE_ACTIVITY_STATUS_REQUEST";
export const UPDATE_ACTIVITY_STATUS_SUCCESS = "UPDATE_ACTIVITY_STATUS_SUCCESS";
export const UPDATE_ACTIVITY_STATUS_FAILURE = "UPDATE_ACTIVITY_STATUS_FAILURE";
export const DELETE_ACTIVITY_STATUS_REQUEST = "DELETE_ACTIVITY_STATUS_REQUEST";
export const DELETE_ACTIVITY_STATUS_SUCCESS = "DELETE_ACTIVITY_STATUS_SUCCESS";
export const DELETE_ACTIVITY_STATUS_FAILURE = "DELETE_ACTIVITY_STATUS_FAILURE";
export const GET_REPORT_REQUEST = "GET_REPORT_REQUEST";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAILURE = "GET_REPORT_FAILURE";
export const TOGGLE_YEAR_REQUEST = "TOGGLE_YEAR_REQUEST";
export const TOGGLE_YEAR_SUCCESS = "TOGGLE_YEAR_SUCCESS";
export const TOGGLE_YEAR_FAILURE = "TOGGLE_YEAR_FAILURE";
