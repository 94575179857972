import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useFilterPilarOptions, useFilterInChangeOptions } from "../../hooks";
import {
  activityColors,
  color,
  customColorSelect,
} from "../../helpers/colors";
import { useSelector, useDispatch } from "react-redux";
import { getLocationsRequest } from "../../store/actions";
import { GMapsLocations, BlockSpinner, GMapsCardMultiple } from "../../components";
import { Modal, Spinner, Collapse } from "reactstrap";
import { getPreviewDetailRequest } from "../../store/actions";
import moment from "moment";
import { formatCurrency } from "../../helpers/utils";
import { BudgetGrouping } from "../Detail"
import "moment/locale/id"; // without this line it didn't work
moment.locale("id");

const Location = () => {
  // HOOKS
  const dispatch = useDispatch();

  // LOCAL STATE
  const defaultFilter = {
    pilar: { value: "all", label: "Semua Pilar" },
    inCharge: {
      value: "all",
      label: "Semua Penanggung Jawab",
    },
  };
  const [filter, setFilter] = useState(defaultFilter);
  const filterPilarOptions = useFilterPilarOptions(true);
  const filterInChargeOptions = useFilterInChangeOptions(true);
  const [locationData, setLocationData] = useState([]);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [previewData, setPreviewData] = useState(null);

  // GLOBAL STATE
  const { isLoading: isLoadingInstitution } = useSelector(
    (state) => state.Institution
  );
  const { locations, isLoading } = useSelector((state) => state.Location);
  const { user } = useSelector((state) => state.Auth);

  const { isLoadingPreview } = useSelector((state) => state.Detail);

  // FUNCTIONS
  const onChangeFilter = (option, { name }) => {
    setFilter((prev) => ({ ...prev, [name]: option || defaultFilter[name] }));
  };

  const getAndSetPreviewDetail = (_id) => {
    dispatch(
      getPreviewDetailRequest(_id, (err, resp) => {
        if (err) {
          // error
        } else {
          setPreviewData(resp);
        }
      })
    );
  };

  const togglePreviewDetail = (_id) => () => {
    if (_id) {
      setIsOpenPreview(true);
      getAndSetPreviewDetail(_id);
    } else {
      setIsOpenPreview(false);
      setPreviewData(null);
    }
  };

  const onClickDetailActivity = (_id) => () => {
    togglePreviewDetail(_id)();
  };

  // LIFECYCLE
  useEffect(() => {
    const { pilar, inCharge } = filter;
    dispatch(
      getLocationsRequest({
        pilar: pilar.value,
        inCharge: inCharge.value !== "all" ? inCharge.value : false,
      })
    );
  }, [filter]); // eslint-disable-line

  useEffect(() => {
    const newLocations = locations?.map((location) => {
      const result = {
        inChargeName: location?.inCharge?.name,
        pilarName: location?.pilar?.name,
        strategyName: location?.strategy?.name,
        programName: location?.program?.name,
        activityName: location?.activity?.name,
        status: location?.status?.status,
        detailId: location?._id,
      };
      if (location?.status?.locations?.[0]) {
        result.latLng = {
          lat: location?.status?.locations?.[0]?.latitude,
          lng: location?.status?.locations?.[0]?.longitude,
        };
      } else {
        let latLng = null;
        location?.implementations?.forEach(({ progress }) => {
          progress.forEach(({ locations }) => {
            if (locations?.[0]) {
              latLng = {
                lat: locations?.[0]?.latitude,
                lng: locations?.[0]?.longitude,
              };
            }
          });
        });
        result.latLng = latLng;
      }
      return result;
    });
    newLocations?.filter(({ latLng }) => Boolean(latLng));
    setLocationData(newLocations);
  }, [locations]);

  const ModalPreview = () => {
    const [isOpenDownload, setIsOpenDownload] = useState(false);
    const [documents, setDocuments] = useState([]);

    const toggleDownload = (documents) => () => {
      // console.log(documents);
      if (documents) {
        setDocuments(documents);
        setIsOpenDownload(true);
      } else {
        setDocuments([]);
        setIsOpenDownload(false);
      }
    };

    return (
      <Modal
        className="modal-xl"
        isOpen={isOpenPreview}
        toggle={togglePreviewDetail(null)}
        fade={false}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Preview Status Kegiatan
          </h5>
          <div>
            <button
              onClick={togglePreviewDetail(null)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          {isLoadingPreview ? (
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                height: "80vh",
                background: "white",
                zIndex: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner size="xl" />
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-4 mb-5 border-right">
                <p className="text-bold">Penanggung Jawab</p>
                <p>{previewData?.inCharge?.name}</p>

                <p className="text-bold mt-4">Pilar 1</p>
                <p>{previewData?.pilar?.name}</p>

                <p className="text-bold mt-4">Strategi</p>
                <p>{previewData?.strategy?.name}</p>

                <p className="text-bold mt-4">Program</p>
                <p>{previewData?.program?.name}</p>

                <p className="text-bold mt-4">Kegiatan</p>
                <p>{previewData?.activity?.name}</p>
              </div>

              <div className="col-lg-4 mb-5 border-left border-right">
                {!previewData?.implementations.filter(
                  (val) => val.progress.length
                ).length && (
                  <p className="text-bold">Belum ada progress kegiatan</p>
                )}
                {previewData?.implementations
                  .filter((val) => val.progress.length)
                  .sort((a, b) => b.year - a.year)
                  .map((yearImplementation, idx, yearImplementationRow) => {
                    const ProgressList = () => {
                      const [isOpenCollapse, setIsOpenCollapse] =
                        useState(false);
                      return (
                        <div key={idx}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            className={`${
                              isOpenCollapse ? "none" : "border-bottom"
                            }`}
                          >
                            <p className="text-bold">{`Tahun ${yearImplementation.year}`}</p>
                            <i
                              className={
                                isOpenCollapse
                                  ? "ion ion-md-arrow-dropdown"
                                  : "ion ion-md-arrow-dropright"
                              }
                              style={{
                                fontSize: 30,
                                color: color.primary,
                                cursor: "pointer",
                                padding: "10px 10px",
                              }}
                              onClick={() => setIsOpenCollapse(!isOpenCollapse)}
                            />
                          </div>
                          {yearImplementation.progress
                            .sort(
                              (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                            )
                            .filter(
                              (a) =>
                                a.status === "Perencanaan" ||
                                a.status === "Pelaksanaan" ||
                                a.status === "Selesai"
                            )
                            .map((progress, progressIndex, progressRow) => {
                              const { status } = progress;
                              const calculateBudged = {
                                APBN: { plan: 0, real: 0 },
                                APBD: { plan: 0, real: 0 },
                                Swasta: { plan: 0, real: 0 },
                                Lainnya: { plan: 0, real: 0 },
                                Total: { plan: 0, real: 0 },
                              };
                              progress?.budgets.forEach((budged) => {
                                const { name, planing, realization } = budged;
                                calculateBudged[name].plan += planing;
                                calculateBudged[name].real += realization;
                                calculateBudged.Total.plan += planing;
                                calculateBudged.Total.real += realization;
                              });
                              return (
                                <Collapse isOpen={isOpenCollapse}>
                                  <div key={progressIndex} className="">
                                    <div className="d-flex justify-content-between align-items-center border-top border-bottom pt-1 pb-1">
                                      <div className="d-flex align-items-center">
                                        <i
                                          className="mdi mdi-bookmark mdi-24px mr-2 mt-1 mb-1"
                                          style={{
                                            color:
                                              activityColors[
                                                status === "Pelaksanaan"
                                                  ? 1
                                                  : status === "Selesai"
                                                  ? 2
                                                  : 0
                                              ],
                                          }}
                                        />
                                        <p>{progress.status}</p>
                                      </div>
                                    </div>
                                    {progress?.updateBy?.userName && (
                                      <p
                                        style={{
                                          fontSize: 13,
                                          color: color.primary,
                                        }}
                                      >
                                        {`Telah diperbaharui oleh ${progress?.updateBy?.userName}`}
                                        <br />
                                        {`Tanggal : ${moment(
                                          progress?.updatedAt
                                        )
                                          .local("id")
                                          .format("LL")}`}
                                        <br />
                                        {`Jam : ${moment(
                                          progress?.updatedAt
                                        ).format("LT")}`}
                                      </p>
                                    )}
                                    <p className="text-bold mt-3">
                                      Penjelasan Status dan Kendala
                                    </p>
                                    <p>{progress?.description || "-"}</p>

                                    <p className="text-bold mt-3 mb-2">
                                      Jumlah Anggaran (dalam Juta)
                                    </p>
                                    <table className="w-100">
                                      <thead>
                                        <tr
                                          style={{ height: 52 }}
                                          className="border-top border-bottom"
                                        >
                                          <th>Sumber</th>
                                          <th className="text-center">
                                            Rencana
                                          </th>
                                          <th className="text-center">
                                            Realisasi
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr style={{ height: 39 }}>
                                          <td>APBN</td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.APBN.plan
                                            )}
                                          </td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.APBN.real
                                            )}
                                          </td>
                                        </tr>
                                        <tr style={{ height: 39 }}>
                                          <td>APBD</td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.APBD.plan
                                            )}
                                          </td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.APBD.real
                                            )}
                                          </td>
                                        </tr>
                                        <tr style={{ height: 39 }}>
                                          <td>Swasta</td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.Swasta.plan
                                            )}
                                          </td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.Swasta.real
                                            )}
                                          </td>
                                        </tr>
                                        <tr style={{ height: 39 }}>
                                          <td>Lainnya</td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.Lainnya.plan
                                            )}
                                          </td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.Lainnya.real
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                      <thead>
                                        <tr style={{ height: 39 }}>
                                          <th>Total</th>
                                          <th className="text-center">
                                            {calculateBudged.Total.plan}
                                          </th>
                                          <th className="text-center">
                                            {calculateBudged.Total.real}
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                    <p className="text-bold mt-3">
                                      Lokasi Kegiatan
                                    </p>
                                    {progress?.isPinPoint &&
                                    progress?.locations?.length > 0 ? (
                                      <div
                                        style={{
                                          width: "100%",
                                          paddingTop: "56.25%",
                                          position: "relative",
                                          borderRadius: 6,
                                        }}
                                        className="mt-2 mb-2"
                                      >
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                          }}
                                        >
                                          <GMapsCardMultiple locations={progress?.locations} />
                                        </div>
                                      </div>
                                    ) : (
                                      progress?.noteLocation || "-"
                                    )}
                                    {progress?.documents?.length > 0 && (
                                      <button
                                        className="btn mt-2"
                                        style={{
                                          color: "white",
                                          backgroundColor: color.danger,
                                          height: 48,
                                          width: "100%",
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                        }}
                                        onClick={toggleDownload(
                                          progress?.documents
                                        )}
                                      >
                                        <p>Download Dokumen</p>
                                      </button>
                                    )}
                                    <div
                                      className="border-bottom"
                                      style={{
                                        marginTop: 35,
                                        marginLeft: -13,
                                        marginRight: -13,
                                      }}
                                    />
                                  </div>
                                </Collapse>
                              );
                            })}
                        </div>
                      );
                    };
                    return <ProgressList />;
                  })}
              </div>

              <div className="col-lg-4 mb-5 border-left">
                <p className="text-bold mb-3">
                  Akumulasi Anggaran Kegiatan (dalam Juta)
                </p>
                <BudgetGrouping previewData={previewData} />
              </div>
            </div>
          )}
        </div>
        <Modal
          className="modal-md"
          isOpen={isOpenDownload}
          toggle={toggleDownload(null)}
          fade={false}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Download dokumen
            </h5>
            <div>
              <button
                onClick={toggleDownload(null)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            {documents.map((docx, idx) => (
              <div
                key={idx}
                style={{
                  backgroundColor: "#f6f6f6",
                  padding: 14,
                  borderRadius: 6,
                  display: "flex",
                  alignItems: "center",
                  marginTop: 18,
                }}
              >
                <p
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginRight: 14,
                  }}
                >
                  {docx.fileUrl.replace(/^.*\/\/[^/]+\/files\//, "")}
                </p>
                <a
                  href={docx.fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={true}
                >
                  <i
                    className="fas fa-file-download mt-1"
                    style={{ color: color.danger, fontSize: 20 }}
                  />
                </a>
              </div>
            ))}
          </div>
        </Modal>
      </Modal>
    );
  };

  return (
    <>
      <div className="actions-group">
        <div className="action-container">
          <p>Filter by :</p>
          <Select
            name="pilar"
            className="action filter-pilar-width mr-2"
            placeholder="Pilar"
            value={filter.pilar}
            onChange={onChangeFilter}
            options={filterPilarOptions}
            isClearable={filter.pilar?.value !== "all"}
            styles={customColorSelect}
          />
          {user?.role !== "admin" && (
            <Select
              name="inCharge"
              className="action filter-in-change-width mr-2"
              placeholder="Penanggung Jawab"
              value={filter.inCharge}
              onChange={onChangeFilter}
              options={filterInChargeOptions}
              isClearable={filter.inCharge?.value !== "all"}
              styles={customColorSelect}
              isLoading={isLoadingInstitution}
            />
          )}
        </div>
      </div>
      <div
        style={{
          width: "100%",
          paddingTop: "50%",
          position: "relative",
          borderRadius: 6,
        }}
        className="mt-2 mb-2"
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <GMapsLocations
            locations={locationData}
            onClickDetailActivity={onClickDetailActivity}
          />
          <BlockSpinner show={isLoading} />
        </div>
      </div>
      <ModalPreview />
    </>
  );
};

export default Location;
