import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import MetisMenu from "metismenujs";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";

const SidebarContent = (props) => {
  const { user } = useSelector((state) => state.Auth);
  return (
    <div id="sidebar-menu">
      <ul className="metismenu list-unstyled" id="side-menu">
        <li>
          <Link to="/overview" className="waves-effect">
            <i className="fas fa-chart-pie"></i>
            <span>Overview</span>
          </Link>
        </li>

        <li>
          <Link to="/detail" className="waves-effect">
            <i className="fas fa-list-alt"></i>
            <span>Detail</span>
          </Link>
        </li>
        
        <li>
          <Link to="/location" className="waves-effect">
            <i className="fas fa-map-marked-alt"></i>
            <span>Peta</span>
          </Link>
        </li>

        {user?.role === "super-admin" && <>
          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="fas fa-server"></i>
              <span>Master Data</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/iku">IKU</Link>
              </li>
              <li>
                <Link to="/pilar">Pilar</Link>
              </li>
              <li>
                <Link to="/strategy">Strategi</Link>
              </li>
              <li>
                <Link to="/program">Program</Link>
              </li>
              <li>
                <Link to="/activity">Kegiatan</Link>
              </li>
              <li>
                <Link to="/assignment">Penugasan</Link>
              </li>
            </ul>
          </li>

          <li>
            <Link to="/#" className="has-arrow waves-effect">
              <i className="fas fa-user-friends"></i>
              <span>Master Admin</span>
            </Link>
            <ul className="sub-menu">
              <li>
                <Link to="/in-charge">Penanggung Jawab</Link>
              </li>
              <li>
                <Link to="/account">Akun</Link>
              </li>
            </ul>
          </li>
        </>}

        <li>
          <Link to="/help" className="waves-effect">
            <i className="fas fa-question-circle"></i>
            <span>Bantuan</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (this.props.type !== prevProps.type) {
      this.initMenu();
    }
  }

  initMenu() {
    if (this.props.type !== "condensed" || this.props.isMobile) {
      new MetisMenu("#side-menu");

      var matchingMenuItem = null;
      var ul = document.getElementById("side-menu");
      var items = ul.getElementsByTagName("a");
      for (var i = 0; i < items.length; ++i) {
        if (this.props.location.pathname === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        this.activateParentDropdown(matchingMenuItem);
      }
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add("mm-active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active"); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <React.Fragment>
        {this.props.type !== "condensed" ? (
          <SimpleBar style={{ maxHeight: "100%" }}>
            <SidebarContent />
          </SimpleBar>
        ) : (
          <SidebarContent />
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(Sidebar);
