import {
  GET_PILARS_REQUEST,
  GET_PILARS_SUCCESS,
  GET_PILARS_FAILURE,
  CREATE_PILAR_REQUEST,
  CREATE_PILAR_SUCCESS,
  CREATE_PILAR_FAILURE,
  UPDATE_PILAR_REQUEST,
  UPDATE_PILAR_SUCCESS,
  UPDATE_PILAR_FAILURE,
  DELETE_PILAR_REQUEST,
  DELETE_PILAR_SUCCESS,
  DELETE_PILAR_FAILURE,
  CREATE_STRATEGY_REQUEST,
  CREATE_STRATEGY_SUCCESS,
  CREATE_STRATEGY_FAILURE,
  UPDATE_STRATEGY_REQUEST,
  UPDATE_STRATEGY_SUCCESS,
  UPDATE_STRATEGY_FAILURE,
  DELETE_STRATEGY_REQUEST,
  DELETE_STRATEGY_SUCCESS,
  DELETE_STRATEGY_FAILURE,
  CREATE_PROGRAM_REQUEST,
  CREATE_PROGRAM_SUCCESS,
  CREATE_PROGRAM_FAILURE,
  UPDATE_PROGRAM_REQUEST,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_FAILURE,
  DELETE_PROGRAM_REQUEST,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_FAILURE,
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAILURE,
  CREATE_ASSIGNMENT_REQUEST,
  CREATE_ASSIGNMENT_SUCCESS,
  CREATE_ASSIGNMENT_FAILURE,
  UPDATE_ASSIGNMENT_REQUEST,
  UPDATE_ASSIGNMENT_SUCCESS,
  UPDATE_ASSIGNMENT_FAILURE,
  DELETE_ASSIGNMENT_REQUEST,
  DELETE_ASSIGNMENT_SUCCESS,
  DELETE_ASSIGNMENT_FAILURE,
  GET_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAILURE,
} from "./actionTypes";

/* GET_ALL_PILAR */
export const getPilarsRequest = () => ({
  type: GET_PILARS_REQUEST,
});

export const getPilarsSuccess = (response) => ({
  type: GET_PILARS_SUCCESS,
  payload: response,
});

export const getPilarsFailure = (error) => ({
  type: GET_PILARS_FAILURE,
  payload: error,
});

/* CREATE_PILAR */
export const createPilarRequest = (pilarData, callback) => ({
  type: CREATE_PILAR_REQUEST,
  payload: { pilarData, callback },
});

export const createPilarSuccess = (response) => ({
  type: CREATE_PILAR_SUCCESS,
  payload: response,
});

export const createPilarFailure = (error) => ({
  type: CREATE_PILAR_FAILURE,
  payload: error,
});

/* UPDATE_PILAR */
export const updatePilarRequest = (_id, pilarData, callback) => ({
  type: UPDATE_PILAR_REQUEST,
  payload: { _id, pilarData, callback },
});

export const updatePilarSuccess = (response) => ({
  type: UPDATE_PILAR_SUCCESS,
  payload: response,
});

export const updatePilarFailure = (error) => ({
  type: UPDATE_PILAR_FAILURE,
  payload: error,
});

/* DELETE_PILAR */
export const deletePilarRequest = (_id, callback) => ({
  type: DELETE_PILAR_REQUEST,
  payload: { _id, callback },
});

export const deletePilarSuccess = (response) => ({
  type: DELETE_PILAR_SUCCESS,
  payload: response,
});

export const deletePilarFailure = (error) => ({
  type: DELETE_PILAR_FAILURE,
  payload: error,
});

/* CREATE_STRATEGY */
export const createStrategyRequest = (strategyData, callback) => ({
  type: CREATE_STRATEGY_REQUEST,
  payload: { strategyData, callback },
});

export const createStrategySuccess = (response) => ({
  type: CREATE_STRATEGY_SUCCESS,
  payload: response,
});

export const createStrategyFailure = (error) => ({
  type: CREATE_STRATEGY_FAILURE,
  payload: error,
});

/* UPDATE_STRATEGY */
export const updateStrategyRequest = (_id, strategyData, callback) => ({
  type: UPDATE_STRATEGY_REQUEST,
  payload: { _id, strategyData, callback },
});

export const updateStrategySuccess = (response) => ({
  type: UPDATE_STRATEGY_SUCCESS,
  payload: response,
});

export const updateStrategyFailure = (error) => ({
  type: UPDATE_STRATEGY_FAILURE,
  payload: error,
});

/* DELETE_STRATEGY */
export const deleteStrategyRequest = (_id, callback) => ({
  type: DELETE_STRATEGY_REQUEST,
  payload: { _id, callback },
});

export const deleteStrategySuccess = (response) => ({
  type: DELETE_STRATEGY_SUCCESS,
  payload: response,
});

export const deleteStrategyFailure = (error) => ({
  type: DELETE_STRATEGY_FAILURE,
  payload: error,
});

/* CREATE_PROGRAM */
export const createProgramRequest = (programData, callback) => ({
  type: CREATE_PROGRAM_REQUEST,
  payload: { programData, callback },
});

export const createProgramSuccess = (response) => ({
  type: CREATE_PROGRAM_SUCCESS,
  payload: response,
});

export const createProgramFailure = (error) => ({
  type: CREATE_PROGRAM_FAILURE,
  payload: error,
});

/* UPDATE_PROGRAM */
export const updateProgramRequest = (_id, programData, callback) => ({
  type: UPDATE_PROGRAM_REQUEST,
  payload: { _id, programData, callback },
});

export const updateProgramSuccess = (response) => ({
  type: UPDATE_PROGRAM_SUCCESS,
  payload: response,
});

export const updateProgramFailure = (error) => ({
  type: UPDATE_PROGRAM_FAILURE,
  payload: error,
});

/* DELETE_PROGRAM */
export const deleteProgramRequest = (_id, callback) => ({
  type: DELETE_PROGRAM_REQUEST,
  payload: { _id, callback },
});

export const deleteProgramSuccess = (response) => ({
  type: DELETE_PROGRAM_SUCCESS,
  payload: response,
});

export const deleteProgramFailure = (error) => ({
  type: DELETE_PROGRAM_FAILURE,
  payload: error,
});

/* CREATE_ACTIVITY */
export const createActivityRequest = (activityData, callback) => ({
  type: CREATE_ACTIVITY_REQUEST,
  payload: { activityData, callback },
});

export const createActivitySuccess = (response) => ({
  type: CREATE_ACTIVITY_SUCCESS,
  payload: response,
});

export const createActivityFailure = (error) => ({
  type: CREATE_ACTIVITY_FAILURE,
  payload: error,
});

/* UPDATE_ACTIVITY */
export const updateActivityRequest = (_id, activityData, callback) => ({
  type: UPDATE_ACTIVITY_REQUEST,
  payload: { _id, activityData, callback },
});

export const updateActivitySuccess = (response) => ({
  type: UPDATE_ACTIVITY_SUCCESS,
  payload: response,
});

export const updateActivityFailure = (error) => ({
  type: UPDATE_ACTIVITY_FAILURE,
  payload: error,
});

/* DELETE_ACTIVITY */
export const deleteActivityRequest = (_id, callback) => ({
  type: DELETE_ACTIVITY_REQUEST,
  payload: { _id, callback },
});

export const deleteActivitySuccess = (response) => ({
  type: DELETE_ACTIVITY_SUCCESS,
  payload: response,
});

export const deleteActivityFailure = (error) => ({
  type: DELETE_ACTIVITY_FAILURE,
  payload: error,
});

/* CREATE_ASSIGNMENT */
export const createAssignmentRequest = (assignmentData, callback) => ({
  type: CREATE_ASSIGNMENT_REQUEST,
  payload: { assignmentData, callback },
});

export const createAssignmentSuccess = (response) => ({
  type: CREATE_ASSIGNMENT_SUCCESS,
  payload: response,
});

export const createAssignmentFailure = (error) => ({
  type: CREATE_ASSIGNMENT_FAILURE,
  payload: error,
});

/* UPDATE_ASSIGNMENT */
export const updateAssignmentRequest = (_id, assignmentData, callback) => ({
  type: UPDATE_ASSIGNMENT_REQUEST,
  payload: { _id, assignmentData, callback },
});

export const updateAssignmentSuccess = (response) => ({
  type: UPDATE_ASSIGNMENT_SUCCESS,
  payload: response,
});

export const updateAssignmentFailure = (error) => ({
  type: UPDATE_ASSIGNMENT_FAILURE,
  payload: error,
});

/* DELETE_ASSIGNMENT */
export const deleteAssignmentRequest = (_id, callback) => ({
  type: DELETE_ASSIGNMENT_REQUEST,
  payload: { _id, callback },
});

export const deleteAssignmentSuccess = (response) => ({
  type: DELETE_ASSIGNMENT_SUCCESS,
  payload: response,
});

export const deleteAssignmentFailure = (error) => ({
  type: DELETE_ASSIGNMENT_FAILURE,
  payload: error,
});

/* GET_ACTIVITIES */
export const getActivitiesRequest = (_id, callback) => ({
  type: GET_ACTIVITIES_REQUEST,
  payload: { _id, callback },
});

export const getActivitiesSuccess = (response) => ({
  type: GET_ACTIVITIES_SUCCESS,
  payload: response,
});

export const getActivitiesFailure = (error) => ({
  type: GET_ACTIVITIES_FAILURE,
  payload: error,
});
