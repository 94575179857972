import React, { useState, useEffect } from "react";
import Select from "react-select";
import { CustomInput, Spinner } from "reactstrap";
import { TableFormInCharge } from "../../components";
import { customColorSelect } from "../../helpers/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  getPilarsRequest,
  getInstitutionsRequest,
  getActivitiesRequest,
  createAssignmentRequest,
  updateAssignmentRequest,
  deleteAssignmentRequest,
} from "../../store/actions";
import ReactTooltip from "react-tooltip";

const Assignment = () => {
  /* HOOKS */
  const dispatch = useDispatch();

  /* LOCAL STATE */
  const [isEdit, setIsEdit] = useState(null);
  const [selectedPilar, setSelectedPilar] = useState(null);
  const [pilarOptions, setPilarOptions] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [strategyOptions, setStrategyOptions] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programOptions, setProgramOptions] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [activityOptions, setActivityOptions] = useState([]);
  const [activityInChargeData, setActivityInChargeData] = useState([]);
  const [selectedYears, setSelectedYears] = useState([]);
  const years = [
    2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
  ];
  const [disabledYears, setDisabledYears] = useState([]);
  const [selectedInCharge, setSelectedInCharge] = useState([]);
  const [inCharges, setInCharges] = useState([]);
  const [disabledInCharges, setDisabledInCharges] = useState([]);

  /* GLOBAL_STATE */
  const { pilars, isLoading } = useSelector((state) => state.MasterData);
  const { institutions, isLoading: isLoadingInCharge } = useSelector(
    (state) => state.Institution
  );

  /* FUNCTIONS */
  const onChangeSelect = (value, { name, action }) => {
    if (name === "pilar") {
      setSelectedPilar(value);
    } else if (name === "strategy") {
      setSelectedStrategy(value);
    } else if (name === "program") {
      setSelectedProgram(value);
    } else if ((name = "activity")) {
      setSelectedActivity(value);
    }

    if (action === "clear") {
      onClearCheckBox();
    }
  };

  const onClickEdit = (inCharge) => {
    setIsEdit(inCharge._id);
    setSelectedInCharge([inCharge.inCharge._id]);
    const newSelectedYears = inCharge.implementations.map(({ year }) => year);
    const newDisabledYears = inCharge.implementations
      .filter(({ progress }) => progress.length)
      .map(({ year }) => year);
    setDisabledYears(newDisabledYears);
    setSelectedYears(newSelectedYears);
  };

  const onClickDelete = (_id) => {
    dispatch(
      deleteAssignmentRequest(_id, (err, resp) => {
        if (err) {
          // error
        } else {
          getActivitiesById(selectedActivity.value);
        }
      })
    );
  };

  const onClickSubmit = () => {
    if (isEdit) {
      const assignmentData = {
        years: selectedYears,
      };
      dispatch(
        updateAssignmentRequest(isEdit, assignmentData, (err, resp) => {
          if (err) {
            // error
          } else {
            setIsEdit(null);
            onClearCheckBox();
            getActivitiesById(selectedActivity.value);
          }
        })
      );
    } else {
      const assignmentData = {
        pilar: selectedPilar.value,
        strategy: selectedStrategy.value,
        program: selectedProgram.value,
        activity: selectedActivity.value,
        inCharges: selectedInCharge,
        years: selectedYears,
      };
      dispatch(
        createAssignmentRequest(assignmentData, (err, resp) => {
          if (err) {
            // err alert
          } else {
            onClearCheckBox();
            getActivitiesById(selectedActivity.value);
          }
        })
      );
    }
  };

  const onCLickCancel = () => {
    setIsEdit(null);
    onClearCheckBox();
  };

  const onClearCheckBox = () => {
    // setIsEdit(null);
    setSelectedYears([]);
    setDisabledYears([]);
    setSelectedInCharge([]);
  };

  const onChangeCheckBox = ({ target }) => {
    const { name, checked, id } = target;
    if (name === "year") {
      if (checked) {
        setSelectedYears((prev) => [...prev, Number(id)].sort());
      } else {
        setSelectedYears((prev) =>
          [...prev].filter((year) => year !== Number(id)).sort()
        );
      }
    } else if (name === "inCharge") {
      if (checked) {
        setSelectedInCharge((prev) => [...prev, id]);
      } else {
        setSelectedInCharge((prev) => [...prev].filter((_id) => _id !== id));
      }
    }
  };

  const getActivitiesById = (_id) => {
    dispatch(
      getActivitiesRequest(_id, (err, resp) => {
        if (err) {
          setActivityInChargeData([]);
        } else {
          setActivityInChargeData(resp);
          const newDisabledInCharges = resp.map(({ inCharge }) => inCharge._id);
          setDisabledInCharges(newDisabledInCharges);
        }
      })
    );
  };

  /* LIFECYCLE */
  useEffect(() => {
    dispatch(getInstitutionsRequest());
    dispatch(getPilarsRequest());
  }, []); // eslint-disable-line

  useEffect(() => {
    setInCharges(institutions);
  }, [institutions]);

  useEffect(() => {
    const newPilarOptions = pilars.map((pilar) => ({
      value: pilar._id,
      label: `PILAR ${pilar.pilarNumber} ${pilar.name}`,
      strategies: pilar.strategies,
    }));
    setPilarOptions(newPilarOptions);

    if (selectedPilar) {
      const pilarId = selectedPilar.value;
      const newSelectedPilar = newPilarOptions.find(
        (pilarOption) => pilarOption.value === pilarId
      );
      setSelectedPilar(newSelectedPilar);
    }
  }, [pilars]); // eslint-disable-line

  useEffect(() => {
    if (selectedPilar) {
      const newStrategyOptions = selectedPilar.strategies.map((strategy) => ({
        value: strategy._id,
        label: strategy.name,
        programs: strategy.programs,
      }));
      setStrategyOptions(newStrategyOptions);

      if (selectedStrategy) {
        const strategyId = selectedStrategy.value;
        const newSelectedStrategy = newStrategyOptions.find(
          (strategyOption) => strategyOption.value === strategyId
        );
        setSelectedStrategy(newSelectedStrategy);
      }
    } else {
      setStrategyOptions([]);
    }
    setSelectedStrategy(null);
  }, [selectedPilar]); // eslint-disable-line

  useEffect(() => {
    if (selectedStrategy) {
      const newProgramOptions = selectedStrategy.programs.map((program) => ({
        value: program._id,
        label: program.name,
        activities: program.activities,
      }));
      setProgramOptions(newProgramOptions);

      if (selectedProgram) {
        const programId = selectedProgram.value;
        const newSelectedProgram = newProgramOptions.find(
          (programOption) => programOption.value === programId
        );
        setSelectedProgram(newSelectedProgram);
      }
    } else {
      setProgramOptions([]);
    }
    setSelectedProgram(null);
  }, [selectedStrategy]); // eslint-disable-line

  useEffect(() => {
    if (selectedProgram) {
      const newActivityOptions = selectedProgram.activities.map((activity) => ({
        value: activity._id,
        label: activity.name,
        // inCharges: activity.inCharge,
      }));
      setActivityOptions(newActivityOptions);
    } else {
      setActivityOptions([]);
    }
    setSelectedActivity(null);
  }, [selectedProgram]);

  useEffect(() => {
    if (selectedActivity) {
      getActivitiesById(selectedActivity.value);
    } else {
      setActivityInChargeData([]);
      setDisabledInCharges([]);
    }
  }, [selectedActivity]); // eslint-disable-line

  const Tooltip = () => (
    <ReactTooltip
      // className="kpi-tooltip"
      place="top"
      effect="float"
      offset={{ top: -9 }}
    />
  );

  return (
    <>
      <Tooltip />
      <p className="page-title mb-4">
        {isEdit && "Edit "}Penugasan Penanggung Jawab
      </p>
      <div className="row">
        <div className="col-xs-12 col-md-7 col-xl-5 border-right form">
          <Select
            name="pilar"
            className="mb-3"
            placeholder="Pilih Pilar"
            isDisabled={isEdit}
            value={selectedPilar}
            options={pilarOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
          />
          <Select
            name="strategy"
            className="mb-3"
            placeholder="Pilih Strategi"
            isDisabled={isEdit || !selectedPilar}
            // isDisabled={!selectedPilar}
            value={selectedStrategy}
            options={strategyOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
          />
          <Select
            name="program"
            className="mb-3"
            placeholder="Pilih Program"
            isDisabled={isEdit || !selectedStrategy}
            // isDisabled={!selectedStrategy}
            value={selectedProgram}
            options={programOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
          />
          <Select
            name="activity"
            className="mb-3"
            placeholder="Pilih Kegiatan"
            isDisabled={isEdit || !selectedProgram}
            // isDisabled={!selectedProgram}
            value={selectedActivity}
            options={activityOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
          />
        </div>
        <div className="col-xs-12 col-md-5 col-xl-5 border-left pl-4">
          <div className="row">
            <p>Pilih Tahun Pelaksanaan</p>
          </div>
          <div className="row">
            {[0, 1, 2].map((multiple) => {
              const limit = 4;
              const start = limit * multiple;
              const end = start + limit;
              return (
                <div key={multiple} className="col-4" style={{ zIndex: 0 }}>
                  {years.slice(start, end).map((year) => (
                    <div
                      data-tip={
                        !isEdit && !selectedActivity
                          ? "Silakan pilih kegiatan terlebih dahulu"
                          : disabledYears.find((val) => val === year)
                          ? "Tidak bisa menghapus tahun yang telah memiliki progress"
                          : ""
                      }
                      data-class="year-tooltip"
                    >
                      <CustomInput
                        key={year}
                        name="year"
                        type="checkbox"
                        className="mb-2"
                        onChange={onChangeCheckBox}
                        checked={
                          [...selectedYears].filter((val) => val === year)
                            .length
                        }
                        id={year}
                        label={<p>{year.toString()}</p>}
                        disabled={
                          !selectedActivity ||
                          disabledYears.find((val) => val === year)
                        }
                      />
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="row p-2 mt-3">
        <p className="col-12">Pilih Penanggung Jawab</p>
        {isLoadingInCharge ? (
          <div className="col-12 d-flex justify-content-center">
            <Spinner />
          </div>
        ) : (
          // <div className="col-10 row">
          <>
            {[0, 1, 2].map((num) => {
              const limit = Math.ceil(inCharges.length / 3);
              const start = limit * num;
              const end = start + limit;
              return (
                <div key={num} className="col-sm-4 col-6" style={{ zIndex: 0 }}>
                  {inCharges.slice(start, end).map(({ _id, name }) => (
                    <div key={_id} style={{ zIndex: 0 }}>
                      <div
                        data-tip={
                          !isEdit && !selectedActivity
                            ? "Silakan pilih kegiatan terlebih dahulu"
                            : !isEdit &&
                              disabledInCharges.find((val) => val === _id)
                            ? "Penanggung Jawab ini sudah dalam kegiatan"
                            : ""
                        }
                        data-class="year-tooltip"
                      >
                        <CustomInput
                          name="inCharge"
                          type="checkbox"
                          className="mb-2"
                          onChange={onChangeCheckBox}
                          checked={
                            [...selectedInCharge].filter((val) => val === _id)
                              .length
                          }
                          id={_id}
                          label={<p>{name}</p>}
                          disabled={
                            !selectedActivity ||
                            isEdit ||
                            disabledInCharges.find((val) => val === _id)
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </>
        )}
      </div>
      <div className="row justify-content-center form">
        <div className="col-md-6 col-sm-8 col-10 d-block d-md-none">
          <div
            data-tip={
              !selectedInCharge.length || !selectedYears.length
                ? "Data penugasan belum lengkap"
                : ""
            }
            data-class="year-tooltip"
          >
            <button
              onClick={onClickSubmit}
              type="button"
              // className="bg-green mb-3"
              className={`${
                isLoading ||
                !selectedActivity ||
                !selectedInCharge.length ||
                !selectedYears.length
                  ? "bg-grey"
                  : "bg-green"
              } mb-3`}
              disabled={
                isLoading ||
                !selectedActivity ||
                !selectedInCharge.length ||
                !selectedYears.length
              }
            >
              {isEdit ? "Simpan Perubahan" : "Tambah Penugasan"}
            </button>
          </div>
        </div>
        {isEdit && (
          <div className="col-md-6 col-sm-8 col-10">
            <button
              onClick={onCLickCancel}
              type="button"
              className="bg-red mb-3"
            >
              Batal
            </button>
          </div>
        )}
        <div className="col-md-6 col-sm-8 col-10 d-none d-md-block">
          <div
            data-tip={
              !selectedInCharge.length || !selectedYears.length
                ? "Data penugasan belum lengkap"
                : ""
            }
            data-class="year-tooltip"
            data-effect="solid"
          >
            <button
              onClick={onClickSubmit}
              type="button"
              className={`${
                isLoading ||
                !selectedActivity ||
                !selectedInCharge.length ||
                !selectedYears.length
                  ? "bg-grey"
                  : "bg-green"
              } mb-3`}
              disabled={
                isLoading ||
                !selectedActivity ||
                !selectedInCharge.length ||
                !selectedYears.length
              }
            >
              {isEdit ? "Simpan Perubahan" : "Tambah Penugasan"}
            </button>
          </div>
        </div>
      </div>
      {/* <div className="row card-shadow p-3 mt-3"> */}
      <TableFormInCharge
        data={activityInChargeData}
        // data={activities}
        onClickEdit={onClickEdit}
        onClickDelete={onClickDelete}
        isEdit={isEdit}
        className="mt-3"
        isLoading={isLoading}
        emptyState={
          !selectedActivity
            ? "Pilih pilar, strategi, program, dan kegiatan untuk melihat data penanggung jawab"
            : null
        }
      />
      {/* </div> */}
    </>
  );
};

export default Assignment;
