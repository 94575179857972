import {
  GET_INSTITUTIONS_REQUEST,
  GET_INSTITUTIONS_SUCCESS,
  GET_INSTITUTIONS_FAILURE,
  CREATE_INSTITUTION_REQUEST,
  CREATE_INSTITUTION_SUCCESS,
  CREATE_INSTITUTION_FAILURE,
  UPDATE_INSTITUTION_REQUEST,
  UPDATE_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION_FAILURE,
  DELETE_INSTITUTION_REQUEST,
  DELETE_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_FAILURE,
} from "./actionTypes";

const initialState = {
  institutions: [],
  error: null,
  isLoading: false,
};

const Institution = (state = initialState, action) => {
  switch (action.type) {
    case GET_INSTITUTIONS_REQUEST:
    case CREATE_INSTITUTION_REQUEST:
    case UPDATE_INSTITUTION_REQUEST:
    case DELETE_INSTITUTION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_INSTITUTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        institutions: action.payload,
      };
    case CREATE_INSTITUTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        institutions: [...state.institutions, action.payload],
      };
    case UPDATE_INSTITUTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        institutions: state.institutions.map((institution) =>
          institution._id === action.payload._id ? action.payload : institution
        ),
      };
    case DELETE_INSTITUTION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        institutions: state.institutions.filter(
          ({ _id }) => _id !== action.payload._id
        ),
      };

    case GET_INSTITUTIONS_FAILURE:
    case CREATE_INSTITUTION_FAILURE:
    case UPDATE_INSTITUTION_FAILURE:
    case DELETE_INSTITUTION_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default Institution;
