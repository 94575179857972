import React, { useEffect, useState } from "react";
import HSBar from "react-horizontal-stacked-bar-chart";
import { activityColors, fundColors, color } from "../../helpers/colors";

const HorizontalBarChart = ({
  title = "title",
  values = [],
  type = "activity",
  hideLine = false,
}) => {
  const [data, setData] = useState([]);
  const activityTitles = ["Perencanaan", "Pelaksanaan", "Selesai"];
  const fundTitles = ["APBN", "APBD", "Swasta", "Lainnya"];
  // const activityTooltip = `Perencanaan: ${values[0]}Pelaksanaan: ${values[1]}\nSelesai: ${values[2]}`
  const activityTooltip = `
    <div>Perencanaan: ${values[0]} Kegiatan</div>
    <div>Pelaksanaan: ${values[1]} Kegiatan</div>
    <div>Selesai: ${values[2]} Kegiatan</div>
  `;
  const fundTooltip = `
    <div>APBN: ${values[0]} Miliar</div>
    <div>APBD: ${values[1]} Miliar</div>
    <div>Swasta: ${values[2]} Miliar</div>
    <div>Lainnya: ${values[3]} Miliar</div>
  `;

  useEffect(() => {
    const newValues = values
      .map((value, index) => ({
        name: type === "activity" ? activityTitles[index] : fundTitles[index],
        value,
        color: type === "activity" ? activityColors[index] : fundColors[index],
      }))
      .filter(({ value }) => value > 0);
    setData(newValues);
  }, [values]); // eslint-disable-line

  return (
    <>
      <div className="pt-3 pb-3">
        <div className="d-flex justify-content-between">
          <p className="horizontal-bar-chart-title text-bold">{title}</p>
          <p className="horizontal-bar-chart-title-type">
            {`${values.reduce((a, b) => a + b, 0).toFixed(2) * 1} ${
              type === "activity" ? "Kegiatan" : "Miliar"
            }`}
          </p>
        </div>
        <div
          data-tip={type === "activity" ? activityTooltip : fundTooltip}
          data-background-color={color.primary}
          data-place="top"
          data-html={true}
          data-effect="float"
          data-offset="{'top': -7}"
        >
          {data.length > 0 ? (
            <HSBar
              height={12}
              id="new_id"
              fontColor="rgb(50,20,100)"
              data={data}
            />
          ) : (
            <div
              style={{
                height: 12,
                width: "100%",
                backgroundColor: "#f6f6f6",
                marginTop: 5,
                marginBottom: 4,
              }}
            />
          )}
        </div>
      </div>
      {!hideLine && <div className="horizontal-bar-chart-line" />}
    </>
  );
};

export default HorizontalBarChart;
