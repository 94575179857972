import React from "react";
import { Spinner } from "reactstrap";
import { color } from "../../helpers/colors";

export default function BlockSpinner({show}) {
  return (
    <>
      {show && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "white",
            opacity: 0.7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner
            style={{
              height: 50,
              width: 50,
              fontSize: 20,
              color: color.primary,
            }}
          />
        </div>
      )}
    </>
  );
}
