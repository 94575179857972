import { takeEvery, put, all, call } from "redux-saga/effects";
import { GET_OVERVIEW_REQUEST } from "./actionTypes";
import { getOverviewSuccess, getOverviewFailure } from "./actions";
import { getOverviewService } from "./services";

function* getOverviewSaga({ payload: { queryString } }) {
  try {
    const response = yield call(getOverviewService, queryString);
    yield put(getOverviewSuccess(response));
  } catch (error) {
    yield put(getOverviewFailure(error));
  }
}

export default function* institutionSaga() {
  yield all([takeEvery(GET_OVERVIEW_REQUEST, getOverviewSaga)]);
}
