import React, { useState, useEffect, useRef } from "react";
import { Input, Spinner } from "reactstrap";
import { TableForm } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  uploadImageRequest,
  getInstitutionsRequest,
  createInstitutionRequest,
  updateInstitutionRequest,
  deleteInstitutionRequest,
} from "../../store/actions";
import { color } from "../../helpers/colors";
import Swal from "sweetalert2";

const InCharge = () => {
  /* HOOKS */
  const uploadRef = useRef(null);
  const dispatch = useDispatch();

  /* LOCAL STATE */
  const [isEdit, setIsEdit] = useState(null);
  const [inChargeName, setInChargeName] = useState("");
  const [image, setImage] = useState(null);
  const [inChargeData, setInChargeData] = useState([]);

  /* GLOBAL_STATE */
  const { isLoading: uploadLoading } = useSelector((state) => state.upload);
  const { institutions, isLoading } = useSelector((state) => state.Institution);

  /* FUNCTIONS */
  const onClickUpload = ({ target }) => {
    const newImage = target.files[0];
    const { type, size } = newImage || {};
    const isImage = type === "image/png" || type === "image/jpeg";
    const sizeInMB = (size / (1024 * 1024)).toFixed(2);
    if (isImage) {
      if (sizeInMB > 5) {
        Swal.fire({
          title: "Ukuran gambar terlalu besar",
          text: `Gambar yang anda upload berukuran ${sizeInMB}MB, pastikan gambar yang akan anda upload berukuran dibawah 50MB!`,
          icon: "error",
          position: "top-end",
          confirmButtonColor: color.primary,
          timer: 10000,
        });
      } else {
        dispatch(
          uploadImageRequest(newImage, (err, resp) => {
            console.log({ newImage });
            if (err) {
              uploadRef.current.value = null;
              Swal.fire({
                title: "Oops.",
                text: "Terjadi kesalahan saat mengupload gambar, harap perhatikan ukuran dan jenis gambar terlebih dahulu dan silakan coba lagi!",
                icon: "error",
                position: "top-end",
                confirmButtonColor: color.primary,
                timer: 10000,
              });
            } else {
              setImage(resp);
            }
          })
        );
      }
    } else {
      Swal.fire({
        title: "Format tidak sesuai",
        text: "Pastikan gambar memiliki exptensi .jpg atau .png",
        icon: "error",
        position: "top-end",
        confirmButtonColor: color.primary,
        timer: 10000,
      });
    }
  };

  const onChangeName = ({ target }) => {
    const { value } = target;
    setInChargeName(value);
  };

  const onClickEdit = (inCharge) => {
    setIsEdit(inCharge._id);
    setInChargeName(inCharge.name);
    setImage(inCharge.logo);
    // console.log({ inCharge });
  };

  const onClickDelete = (_id) => {
    dispatch(
      deleteInstitutionRequest(_id, (err, resp) => {
        if (err) {
          Swal.fire({
            title: "Oops.",
            text: `${
              err === 405
                ? "Tidak dapat menghapus penanggung jawab yang telah memiliki kegiatan!"
                : "Terjadi kesalahan, coba lagi nanti!"
            }`,
            icon: "error",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 10000,
          });
        } else {
          Swal.fire({
            title: "Berhasil.",
            text: "Penanggung jawab berhasil dihapus.",
            icon: "success",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 5000,
          });
        }
      })
    );
  };

  const onClickSubmit = () => {
    const institutionData = {
      logo: image,
      name: inChargeName,
    };
    if (isEdit) {
      dispatch(
        updateInstitutionRequest(isEdit, institutionData, (err, resp) => {
          if (err) {
            // error alert
          } else {
            onClearForm();
          }
        })
      );
    } else {
      dispatch(
        createInstitutionRequest(institutionData, (err, resp) => {
          if (err) {
            // error alert
          } else {
            onClearForm();
          }
        })
      );
    }
  };

  const onCLickCancel = () => {
    onClearForm();
  };

  const onClearForm = () => {
    setIsEdit(null);
    setInChargeName("");
    setImage(null);
  };

  useEffect(() => {
    dispatch(getInstitutionsRequest());
  }, []); // eslint-disable-line

  useEffect(() => {
    const newInCHargeData = institutions.map((institution) => ({
      _id: institution._id,
      name: institution.name,
      logo: institution.logo,
    }));
    setInChargeData(newInCHargeData);
  }, [institutions]);

  return (
    <>
      <p className="page-title mb-4">
        {isEdit ? "Edit" : "Tambah"} Penanggung Jawab
      </p>
      <div className="row">
        <div className="col-xs-12 col-md-6 border-right form">
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: 18,
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: 150,
                width: 150,
                backgroundColor: "#f6f6f6",
                borderRadius: 6,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: 36,
                marginBottom: 18,
              }}
            >
              {uploadLoading ? (
                <Spinner />
              ) : image ? (
                <img
                  src={image}
                  width={150}
                  height={150}
                  style={{ objectFit: "contain" }}
                  alt="Logo"
                />
              ) : (
                <i
                  className="fas fa-user"
                  style={{ fontSize: 30, color: "#8e8e95" }}
                />
              )}
            </div>
            <div style={{ width: 192, marginBottom: 18 }}>
              <p>Masukkan gambar / logo institusi anda</p>
              <input
                ref={uploadRef}
                type="file"
                name="image"
                accept="image/*"
                placeholder="Upload"
                hidden
                onChange={onClickUpload}
              />
              <button
                className={`${uploadLoading ? "bg-grey" : "bg-purple"}`}
                disabled={uploadLoading}
                onClick={() => uploadRef.current.click()}
              >
                {uploadLoading ? (
                  <Spinner />
                ) : image ? (
                  "Ganti Gambar"
                ) : (
                  "Upload Gambar"
                )}
              </button>
            </div>
          </div>
          <Input
            className="input-data"
            placeholder="Ketik Nama Penanggung Jawab"
            onChange={onChangeName}
            value={inChargeName}
            style={{ marginBottom: 36 }}
          />
          <button
            onClick={onClickSubmit}
            type="button"
            className={`${
              !inChargeName.length || !image ? "bg-grey" : "bg-green"
            } mb-3`}
            disabled={!inChargeName.length || !image}
          >
            {isEdit ? "Simpan Perubahan" : "Tambah Penanggung Jawab"}
          </button>
          {isEdit && (
            <button onClick={onCLickCancel} className="bg-red mb-3">
              Batal
            </button>
          )}
        </div>
        <div className="col-xs-12 col-md-6 border-left">
          <TableForm
            headers={[
              { title: "No.", alignValue: "center" },
              { title: "Nama Penanggung Jawab", value: "name" },
            ]}
            data={inChargeData}
            isEdit={isEdit}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default InCharge;
