import {
  GET_PILARS_REQUEST,
  GET_PILARS_SUCCESS,
  GET_PILARS_FAILURE,
  CREATE_PILAR_REQUEST,
  CREATE_PILAR_SUCCESS,
  CREATE_PILAR_FAILURE,
  UPDATE_PILAR_REQUEST,
  UPDATE_PILAR_SUCCESS,
  UPDATE_PILAR_FAILURE,
  DELETE_PILAR_REQUEST,
  DELETE_PILAR_SUCCESS,
  DELETE_PILAR_FAILURE,
  CREATE_STRATEGY_REQUEST,
  CREATE_STRATEGY_SUCCESS,
  CREATE_STRATEGY_FAILURE,
  UPDATE_STRATEGY_REQUEST,
  UPDATE_STRATEGY_SUCCESS,
  UPDATE_STRATEGY_FAILURE,
  DELETE_STRATEGY_REQUEST,
  DELETE_STRATEGY_SUCCESS,
  DELETE_STRATEGY_FAILURE,
  CREATE_PROGRAM_REQUEST,
  CREATE_PROGRAM_SUCCESS,
  CREATE_PROGRAM_FAILURE,
  UPDATE_PROGRAM_REQUEST,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_FAILURE,
  DELETE_PROGRAM_REQUEST,
  DELETE_PROGRAM_SUCCESS,
  DELETE_PROGRAM_FAILURE,
  CREATE_ACTIVITY_REQUEST,
  CREATE_ACTIVITY_SUCCESS,
  CREATE_ACTIVITY_FAILURE,
  UPDATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_SUCCESS,
  UPDATE_ACTIVITY_FAILURE,
  DELETE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_SUCCESS,
  DELETE_ACTIVITY_FAILURE,
  CREATE_ASSIGNMENT_REQUEST,
  CREATE_ASSIGNMENT_SUCCESS,
  CREATE_ASSIGNMENT_FAILURE,
  UPDATE_ASSIGNMENT_REQUEST,
  UPDATE_ASSIGNMENT_SUCCESS,
  UPDATE_ASSIGNMENT_FAILURE,
  DELETE_ASSIGNMENT_REQUEST,
  DELETE_ASSIGNMENT_SUCCESS,
  DELETE_ASSIGNMENT_FAILURE,
  GET_ACTIVITIES_REQUEST,
  GET_ACTIVITIES_SUCCESS,
  GET_ACTIVITIES_FAILURE,
} from "./actionTypes";

const initialState = {
  pilars: [],
  error: null,
  isLoading: false,
};

const MasterData = (state = initialState, action) => {
  switch (action.type) {
    case GET_PILARS_REQUEST:
    case CREATE_PILAR_REQUEST:
    case UPDATE_PILAR_REQUEST:
    case DELETE_PILAR_REQUEST:
    case CREATE_STRATEGY_REQUEST:
    case UPDATE_STRATEGY_REQUEST:
    case DELETE_STRATEGY_REQUEST:
    case CREATE_PROGRAM_REQUEST:
    case UPDATE_PROGRAM_REQUEST:
    case DELETE_PROGRAM_REQUEST:
    case CREATE_ACTIVITY_REQUEST:
    case UPDATE_ACTIVITY_REQUEST:
    case DELETE_ACTIVITY_REQUEST:
    case CREATE_ASSIGNMENT_REQUEST:
    case UPDATE_ASSIGNMENT_REQUEST:
    case DELETE_ASSIGNMENT_REQUEST:
    case GET_ACTIVITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case GET_PILARS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: action.payload,
      };

    case CREATE_PILAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: [...state.pilars, action.payload].sort(
          (a, b) => a.pilarNumber - b.pilarNumber
        ),
      };

    case UPDATE_PILAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: [...state.pilars].map((pilar) =>
          pilar._id === action.payload._id ? action.payload : pilar
        ),
      };

    case DELETE_PILAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.filter(
          (pilar) => pilar._id !== action.payload._id
        ),
      };

    case CREATE_STRATEGY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.map((pilar) => {
          if (pilar._id === action.payload.pilar) {
            return {
              ...pilar,
              strategies: [...pilar.strategies, action.payload],
            };
          }
          return pilar;
        }),
      };

    case UPDATE_STRATEGY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.map((pilar) => {
          if (pilar._id === action?.payload?.pilar?._id) {
            return {
              ...pilar,
              strategies: pilar.strategies.map((strategy) =>
                strategy._id === action.payload._id ? action.payload : strategy
              ),
            };
          }
          return pilar;
        }),
      };

    case DELETE_STRATEGY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.map((pilar) => {
          if (pilar._id === action?.payload?.pilar) {
            return {
              ...pilar,
              strategies: pilar.strategies.filter(
                (strategy) => strategy._id !== action.payload._id
              ),
            };
          }
          return pilar;
        }),
      };

    case CREATE_PROGRAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.map((pilar) => {
          if (pilar._id === action.payload.pilar) {
            return {
              ...pilar,
              strategies: pilar.strategies.map((strategy) => {
                if (strategy._id === action.payload.strategy) {
                  return {
                    ...strategy,
                    programs: [...strategy.programs, action.payload],
                  };
                }
                return strategy;
              }),
            };
          }
          return pilar;
        }),
      };

    case UPDATE_PROGRAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.map((pilar) => {
          if (pilar._id === action?.payload?.pilar) {
            return {
              ...pilar,
              strategies: pilar.strategies.map((strategy) => {
                if (strategy._id === action.payload.strategy) {
                  return {
                    ...strategy,
                    programs: strategy.programs.map((program) =>
                      program._id === action.payload._id
                        ? action.payload
                        : program
                    ),
                  };
                }
                return strategy;
              }),
            };
          }
          return pilar;
        }),
      };

    case DELETE_PROGRAM_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.map((pilar) => {
          if (pilar._id === action?.payload?.pilar) {
            return {
              ...pilar,
              strategies: pilar.strategies.map((strategy) => {
                if (strategy._id === action.payload.strategy) {
                  return {
                    ...strategy,
                    programs: strategy.programs.filter(
                      (program) => program._id !== action.payload._id
                    ),
                  };
                }
                return strategy;
              }),
            };
          }
          return pilar;
        }),
      };

    case CREATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.map((pilar) => {
          if (pilar._id === action.payload.pilar) {
            return {
              ...pilar,
              strategies: pilar.strategies.map((strategy) => {
                if (strategy._id === action.payload.strategy) {
                  return {
                    ...strategy,
                    programs: strategy.programs.map((program) => {
                      if (program._id === action.payload.program) {
                        return {
                          ...program,
                          activities: [...program.activities, action.payload],
                        };
                      }
                      return program;
                    }),
                  };
                }
                return strategy;
              }),
            };
          }
          return pilar;
        }),
      };

    case UPDATE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.map((pilar) => {
          if (pilar._id === action?.payload?.pilar) {
            return {
              ...pilar,
              strategies: pilar.strategies.map((strategy) => {
                if (strategy._id === action.payload.strategy) {
                  return {
                    ...strategy,
                    programs: strategy.programs.map((program) => {
                      if (program._id === action.payload.program) {
                        return {
                          ...program,
                          activities: program.activities.map((activity) =>
                            activity._id === action.payload._id
                              ? action.payload
                              : activity
                          ),
                        };
                      }
                      return program;
                    }),
                  };
                }
                return strategy;
              }),
            };
          }
          return pilar;
        }),
      };

    case DELETE_ACTIVITY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        pilars: state.pilars.map((pilar) => {
          if (pilar._id === action?.payload?.pilar) {
            return {
              ...pilar,
              strategies: pilar.strategies.map((strategy) => {
                if (strategy._id === action.payload.strategy) {
                  return {
                    ...strategy,
                    programs: strategy.programs.map((program) => {
                      if (program._id === action.payload.program) {
                        return {
                          ...program,
                          activities: program.activities.filter(
                            (activity) => activity._id !== action.payload._id
                          ),
                        };
                      }
                      return program;
                    }),
                  };
                }
                return strategy;
              }),
            };
          }
          return pilar;
        }),
      };

    case CREATE_ASSIGNMENT_SUCCESS:
    case UPDATE_ASSIGNMENT_SUCCESS:
    case DELETE_ASSIGNMENT_SUCCESS:
    case GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case GET_PILARS_FAILURE:
    case CREATE_PILAR_FAILURE:
    case UPDATE_PILAR_FAILURE:
    case DELETE_PILAR_FAILURE:
    case CREATE_STRATEGY_FAILURE:
    case UPDATE_STRATEGY_FAILURE:
    case DELETE_STRATEGY_FAILURE:
    case CREATE_PROGRAM_FAILURE:
    case UPDATE_PROGRAM_FAILURE:
    case DELETE_PROGRAM_FAILURE:
    case CREATE_ACTIVITY_FAILURE:
    case UPDATE_ACTIVITY_FAILURE:
    case DELETE_ACTIVITY_FAILURE:
    case CREATE_ASSIGNMENT_FAILURE:
    case UPDATE_ASSIGNMENT_FAILURE:
    case DELETE_ASSIGNMENT_FAILURE:
    case GET_ACTIVITIES_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default MasterData;
