// import axios from "axios";
import axios from "../api";
import { baseUrl } from "../../helpers/config";
import store from "../index";

export const getLocationsService = async (filter) => {
  try {
    const { pilar, inCharge } = filter;
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      headers: { Authorization },
      url: `${baseUrl}/api/location?pilar=${pilar}${
        inCharge ? "&incharge=" + inCharge : ""
      }`,
    });
    return response.data.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};
