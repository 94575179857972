import {
  GET_IKU_REQUEST,
  GET_IKU_SUCCESS,
  GET_IKU_FAILURE,
  UPDATE_IKU_REQUEST,
  UPDATE_IKU_SUCCESS,
  UPDATE_IKU_FAILURE,
  GET_IKU_ACCUMULATION_REQUEST,
  GET_IKU_ACCUMULATION_SUCCESS,
  GET_IKU_ACCUMULATION_FAILURE,
} from "./actionTypes";

export const getIkuRequest = () => ({
  type: GET_IKU_REQUEST,
});

export const getIkuSuccess = (response) => ({
  type: GET_IKU_SUCCESS,
  payload: response,
});

export const getIkuFailure = (error) => ({
  type: GET_IKU_FAILURE,
  payload: error,
});

export const updateIkuRequest = (ikuNumber, value, callback) => ({
  type: UPDATE_IKU_REQUEST,
  payload: { ikuNumber, value, callback },
});

export const updateIkuSuccess = (response) => ({
  type: UPDATE_IKU_SUCCESS,
  payload: response,
});

export const updateIkuFailure = (error) => ({
  type: UPDATE_IKU_FAILURE,
  payload: error,
});

export const getIkuAccumulationRequest = () => ({
  type: GET_IKU_ACCUMULATION_REQUEST,
});

export const getIkuAccumulationSuccess = (response) => ({
  type: GET_IKU_ACCUMULATION_SUCCESS,
  payload: response,
});

export const getIkuAccumulationFailure = (error) => ({
  type: GET_IKU_ACCUMULATION_FAILURE,
  payload: error,
});
