import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  role,
  ...rest
}) => {
  const { user } = useSelector((state) => state.Auth);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthProtected && !role.find((val) => val === user?.role)) {
          return (
            <Redirect
              to={{
                pathname: user ? "/overview" : "/login",
                state: { from: props.location },
              }}
            />
          );
        }

        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default AppRoute;
