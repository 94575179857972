import {
  GET_DETAIL_REQUEST,
  GET_DETAIL_SUCCESS,
  GET_DETAIL_FAILURE,
  GET_PREVIEW_DETAIL_REQUEST,
  GET_PREVIEW_DETAIL_SUCCESS,
  GET_PREVIEW_DETAIL_FAILURE,
  CREATE_ACTIVITY_STATUS_REQUEST,
  CREATE_ACTIVITY_STATUS_SUCCESS,
  CREATE_ACTIVITY_STATUS_FAILURE,
  UPDATE_ACTIVITY_STATUS_REQUEST,
  UPDATE_ACTIVITY_STATUS_SUCCESS,
  UPDATE_ACTIVITY_STATUS_FAILURE,
  DELETE_ACTIVITY_STATUS_REQUEST,
  DELETE_ACTIVITY_STATUS_SUCCESS,
  DELETE_ACTIVITY_STATUS_FAILURE,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
} from "./actionTypes";

const initialState = {
  detail: [],
  report: [],
  isLoading: false,
  isLoadingPreview: false,
  isLoadingStatus: false,
  isLoadingReport: false,
  error: null,
  errorPreview: null,
  errorStatus: null,
  errorReport: null,
};

const Detail = (state = initialState, action) => {
  switch (action.type) {
    case GET_DETAIL_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case GET_PREVIEW_DETAIL_REQUEST:
      return {
        ...state,
        isLoadingPreview: true,
        errorPreview: null,
      };
    case GET_REPORT_REQUEST:
      return {
        ...state,
        isLoadingReport: true,
        errorReport: null,
      };
    case CREATE_ACTIVITY_STATUS_REQUEST:
    case UPDATE_ACTIVITY_STATUS_REQUEST:
    case DELETE_ACTIVITY_STATUS_REQUEST:
      return {
        ...state,
        isLoadingStatus: true,
        errorStatus: null,
      };

    case GET_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        detail: action.payload,
      };
    case GET_PREVIEW_DETAIL_SUCCESS:
      return {
        ...state,
        isLoadingPreview: false,
      };
    case GET_REPORT_SUCCESS:
      return {
        ...state,
        isLoadingReport: false,
        report: action.payload,
      };
    case CREATE_ACTIVITY_STATUS_SUCCESS:
    case UPDATE_ACTIVITY_STATUS_SUCCESS:
    case DELETE_ACTIVITY_STATUS_SUCCESS:
      return {
        ...state,
        isLoadingStatus: false,
      };

    case GET_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        detail: [],
      };
    case GET_PREVIEW_DETAIL_FAILURE:
      return {
        ...state,
        isLoadingPreview: false,
        error: action.payload,
      };
    case GET_REPORT_FAILURE:
      return {
        ...state,
        isLoadingReport: false,
        errorReport: action.payload,
      };
    case CREATE_ACTIVITY_STATUS_FAILURE:
    case UPDATE_ACTIVITY_STATUS_FAILURE:
    case DELETE_ACTIVITY_STATUS_FAILURE:
      return {
        ...state,
        isLoadingStatus: false,
        errorStatus: action.payload,
      };

    default:
      return state;
  }
};

export default Detail;
