import React from "react";
import { blueWheelColors } from "../../helpers/colors";
import { Popconfirm } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import { BlockSpinner } from "..";

const TableFormInCharge = ({
  headers,
  data,
  onClickEdit,
  onClickDelete,
  isEdit,
  emptyState,
  className,
  isLoading,
}) => {
  return (
    <>
      <div
        style={{
          marginRight: -22,
          marginLeft: -22,
          paddingLeft: 22,
          paddingRight: 22,
          overflow: "auto",
          position: "relative",
        }}
      >
        <table
          className="table card-shadow mt-4 table-hover"
          style={{
            borderCollapse: "collapse",
            borderRadius: "1em",
            overflow: "hidden",
          }}
        >
          <thead>
            <tr>
              <td
                align="center"
                style={{
                  backgroundColor: blueWheelColors[3],
                  color: "#ffffff",
                  verticalAlign: "middle",
                }}
                rowSpan={2}
              >
                No.
              </td>
              <td
                align="center"
                style={{
                  backgroundColor: blueWheelColors[2],
                  color: "#ffffff",
                  verticalAlign: "middle",
                }}
                rowSpan={2}
              >
                Penanggung Jawab
              </td>
              <td
                align="center"
                style={{
                  backgroundColor: blueWheelColors[3],
                  color: "#ffffff",
                }}
                colSpan={12}
              >
                Tahun (dalam 2000)
              </td>
              <td
                style={{
                  backgroundColor: blueWheelColors[3],
                  color: "#ffffff",
                  borderWidth: 0,
                }}
                rowSpan={2}
              ></td>
            </tr>
            <tr>
              {[18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29].map((year) => (
                <td
                  key={year}
                  align="center"
                  style={{
                    backgroundColor: blueWheelColors[3],
                    color: "#ffffff",
                    borderWidth: 0,
                  }}
                >
                  {year}
                </td>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((value, valueIndex) => (
              <tr
                key={valueIndex}
                style={{
                  backgroundColor: isEdit === value._id ? "#a0b0d5" : "",
                }}
              >
                <td align="center">{valueIndex + 1}</td>
                <td align="center">{value.inCharge.name}</td>
                {[
                  2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027,
                  2028, 2029,
                ].map((year) => (
                  <td align="center" key={year}>
                    <div
                      style={{
                        width: 28,
                        height: 28,
                        borderRadius: 4,
                        backgroundColor: value.implementations.find(
                          (val) => val.year === year
                        )
                          ? blueWheelColors[5]
                          : "#f6f6f6",
                      }}
                    ></div>
                  </td>
                ))}
                <td align="center">
                  <div className="d-flex justify-content-center">
                    <button
                      disabled={isEdit}
                      className={`${isEdit ? "disabled" : "edit"}`}
                      onClick={() => onClickEdit(value)}
                    >
                      Edit
                    </button>
                    <Popconfirm
                      placement="left"
                      title={"Ingin menghapus data ini?"}
                      onConfirm={() => onClickDelete(value._id)}
                      okText="Hapus"
                      cancelText="Batal"
                      className={`${isEdit ? "disabled" : "delete"} ml-1`}
                      disabled={isEdit}
                      okButtonProps={{
                        danger: true,
                        icon: <i className="far fa-trash-alt mr-1" />,
                      }}
                    >
                      <button>Hapus</button>
                    </Popconfirm>
                  </div>
                </td>
              </tr>
            ))}
            {!data.length && (
              <tr>
                <td colSpan={15} align="center">
                  {emptyState || "Data kosong"}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <BlockSpinner show={isLoading} />
      </div>
    </>
  );
};

export default TableFormInCharge;
