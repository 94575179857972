import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input } from "reactstrap";
import { TableForm } from "../../components";
import { customColorSelect, color } from "../../helpers/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  getPilarsRequest,
  createActivityRequest,
  updateActivityRequest,
  deleteActivityRequest,
} from "../../store/actions";
import Swal from "sweetalert2";

const Activity = () => {
  /* HOOKS */
  const dispatch = useDispatch();

  /* LOCAL STATE */
  const [isEdit, setIsEdit] = useState(null);
  const [activityName, setActivityName] = useState("");
  const [selectedPilar, setSelectedPilar] = useState(null);
  const [pilarOptions, setPilarOptions] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [strategyOptions, setStrategyOptions] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [programOptions, setProgramOptions] = useState([]);
  const [activityData, setActivityData] = useState([]);

  /* GLOBAL_STATE */
  const { pilars, isLoading } = useSelector((state) => state.MasterData);

  /* FUNCTIONS */
  const onChangeName = ({ target }) => {
    const { value } = target;
    setActivityName(value);
  };

  const onChangeSelect = (value, { name, action }) => {
    if (name === "pilar") {
      setSelectedPilar(value);
      setSelectedStrategy(null);
      setSelectedProgram(null);
    } else if (name === "strategy") {
      setSelectedStrategy(value);
      setSelectedProgram(null);
    } else if (name === "program") {
      setSelectedProgram(value);
    }

    if (action === "clear") {
      setActivityName("");
    }
  };

  const onClickEdit = (activity) => {
    setIsEdit(activity._id);
    setActivityName(activity.name);
  };

  const onClickDelete = (_id) => {
    dispatch(
      deleteActivityRequest(_id, (err, resp) => {
        if (err) {
          Swal.fire({
            title: "Oops.",
            text: `${
              err === 409
                ? "Tidak dapat menghapus kegiatan yang telah ditugaskan."
                : "Terjadi kesalahan, coba lagi nanti!"
            }`,
            icon: "error",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 10000,
          });
        } else {
          Swal.fire({
            title: "Berhasil.",
            text: "Kegiatan berhasil dihapus.",
            icon: "success",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 5000,
          });
        }
      })
    );
  };

  const onClickSubmit = () => {
    if (isEdit) {
      dispatch(
        updateActivityRequest(isEdit, { name: activityName }, (err, resp) => {
          // console.log({ err, resp });
          if (err) {
            // error alert
          } else {
            onClearForm();
          }
        })
      );
    } else {
      const activityData = {
        pilar: selectedPilar.value,
        strategy: selectedStrategy.value,
        program: selectedProgram.value,
        name: activityName,
      };
      dispatch(
        createActivityRequest(activityData, (err, resp) => {
          if (err) {
            // error alert
          } else {
            onClearForm();
          }
        })
      );
    }
  };

  const onCLickCancel = () => {
    onClearForm();
  };

  const onClearForm = () => {
    setIsEdit(null);
    setActivityName("");
  };

  /* LIFECYCLE */
  useEffect(() => {
    dispatch(getPilarsRequest());
  }, []); // eslint-disable-line

  useEffect(() => {
    const newPilarOptions = pilars.map((pilar) => ({
      value: pilar._id,
      label: `PILAR ${pilar.pilarNumber} ${pilar.name}`,
      strategies: pilar.strategies,
    }));
    setPilarOptions(newPilarOptions);

    if (selectedPilar) {
      const pilarId = selectedPilar.value;
      const newSelectedPilar = newPilarOptions.find(
        (pilarOption) => pilarOption.value === pilarId
      );
      setSelectedPilar(newSelectedPilar);
    }
  }, [pilars]); // eslint-disable-line

  useEffect(() => {
    if (selectedPilar) {
      const newStrategyOptions = selectedPilar.strategies.map((strategy) => ({
        value: strategy._id,
        label: strategy.name,
        programs: strategy.programs,
      }));
      setStrategyOptions(newStrategyOptions);

      if (selectedStrategy) {
        const strategyId = selectedStrategy.value;
        const newSelectedStrategy = newStrategyOptions.find(
          (strategyOption) => strategyOption.value === strategyId
        );
        setSelectedStrategy(newSelectedStrategy);
      }
    } else {
      setStrategyOptions([]);
    }
  }, [selectedPilar]); // eslint-disable-line

  useEffect(() => {
    if (selectedStrategy) {
      const newProgramOptions = selectedStrategy.programs.map((program) => ({
        value: program._id,
        label: program.name,
        activities: program.activities,
      }));
      setProgramOptions(newProgramOptions);

      if (selectedProgram) {
        const programId = selectedProgram.value;
        const newSelectedProgram = newProgramOptions.find(
          (programOption) => programOption.value === programId
        );
        setSelectedProgram(newSelectedProgram);
      }
    } else {
      setProgramOptions([]);
    }
  }, [selectedStrategy]); // eslint-disable-line

  useEffect(() => {
    if (selectedProgram) {
      const newActivityData = selectedProgram.activities.map(
        ({ _id, name }) => ({ _id, name })
      );
      setActivityData(newActivityData);
    } else {
      setActivityData([]);
    }
  }, [selectedProgram]);

  return (
    <>
      <p className="page-title mb-4">Tambah Kegiatan</p>
      <div className="row">
        <div className="col-xs-12 col-xl-5 border-right form">
          <Select
            name="pilar"
            className="mb-3"
            placeholder="Pilih Pilar"
            isDisabled={isEdit}
            value={selectedPilar}
            options={pilarOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
          />
          <Select
            name="strategy"
            className="mb-3"
            placeholder="Pilih Strategi"
            isDisabled={isEdit || !selectedPilar}
            value={selectedStrategy}
            options={strategyOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
          />
          <Select
            name="program"
            className="mb-3"
            placeholder="Pilih Program"
            isDisabled={isEdit || !selectedStrategy}
            value={selectedProgram}
            options={programOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
          />
          <Input
            className="mb-3"
            type="textarea"
            placeholder="Nama Kegiatan"
            onChange={onChangeName}
            value={activityName}
            disabled={!selectedProgram}
            style={{
              backgroundColor: !selectedProgram ? "#f2f2f2" : "",
              minHeight: 50,
              padding: 14,
              height: 150,
            }}
          />
          <button
            onClick={onClickSubmit}
            type="button"
            className={`${
              isLoading || !selectedProgram || !activityName.length
                ? "bg-grey"
                : "bg-green"
            } mb-3`}
            disabled={isLoading || !selectedProgram || !activityName.length}
          >
            {isEdit ? "Simpan Perubahan" : "Tambah Kegiatan"}
          </button>
          {isEdit && (
            <button onClick={onCLickCancel} className="bg-red mb-3">
              Batal
            </button>
          )}
        </div>
        <div className="col-xs-12 col-xl-7 border-left">
          <TableForm
            headers={[
              { title: "No.", value: "pilar", alignValue: "center" },
              { title: "Nama Kegiatan", value: "name" },
            ]}
            data={activityData}
            isEdit={isEdit}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            emptyState={`${
              selectedProgram
                ? "Kegiatan belum tersedia, silakan tambah kegiatan!"
                : "Pilih pilar, strategi, dan program untuk melihat data kegiatan"
            }`}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default Activity;
