// import axios from "axios";
import axios from "../api";
import { baseUrl } from "../../helpers/config";
import store from "../index";

export const getDetailService = async ({page, start, end, unit, search}) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/pilar/detail?search=${search}&page=${search ? "" : page}&start=${start}&end=${end}${
        unit ? "&unit=" + unit : ""
      }`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const getPreviewDetailService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/activity/detail/${_id}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const createActivityStatusService = async (_id, activityStatusData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/activity/detail/${_id}`,
      headers: { Authorization },
      data: activityStatusData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const updateActivityStatusService = async (_id, activityStatusData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "put",
      url: `${baseUrl}/api/activity/detail/status/${_id}`,
      headers: { Authorization },
      data: activityStatusData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const deleteActivityStatusService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "delete",
      url: `${baseUrl}/api/activity/progress/${_id}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const getReportService = async (queryString) => {
  try {
    const { page, unit, start, end } = queryString;
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/pilar/report?page=${page}&unit=${unit}&start=${start}&end=${end}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const toggleYearService = async (_id, isLock) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "patch",
      url: `${baseUrl}/api/activity/pemission/${_id}?isLock=${isLock}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
} 
