import React from "react";
import GoogleMapReact from "google-map-react";
import { validateLatLng } from "../../helpers/utils";
import { color } from "../../helpers/colors";
import { googleMapsApi } from "../../helpers/config";

export default function GMapsCard({ lat, lng, zoom }) {
  const defaultProps = {
    defaultCenter: {
      lat: -6.175392,
      lng: 106.827153,
    },
    center: validateLatLng(lat, lng)
      ? {
          lat,
          lng,
        }
      : null,
    zoom: zoom || 11,
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsApi }}
        defaultCenter={defaultProps.defaultCenter}
        center={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        draggable={false}
      >
        {defaultProps.center ? (
          <div lat={defaultProps.center?.lat} lng={defaultProps.center?.lng}>
            <i
              className="mdi mdi-map-marker"
              style={{
                fontSize: 40,
                color: color.primary,
                position: "absolute",
                top: -38.7,
                left: -19.5,
              }}
            />
          </div>
        ) : (
          <p
            style={{
              fontSize: 20,
              color: "red",
              position: "absolute",
              top: -20,
              left: -80,
            }}
          >
            Lokasi tidak valid!
          </p>
        )}
      </GoogleMapReact>
    </div>
  );
}
