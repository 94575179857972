import { takeEvery, put, all, call } from "redux-saga/effects";

import {
  USER_LOGIN_REQUEST,
  USER_LOGOUT_REQUEST,
  USER_FORGET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_REQUEST,
} from "./actionTypes";

import {
  userLoginSuccess,
  userLoginFailure,
  userLogoutSuccess,
  userLogoutFailure,
  userForgetPasswordSuccess,
  userForgetPasswordFailure,
  userResetPasswordSuccess,
  userResetPasswordFailure,
} from "./actions";

import {
  userLoginService,
  userLogoutService,
  userForgetPasswordService,
  userResetPasswordService,
} from "./services";

function* userLoginSaga({ payload: { userData, history, callback } }) {
  try {
    const response = yield call(userLoginService, userData);
    yield put(userLoginSuccess(response));
    if (typeof callback === "function") callback(null, response);
    history.push("/overview");
  } catch (error) {
    yield put(userLoginFailure(error));
    if (typeof callback === "function") callback(error);
  }
}

function* userLogoutSaga({ payload: { history } }) {
  try {
    const response = yield call(userLogoutService);
    yield put(userLogoutSuccess(response));
    history.push("/login");
  } catch (error) {
    yield put(userLogoutFailure(error));
  }
}

function* userForgetPasswordSaga({ payload: { userEmail, callback } }) {
  try {
    const response = yield call(userForgetPasswordService, userEmail);
    yield put(userForgetPasswordSuccess(response));
    if (typeof callback === "function") callback(null, response);
  } catch (error) {
    yield put(userForgetPasswordFailure(error));
    if (typeof callback === "function") callback(error);
  }
}

function* userResetPasswordSaga({ payload: { userData, callback } }) {
  try {
    const response = yield call(userResetPasswordService, userData);
    yield put(userResetPasswordSuccess(response));
    if (typeof callback === "function") callback(null, response);
  } catch (error) {
    yield put(userResetPasswordFailure(error));
    if (typeof callback === "function") callback(error);
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(USER_LOGIN_REQUEST, userLoginSaga),
    takeEvery(USER_LOGOUT_REQUEST, userLogoutSaga),
    takeEvery(USER_FORGET_PASSWORD_REQUEST, userForgetPasswordSaga),
    takeEvery(USER_RESET_PASSWORD_REQUEST, userResetPasswordSaga),
  ]);
}
