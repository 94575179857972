import {
  CREATE_HELP_REQUEST,
  CREATE_HELP_SUCCESS,
  CREATE_HELP_FAILURE,
} from "./actionTypes";

const initialState = {
  isLoadingHelp: false,
  errorHelp: null,
};

const Help = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_HELP_REQUEST:
      return {
        ...state,
        isLoadingHelp: true,
        errorHelp: null,
      };
    case CREATE_HELP_SUCCESS:
      return {
        ...state,
        isLoadingHelp: false,
      };
    case CREATE_HELP_FAILURE:
      return {
        ...state,
        isLoadingHelp: false,
        errorHelp: action.payload,
      };
    default:
      return state;
  }
};

export default Help;
