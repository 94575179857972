// import axios from "axios";
import axios from "../api";
import { baseUrl } from "../../helpers/config";
import store from "../index";

export const getUsersService = async () => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/user`,
      headers: { Authorization },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const createUserService = async (userData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/user/register`,
      headers: { Authorization },
      data: userData,
    });
    return response.data?.data;
  } catch (error) {
    if (error?.response?.status === 400 && error?.response?.data?.msg === "email already register" ) {
      return Promise.reject(`Email ${userData?.email} sudah pernah didaftarkan, silakan menggunakan email lain.`);
    } else if (error?.response?.status === 500 && error?.response?.data?.keyValue?.userName === userData?.userName ) {
      return Promise.reject(`Username ${userData?.userName} sudah pernah didaftarkan, silakan menggunakan username lain.`);
    } else {
      return Promise.reject("Terjadi kesalahan, silakan periksa data yang diinput dan coba lagi.");
    }
  }
};

export const updateUserService = async (_id, userData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "put",
      url: `${baseUrl}/api/user/${_id}`,
      headers: { Authorization },
      data: userData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const deleteUserService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "delete",
      url: `${baseUrl}/api/user/${_id}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const updateUserPasswordService = async (_id, password) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "patch",
      url: `${baseUrl}/api/user/change-password/${_id}`,
      headers: { Authorization },
      data: { password },
    });
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
};
