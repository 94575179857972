export const color = {
  success: "#7fd051",
  error: "#ef5c5c",
  danger: "#fd9601",
  primary: "#555bae",
};

// export const activityColors = ["#daa9c7", "#aa8ec1", "#6871a8"];
// export const fundColors = ["#7db0dc", "#6bc0ca", "#37bfb1", "#4ab684"];
export const activityColors = ["#afebe1", "#5d96af", "#217f80"];
export const fundColors = ["#fcc8cb", "#daa9c7", "#a98fc1", "#6872a9"];
export const ikuColors = [
  "#aca2e2",
  "#cc99d9",
  "#df92c1",
  "#e295a4",
  "#db9d86",
  "#c7a76c",
  "#abb063",
  "#8bb374",
];
export const blueWheelColors = [
  "#d1e8f0",
  "#b8cce3",
  "#a0b0d5",
  "#8794c8",
  "#6e77bb",
  "#555bae",
  "#3d3fa0",
  "#242393",
];

export const customColorSelect = {
  control: (provided) => ({
    ...provided,
    border: "none",
    height: "48px",
    boxShadow: "0 12px 36px -12px rgba(100, 24, 194, 0.12)",
    borderRadius: "6px",
    paddingLeft: "4px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "#ffffff"
      : state.isFocused
      ? "#ffffff"
      : "#323338",
    backgroundColor: state.isSelected
      ? "#555bae"
      : state.isFocused
      ? "#a0b0d5"
      : "#ffffff",
  }),
};

export const customColorSelect2 = {
  control: (provided) => ({
    ...provided,
    height: "48px",
    boxShadow: "0 12px 36px -12px rgba(100, 24, 194, 0.12)",
    borderRadius: "6px",
    paddingLeft: "4px",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "#ffffff"
      : state.isFocused
      ? "#ffffff"
      : "#323338",
    backgroundColor: state.isSelected
      ? "#555bae"
      : state.isFocused
      ? "#a0b0d5"
      : "#ffffff",
    // borderColor: "red",
  }),
};

export const customColorSelectValidate = (isValid) => ({
  control: (provided) => ({
    ...provided,
    height: "48px",
    boxShadow: "0 12px 36px -12px rgba(100, 24, 194, 0.12)",
    borderRadius: "6px",
    paddingLeft: "4px",
    borderColor: !isValid ? "red" : "#ffffff",
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected
      ? "#ffffff"
      : state.isFocused
      ? "#ffffff"
      : "#323338",
    backgroundColor: state.isSelected
      ? "#555bae"
      : state.isFocused
      ? "#a0b0d5"
      : "#ffffff",
    // borderColor: "red",
  }),
});
