import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import LayoutSaga from "./layout/saga";

//BPTH
import Auth from "./auth/saga";
import MasterData from "./MasterData/saga";
import upload from "./upload/saga";
import Institution from "./Institution/saga";
import User from "./User/saga";
import Detail from "./Detail/saga";
import IKU from "./IKU/saga";
import Overview from "./Overview/saga";
import Help from "./Help/saga";
import Location from "./Location/saga";
import Notification from "./Notification/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ForgetSaga(),
    LayoutSaga(),
    Auth(),
    MasterData(),
    upload(),
    Institution(),
    User(),
    Detail(),
    IKU(),
    Overview(),
    Help(),
    Location(),
    Notification(),
  ]);
}
