import React from "react";
import { Pie } from "react-chartjs-2";
import { activityColors } from "../../helpers/colors";

const PieChart = ({ values = [0, 0, 0] }) => {
  const data = {
    datasets: [
      {
        data: values,
        backgroundColor: activityColors,
        hoverBackgroundColor: activityColors,
        hoverBorderColor: "#fff",
        borderWidth: values.filter((val) => val > 0).length > 1 ? 4 : 0,
      },
    ],
  };

  const option = {
    tooltips: {
      callbacks: {
        title: function (tooltipItem, data) {
          const total = values.reduce((a, b) => a + b, 0);
          const currentValue = data.datasets[0].data[tooltipItem[0].index];
          const percentage = parseFloat(
            ((currentValue / total) * 100).toFixed(1)
          );
          return `${percentage}%`;
        },
        label: function (tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const currentValue = dataset.data[tooltipItem.index];
          return `${currentValue} Kegiatan`;
        },
        afterLabel: function (tooltipItem) {
          const status =
            tooltipItem.index === 0
              ? "sedang dalam perencanaan"
              : tooltipItem.index === 1
              ? "sedang dalam pelaksanaan"
              : tooltipItem.index === 2
              ? "selesai dilaksanakan"
              : "";
          return status;
        },
      },
    },
  };

  return values.reduce((a, b) => a + b, 0) ? (
    <Pie width={100} height={100} data={data} options={option} />
  ) : (
    <div
      style={{
        position: "relative",
        paddingBottom: "93%",
        marginTop: "7%",
        marginLeft: "3.5%",
        marginRight: "3.5%",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "93%",
          width: "93%",
          borderRadius: "50%",
          backgroundColor: "#f6f6f6",
          // backgroundColor: "red",
        }}
      />
    </div>
  );
};

export default PieChart;
