import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
} from "./actionTypes";

const initialState = {
  error: null,
  isLoading: false,
};

const Upload = (state = initialState, action) => {
  switch (action.type) {
    case UPLOAD_FILE_REQUEST:
    case UPLOAD_IMAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case UPLOAD_FILE_SUCCESS:
    case UPLOAD_IMAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case UPLOAD_FILE_FAILURE:
    case UPLOAD_IMAGE_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default Upload;
