import React from "react";
import { Bar } from "react-chartjs-2";
import { fundColors } from "../../helpers/colors";
import { Chart } from "chart.js";

const BarChart = ({
  values = [
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ],
}) => {
  const data = {
    labels: ["APBN", "APBD", "Swasta", "Lainnya"],
    datasets: [
      {
        label: "Anggaran",
        backgroundColor: fundColors,
        borderColor: fundColors,
        hoverBackgroundColor: fundColors,
        hoverBorderColor: fundColors,
        borderWidth: 1,
        data: values[0],
        barPercentage: 0.55,
        categoryPercentage: 0.5,
      },
      {
        label: "Realisasi",
        backgroundColor: "#3d3fa0",
        borderColor: "#3d3fa0",
        hoverBackgroundColor: "#3d3fa0",
        hoverBorderColor: "#3d3fa0",
        borderWidth: 1,
        data: values[1],
        barPercentage: 0.55,
        categoryPercentage: 0.5,
      },
    ],
  };

  const option = {
    // toolbar: {
    //   show: false,
    // },
    tooltips: {
      enabled: false,
      // callbacks: {
      //   title: function (tooltipItem, data) {
      //     // console.log({ tooltipItem, data });
      //     return data.labels[tooltipItem[0].index];
      //   },
      //   label: function (tooltipItem, data) {
      //     var dataset = data.datasets[tooltipItem.datasetIndex];
      //     var meta = dataset._meta[Object.keys(dataset._meta)[0]];
      //     var total = meta.total;
      //     var currentValue = dataset.data[tooltipItem.index];
      //     var percentage = parseFloat(
      //       ((currentValue / total) * 100).toFixed(1)
      //     );
      //     // console.log({tooltipItem, data})
      //     return currentValue + " (" + percentage + "%)";
      //   },
      // },
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            // max: Math.max(...data.datasets[0].data) + 1,
            // display: false,
          },
          // gridLines: false,
          display: false,
        },
      ],
      xAxes: [
        {
          // gridLines: false,
          display: false,
        },
      ],
    },
    layout: {
      padding: {
        // left: -200,
        // right: -200,
        top: 30,
        // top: 0,
        bottom: 0,
      },
    },
    legend: {
      display: false,
    },
    // plugins: {
    //   datalabels: {
    //     anchor: "end",
    //     align: "top",
    //     formatter: Math.round,
    //     font: {
    //       weight: "bold",
    //     },
    //   },
    // },
    hover: {
      animationDuration: 0
    },
    animation: {
      duration: 1,
      onComplete: function () {
        const chartInstance = this.chart;
        const ctx = chartInstance.ctx;

        ctx.font = Chart.helpers.fontString(
          18,
          "bold",
          Chart.defaults.global.defaultFontFamily,
        );
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";

        this.data.datasets.forEach(function (dataset, i) {
          const meta = chartInstance.controller.getDatasetMeta(i);
          meta.data.forEach(function (bar, index) {
            const data = dataset.data[index];
            ctx.fillText(data, bar._model.x, bar._model.y - 10);
          });
        });
      },
    },
  };

  return <Bar width={600} height={230} data={data} options={option} />;
};

export default BarChart;
