import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { compose, withProps, withState, withHandlers } from "recompose";
import { googleMapsApi } from "../../helpers/config";
import {
  MapMarkerDefault,
  MapMarkerActive,
} from "../../assets/images/MapMarker";

const GoogleMapsComponen = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleMapsApi}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  withState("zoom", "setZoom", 10),
  withState("id", "setId", ""),
  withState("isVisible", "setIsVisible", false),
  withHandlers(() => {
    const refs = {
      map: undefined,
    };

    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onZoomChanged:
        ({ setZoom }) =>
        () => {
          setZoom(refs.map.getZoom());
        },
      onClick:
        ({ setId }) =>
        (id) => {
          setId(id);
        },
    };
  })
)((props) => (
  <GoogleMap
    defaultCenter={{ lat: -6.176312, lng: 106.828605 }}
    zoom={props.zoom}
    ref={props.onMapMounted}
    onZoomChanged={props.onZoomChanged}
    onClick={props.onClick}
    center={
      props.locationActiveIndex
        ? props.locations[props.locationActiveIndex]
          ? {
              lat: props.locations[props.locationActiveIndex].latitude,
              lng: props.locations[props.locationActiveIndex].longitude,
            }
          : props.locations[0]
          ? {
              lat: props.locations[0].latitude,
              lng: props.locations[0].longitude,
            }
          : { lat: -6.176312, lng: 106.828605 }
        : props.locations[0]
        ? {
            lat: props.locations[0].latitude,
            lng: props.locations[0].longitude,
          }
        : { lat: -6.176312, lng: 106.828605 }
    }
  >
    <>
      {props.locations.map(
        ({ latitude: lat, longitude: lng }, locationIndex) => (
          <Marker
            key={locationIndex}
            position={{ lat, lng }}
            icon={
              locationIndex === props.locationActiveIndex
                ? MapMarkerActive
                : MapMarkerDefault
            }
            onClick={() => props.setLocationActiveIndex?.(locationIndex)}
          ></Marker>
        )
      )}
    </>
  </GoogleMap>
));

const GMapsLocations = ({
  locations = [],
  locationActiveIndex,
  setLocationActiveIndex,
}) => (
  <GoogleMapsComponen
    locations={locations}
    locationActiveIndex={locationActiveIndex}
    setLocationActiveIndex={setLocationActiveIndex}
  />
);

export default GMapsLocations;
