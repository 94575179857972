import React from "react";
import { Redirect } from "react-router-dom";

// publicPageRoutes
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/FogetPassword";
import ResetPwd from "../pages/Authentication/ResetPasswrod";

// authProtectedPageRoutes
import Overview from "../pages/Overview";
import Detail from "../pages/Detail";
import Help from "../pages/Help";
import Location from "../pages/Location";

// SuperAdminPageRoutes
import IKU from "../pages/MasterData/IKU";
import Pilar from "../pages/MasterData/Pilar";
import Strategy from "../pages/MasterData/Strategy";
import Program from "../pages/MasterData/Program";
import Activity from "../pages/MasterData/Activity";
import Assignment from "../pages/MasterData/Assignment";
import InCharge from "../pages/MasterAdmin/InCharge";
import Account from "../pages/MasterAdmin/Account";


const authProtectedRoutes = [
  { path: "/overview", component: Overview },
  { path: "/detail", component: Detail },
  { path: "/help", component: Help },
  { path: "/location", component: Location },
  { path: "/", exact: true, component: () => <Redirect to="/overview" /> }
];

const SuperAdminRoutes = [
  { path: "/iku", component: IKU },
  { path: "/pilar", component: Pilar },
  { path: "/strategy", component: Strategy },
  { path: "/program", component: Program },
  { path: "/activity", component: Activity },
  { path: "/assignment", component: Assignment },
  { path: "/in-charge", component: InCharge },
  { path: "/account", component: Account },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forget-password", component: ForgetPwd },
  { path: "/reset-password/:id/:token", component: ResetPwd },
  { path: "/pages-register", component: Register },
];

export { authProtectedRoutes, publicRoutes, SuperAdminRoutes };
