import React from "react";
import GoogleMapReact from "google-map-react";
import { validateLatLng } from "../../helpers/utils";
import { color } from "../../helpers/colors";
import { googleMapsApi } from "../../helpers/config";

export default function GMapsSelect({ lat, lng, getCenterCoordinate }) {
  const defaultProps = {
    defaultCenter: {
      lat: -6.175392,
      lng: 106.827153,
    },
    center: validateLatLng(lat, lng)
      ? {
          lat,
          lng,
        }
      : null,
    zoom: 11,
  };

  const onChange = ({ center }) => {
    if (typeof getCenterCoordinate === "function") {
      getCenterCoordinate(center);
    }
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: googleMapsApi }}
        defaultCenter={defaultProps.defaultCenter}
        center={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onChange={onChange}
      />
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
        }}
      >
        <i
          className="mdi mdi-map-marker"
          style={{
            fontSize: 40,
            color: color.primary,
            position: "absolute",
            top: -47,
            left: -20,
          }}
        />
      </div>
    </div>
  );
}
