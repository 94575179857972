import {
  GET_USERS_REQUEST,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_USER_PASSWORD_SUCCESS,
  UPDATE_USER_PASSWORD_FAILURE,
} from "./actionTypes";

export const getUsersRequest = (callback) => ({
  type: GET_USERS_REQUEST,
  payload: { callback },
});

export const getUsersSuccess = (response) => ({
  type: GET_USERS_SUCCESS,
  payload: response,
});

export const getUsersFailure = (error) => ({
  type: GET_USERS_FAILURE,
  payload: error,
});

export const createUserRequest = (userData, callback) => ({
  type: CREATE_USER_REQUEST,
  payload: { userData, callback },
});

export const createUserSuccess = (response) => ({
  type: CREATE_USER_SUCCESS,
  payload: response,
});

export const createUserFailure = (error) => ({
  type: CREATE_USER_FAILURE,
  payload: error,
});

export const updateUserRequest = (_id, userData, callback) => ({
  type: UPDATE_USER_REQUEST,
  payload: { _id, userData, callback },
});

export const updateUserSuccess = (response) => ({
  type: UPDATE_USER_SUCCESS,
  payload: response,
});

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
});

export const deleteUserRequest = (_id, callback) => ({
  type: DELETE_USER_REQUEST,
  payload: { _id, callback },
});

export const deleteUserSuccess = (response) => ({
  type: DELETE_USER_SUCCESS,
  payload: response,
});

export const deleteUserFailure = (error) => ({
  type: DELETE_USER_FAILURE,
  payload: error,
});

export const updateUserPasswordRequest = (_id, password, callback) => ({
  type: UPDATE_USER_PASSWORD_REQUEST,
  payload: { _id, password, callback },
});

export const updateUserPasswordSuccess = (response) => ({
  type: UPDATE_USER_PASSWORD_SUCCESS,
  payload: response,
});

export const updateUserPasswordFailure = (error) => ({
  type: UPDATE_USER_PASSWORD_FAILURE,
  payload: error,
});
