import React, { useState, useLayoutEffect, useEffect } from 'react';
import {
  Speedo,
  HorizontalBarChart,
  PieChart,
  PolarChart,
  BarChartFunds,
  BarChartActivities,
  BlockSpinner,
} from '../../components';
import Select from 'react-select';
import {
  useFilterPilarOptions,
  useFilterInChangeOptions,
  useFilterYearOptions,
} from '../../hooks';
import {
  activityColors,
  fundColors,
  ikuColors,
  customColorSelect,
  color,
} from '../../helpers/colors';
import bgMap from '../../assets/images/bg-map.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  getIkuRequest,
  getIkuAccumulationRequest,
  getOverviewRequest,
} from '../../store/actions';
import ReactTooltip from 'react-tooltip';

const Overview = () => {
  /* HOOKS */
  const dispatch = useDispatch();

  /* LOCAL STATE */
  const [defaultPilarValue] = useState({ value: 'all', label: 'Semua Pilar' });
  const filterPilarOptions = useFilterPilarOptions(true);
  const [filterPilarValue, setFilterPilarValue] = useState(defaultPilarValue);

  const [defaultInChargerValue] = useState({
    value: 'all',
    label: 'Semua Penanggung Jawab',
  });
  const filterInChargeOptions = useFilterInChangeOptions(true);
  const [filterInChargeValue, setFilterInChargeValue] = useState(null);

  const filterStartYearOptions = useFilterYearOptions();
  const [filterStartYearValue, setFilterStartValue] = useState(null);

  const filterEndYearOptions = useFilterYearOptions(
    filterStartYearValue?.value || 2018,
  );
  const [filterEndYearValue, setFilterEndYearValue] = useState(null);

  const [width, setWidth] = useState(0);

  const [verticalChartProgressValues, setVerticalChartProgressValues] = useState([
    0, 0, 0,
  ]);
  const [verticalChartFundValues, setVerticalChartFundValues] = useState([
    [0, 0, 0, 0],
    [0, 0, 0, 0],
  ]);

  const [horizontalChartProgressValues, setHorizontalChartProgressValues] =
    useState([]);
  const [horizontalChartFundValues, setHorizontalChartFundValues] = useState([]);

  const [pieChartProgressDefault, setPieChartProgressDefault] = useState([]);
  const [pieChartProgressValues, setPieChartProgressValues] = useState([]);
  const [pieChartProgressRaw, setPieChartProgressRaw] = useState([]);
  const [pieChartFundDefault, setPieChartFundDefault] = useState([]);
  const [pieChartFundValues, setPieChartFundValues] = useState([]);
  const [pieChartFundRaw, setPieChartFundRaw] = useState([]);

  /* GLOBAL_STORE */
  const { IKU, accumulation, isLoadingIku, isLoadingAccumulation } = useSelector(
    (state) => state.IKU,
  );
  const { overview, isLoadingOverview } = useSelector((state) => state.Overview);
  const { user } = useSelector((state) => state.Auth);

  /* FUNCTIONS */
  const onChangeFilter = (option, { name, action }) => {
    switch (name) {
      case 'pilar':
        setFilterPilarValue(option || defaultPilarValue);
        if (action === 'clear') setFilterInChargeValue(null);
        if (option?.value !== 'all' && filterInChargeValue === null) {
          setFilterInChargeValue(defaultInChargerValue);
        }
        break;
      case 'in-charge':
        setFilterInChargeValue(
          option ||
            (filterPilarValue.value === 'all' ? null : defaultInChargerValue),
        );
        break;
      case 'start-year':
        setFilterStartValue(option);
        if (!option) {
          setFilterEndYearValue(null);
        } else if (filterEndYearValue?.value < option?.value) {
          setFilterEndYearValue(option);
        }
        break;
      case 'end-year':
        setFilterEndYearValue(option);
        break;
      default:
        break;
    }
  };

  const convertToBillion = (million) => {
    return (million / 1000).toFixed(2) * 1;
  };

  /* LIFECYCLE */
  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  useEffect(() => {
    dispatch(getIkuRequest());
    dispatch(getIkuAccumulationRequest());
  }, []); // eslint-disable-line

  useEffect(() => {
    const queryString = {
      pilar: filterPilarValue.value,
      incharge:
        user.role === 'admin'
          ? user?.institusi?._id
          : filterInChargeValue?.value || 'none',
      start: filterStartYearValue?.value || 2018,
      end: filterEndYearValue?.value || 2029,
    };
    dispatch(getOverviewRequest(queryString));
    // eslint-disable-next-line
  }, [
    filterPilarValue,
    filterInChargeValue,
    filterStartYearValue,
    filterEndYearValue,
  ]);

  useEffect(() => {
    const pilar = filterPilarValue?.value || null;
    const pilarLabel = filterPilarValue?.label || null;
    const inChargeValue = filterInChargeValue?.value || null;
    // const inChargeLabel = filterInChargeValue?.label || null;
    const { progress, budget, realization } = overview || {};
    if (pilar !== 'all' && (inChargeValue !== 'all' || user.role === 'admin')) {
      const { Perencanaan, Pelaksanaan, Selesai } = progress?.[0]?.data || {};
      const newVerticalChartProgressValues = [
        Perencanaan || 0,
        Pelaksanaan || 0,
        Selesai || 0,
      ];
      const newVerticalChartFundValues = [
        [
          convertToBillion(budget?.[0]?.data?.APBN || 0),
          convertToBillion(budget?.[0]?.data?.APBD || 0),
          convertToBillion(budget?.[0]?.data?.Swasta || 0),
          convertToBillion(budget?.[0]?.data?.Lainnya || 0),
        ],
        [
          convertToBillion(realization?.[0]?.data?.APBN || 0),
          convertToBillion(realization?.[0]?.data?.APBD || 0),
          convertToBillion(realization?.[0]?.data?.Swasta || 0),
          convertToBillion(realization?.[0]?.data?.Lainnya || 0),
        ],
      ];
      setVerticalChartProgressValues(newVerticalChartProgressValues);
      setVerticalChartFundValues(newVerticalChartFundValues);
    } else {
      const newHorizontalChartProgressValues =
        progress?.map(({ name, data }) => ({
          name: name,
          values: [
            data?.Perencanaan || 0,
            data?.Pelaksanaan || 0,
            data?.Selesai || 0,
          ],
        })) || [];
      const newHorizontalChartFundValues =
        budget?.map(({ name, data }) => ({
          name: name,
          values: [
            convertToBillion(data?.APBN || 0),
            convertToBillion(data?.APBD || 0),
            convertToBillion(data?.Swasta || 0),
            convertToBillion(data?.Lainnya || 0),
          ],
        })) || [];

      setHorizontalChartProgressValues(newHorizontalChartProgressValues);
      setHorizontalChartFundValues(newHorizontalChartFundValues);

      if (pilar === 'all') {
        if (!inChargeValue || inChargeValue !== 'all') {
          const newPieChartProgressValues = progress
            ? [
                progress
                  ?.map(({ data }) => data?.Perencanaan || 0)
                  .reduce((a, b) => a + b, 0),
                progress
                  ?.map(({ data }) => data?.Pelaksanaan || 0)
                  .reduce((a, b) => a + b, 0),
                progress
                  ?.map(({ data }) => data?.Selesai || 0)
                  .reduce((a, b) => a + b, 0),
              ]
            : [0, 0, 0];

          const newPieChartFundValues = budget
            ? [
                convertToBillion(
                  budget
                    ?.map(({ data }) => data?.APBN || 0)
                    .reduce((a, b) => a + b, 0),
                ),
                convertToBillion(
                  budget
                    ?.map(({ data }) => data?.APBD || 0)
                    .reduce((a, b) => a + b, 0),
                ),
                convertToBillion(
                  budget
                    ?.map(({ data }) => data?.Swasta || 0)
                    .reduce((a, b) => a + b, 0),
                ),
                convertToBillion(
                  budget
                    ?.map(({ data }) => data?.Lainnya || 0)
                    .reduce((a, b) => a + b, 0),
                ),
              ]
            : [0, 0, 0, 0];

          if (!inChargeValue) {
            setPieChartProgressDefault(newPieChartProgressValues);
            setPieChartProgressValues(newPieChartProgressValues);
            setPieChartProgressRaw(
              progress?.map(({ name, data }) => ({
                name,
                values: [
                  data?.Perencanaan || 0,
                  data?.Pelaksanaan || 0,
                  data?.Selesai || 0,
                ],
              })),
            );

            setPieChartFundDefault(newPieChartFundValues);
            setPieChartFundValues(newPieChartFundValues);
            setPieChartFundRaw(
              budget?.map(({ name, data }) => ({
                name,
                values: [
                  convertToBillion(data?.APBN || 0),
                  convertToBillion(data?.APBD || 0),
                  convertToBillion(data?.Swasta || 0),
                  convertToBillion(data?.Lainnya || 0),
                ],
              })),
            );
          } else {
            setPieChartProgressValues(newPieChartProgressValues);
            setPieChartFundValues(newPieChartFundValues);
          }
        } else {
          setPieChartProgressValues(pieChartProgressDefault);
          setPieChartFundValues(pieChartFundDefault);
        }
      } else {
        if (inChargeValue === 'all') {
          // console.log({ pieChartProgressRaw, pieChartFundRaw });
          const filteredPieChartProgressRaw = pieChartProgressRaw.filter(
            ({ name }) => name === pilarLabel,
          )?.[0]?.values || [0, 0, 0];
          const filteredPieChartFundRaw = pieChartFundRaw.filter(
            ({ name }) => name === pilarLabel,
          )?.[0]?.values || [0, 0, 0, 0];
          setPieChartProgressValues(filteredPieChartProgressRaw);
          setPieChartFundValues(filteredPieChartFundRaw);
        }
      }
    }
  }, [overview]); // eslint-disable-line

  /* COMPONENTS */
  const KeyPerformanceIndicator = () => (
    <div className='kpi-container mb-5'>
      <p className='page-title'>Indikator Kinerja Utama</p>
      <div className='row'>
        <div className='col-xl-6 col-lg-12 mb-4'>
          {width && <Speedo value={accumulation} />}
          {<BlockSpinner show={isLoadingAccumulation} />}
        </div>
        {[0, 4].map((indexStart) => (
          <div
            key={indexStart}
            className='col-xl-3 col-sm-6 mb-4 d-flex align-content-between flex-wrap'
          >
            {IKU.slice(indexStart, indexStart + 4).map((kpi, idx) => (
              <div key={idx} className={`${idx < 3 && 'mb-4'} w-100`}>
                <div
                  data-tip={kpi.description}
                  data-background-color={ikuColors[indexStart + idx]}
                  data-class='kpi-tooltip'
                  data-offset="{'top': 30}"
                  className='kpi-list-card card-shadow p-2'
                >
                  <div className='kpi-percentage'>
                    <div style={{ backgroundColor: ikuColors[indexStart + idx] }}>
                      <div className={`${kpi.unit !== '%' && 'block-unit'}`}>
                        <p>
                          {kpi?.inputValue?.toFixed(1) * 1 || '- '}
                          {kpi.unit === '%' && kpi.unit}
                        </p>
                        <p className='small'>
                          {kpi.unit !== '%' &&
                            (kpi.unit === 'minutes' ? 'min' : kpi.unit)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='kpi-name'>
                    <p>{kpi.name}</p>
                  </div>
                </div>
              </div>
            ))}
            {<BlockSpinner show={isLoadingIku} />}
          </div>
        ))}
      </div>
    </div>
  );

  const ViewsAndFilters = () => (
    <div className='actions-group justify-content-end'>
      <div className='action-container'>
        <p>Filter by :</p>
        <Select
          name='pilar'
          className='action filter-pilar-width mr-2'
          placeholder='Pilar'
          value={filterPilarValue}
          onChange={onChangeFilter}
          options={filterPilarOptions}
          isClearable={filterPilarValue.value !== 'all'}
          styles={customColorSelect}
        />
        {user?.role !== 'admin' && (
          <Select
            name='in-charge'
            className='action filter-in-change-width mr-2'
            placeholder='Pilih Penanggung Jawab'
            value={filterInChargeValue}
            onChange={onChangeFilter}
            options={filterInChargeOptions}
            isClearable={
              filterPilarValue.value !== 'all' &&
              filterInChargeValue?.value === 'all'
                ? false
                : true
            }
            styles={customColorSelect}
          />
        )}
        <Select
          name='start-year'
          className='action filter-year mr-2'
          placeholder='Tahun Awal'
          value={filterStartYearValue}
          onChange={onChangeFilter}
          options={filterStartYearOptions}
          isClearable
          styles={customColorSelect}
        />
        <Select
          name='end-year'
          className='action filter-year'
          placeholder='Tahun Akhir'
          value={filterEndYearValue}
          onChange={onChangeFilter}
          options={filterEndYearOptions}
          isClearable
          isDisabled={!filterStartYearValue}
          styles={customColorSelect}
        />
      </div>
    </div>
  );

  const HorizontalCharts = () => (
    <div className='row'>
      <div className='col-xl-6 col-md-12 mt-4'>
        <div className='card-shadow p-4'>
          {filterPilarValue?.value === 'all' ? (
            <p className='page-title'>{`Kegiatan Per ${
              filterInChargeValue?.value === 'all'
                ? 'Penanggung Jawab'
                : filterInChargeValue?.value
                ? 'Pilar ' + filterInChargeValue?.label
                : user.role === 'admin'
                ? 'Pilar ' + user?.institusi?.name
                : 'Pilar'
            }`}</p>
          ) : (
            <p className='page-title'>{`Kegiatan Per Pilar ${filterPilarValue.value}`}</p>
          )}
          {horizontalChartProgressValues.map((activity, index) => (
            <HorizontalBarChart
              key={index}
              type='activity'
              title={activity.name}
              values={activity.values}
              hideLine={index + 1 === horizontalChartProgressValues.length}
            />
          ))}
          <div className='d-flex flex-wrap'>
            {['Perencanaan', 'Pelaksanaan', 'Selesai'].map((title, idx) => (
              <div
                key={idx}
                className={`d-flex align-items-center ${idx < 3 && 'mr-4'}`}
              >
                <i
                  className='mdi mdi-bookmark mdi-24px mr-2'
                  style={{ color: activityColors[idx] }}
                />
                <p className='m-0'>{title}</p>
              </div>
            ))}
          </div>
          <BlockSpinner show={isLoadingOverview} />
        </div>
      </div>
      <div className='col-xl-6 col-md-12 mt-4'>
        <div className='card-shadow p-4'>
          {filterPilarValue?.value === 'all' ? (
            <p className='page-title'>{`Anggaran Per ${
              filterInChargeValue?.value === 'all'
                ? 'Penanggung Jawab'
                : filterInChargeValue?.value
                ? 'Pilar ' + filterInChargeValue?.label
                : user.role === 'admin'
                ? 'Pilar ' + user?.institusi?.name
                : 'Pilar'
            }`}</p>
          ) : (
            <p className='page-title'>{`Anggaran Per Pilar ${filterPilarValue.value}`}</p>
          )}
          {horizontalChartFundValues.map((fund, index) => (
            <HorizontalBarChart
              key={index}
              type='fund'
              title={fund.name}
              values={fund.values}
              hideLine={index + 1 === horizontalChartFundValues.length}
            />
          ))}
          <div className='d-flex flex-wrap'>
            {['APBN', 'APBD', 'Swasta', 'Lainnya'].map((title, idx) => (
              <div
                key={idx}
                className={`d-flex align-items-center ${idx < 3 && 'mr-4'}`}
              >
                <i
                  className='mdi mdi-bookmark mdi-24px mr-2'
                  style={{ color: fundColors[idx] }}
                />
                <p className='m-0'>{title}</p>
              </div>
            ))}
          </div>
          <BlockSpinner show={isLoadingOverview} />
        </div>
      </div>
    </div>
  );

  const PieCharts = () => (
    <div className='row'>
      <div className='col-md-6 col-sm-12 mt-4'>
        <div className='card-shadow p-4'>
          <p className='page-title'>
            {'Progres Kegiatan RITJ '}
            {filterInChargeValue?.value !== 'all'
              ? filterInChargeValue?.label || ''
              : ''}
            {filterPilarValue.value !== 'all' ? filterPilarValue?.label : ''}
          </p>
          <div className='row align-items-center'>
            <div className='col-sm-6'>
              <PieChart values={pieChartProgressValues} />
            </div>
            <div className='m-2 d-sm-none' />
            <div className='col-sm-6 d-flex justify-content-center'>
              <div className='d-inline-flex align-items-center pie-chart-info'>
                <div>
                  <p className='total'>
                    {pieChartProgressValues.reduce((a, b) => a + b, 0)}
                  </p>
                  <p className='type'>Kegiatan</p>
                  {['Perencanaan', 'Pelaksanaan', 'Selesai'].map((title, idx) => (
                    <div key={idx} className={'d-flex align-items-center'}>
                      <i
                        className='mdi mdi-bookmark mdi-24px mr-2'
                        style={{ color: activityColors[idx] }}
                      />
                      <p className='m-0'>{title}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <BlockSpinner show={isLoadingOverview} />
        </div>
      </div>

      <div className='col-md-6 col-sm-12 mt-4'>
        <div className='card-shadow p-4'>
          <p className='page-title'>
            {'Sumber Anggaran RITJ '}
            {filterInChargeValue?.value !== 'all'
              ? filterInChargeValue?.label || ''
              : ''}
            {filterPilarValue.value !== 'all' ? filterPilarValue?.label : ''}
          </p>
          <div className='row align-items-center'>
            <div className='col-sm-6'>
              <PolarChart values={pieChartFundValues} />
            </div>
            <div className='m-2 d-sm-none' />
            <div className='col-sm-6 d-flex justify-content-center'>
              <div className='d-inline-flex align-items-center pie-chart-info'>
                <div>
                  <p className='total'>
                    {pieChartFundValues.reduce((a, b) => a + b, 0).toFixed(2) * 1}
                  </p>
                  <p className='type'>Miliar</p>
                  {['APBN', 'APBD', 'Swasta', 'Lainnya'].map((title, idx) => (
                    <div key={idx} className={'d-flex align-items-center'}>
                      <i
                        className='mdi mdi-bookmark mdi-24px mr-2'
                        style={{ color: fundColors[idx] }}
                      />
                      <p className='m-0'>{title}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <BlockSpinner show={isLoadingOverview} />
        </div>
      </div>
    </div>
  );

  const VerticalCharts = () => (
    <>
      <div className='row'>
        <div className='col-12 mt-4'>
          <div className='card-shadow pr-4 pl-4 pt-5 pb-5'>
            <div className='w-100'>
              <p className='page-title text-center'>
                {`Progres Kegiatan RITJ ${filterPilarValue.label} ${
                  user.role === 'admin'
                    ? user?.institusi?.name
                    : filterInChargeValue?.label
                }`}
              </p>
            </div>
            <div
              className='w-100 d-flex justify-content-center position-relative align-items-center'
              style={{
                backgroundImage: `url(${bgMap})`,
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center bottom',
                paddingBottom: '11%',
              }}
            >
              <div
                className='position-absolute d-none d-lg-block'
                style={{ left: 0 }}
              >
                {['Perencanaan', 'Pelaksanaan', 'Selesai'].map((title, idx) => (
                  <div
                    key={idx}
                    className={`d-flex align-items-center ${idx < 3 && 'mb-3'}`}
                  >
                    <i
                      className='mdi mdi-bookmark mdi-24px mr-2'
                      style={{ color: activityColors[idx] }}
                    />
                    <p className='m-0'>{title}</p>
                  </div>
                ))}
              </div>
              <div className='w-75'>
                <BarChartActivities values={verticalChartProgressValues} />
              </div>
              <div
                className='position-absolute h-100 d-flex justify-content-center'
                style={{
                  width: '12%',
                  display: 'flex',
                  alignItems: 'end',
                  marginTop: '20%',
                }}
              >
                <div
                  style={{
                    border: 'solid 1px',
                    borderColor: color.primary,
                    borderRadius: '50%',
                    width: '100%',
                    paddingBottom: '100%',
                    position: 'relative',
                    backgroundColor: 'white',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '15%',
                      right: '15%',
                      bottom: '15%',
                      left: '15%',
                    }}
                  >
                    <img
                      src={
                        user?.role === 'admin'
                          ? user?.institusi?.logo
                          : overview?.progress?.[0]?.data?.logo || ''
                      }
                      alt=''
                      width='100%'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style={{ height: 15 }} />
            <div className='mt-2 d-flex flex-wrap d-block d-lg-none'>
              {['Perencanaan', 'Pelaksanaan', 'Selesai'].map((title, idx) => (
                <div
                  key={idx}
                  className={`d-flex align-items-center ${idx < 3 && 'mr-4'}`}
                >
                  <i
                    className='mdi mdi-bookmark mdi-18px mr-2'
                    style={{ color: activityColors[idx] }}
                  />
                  <p className='m-0' style={{ fontSize: 12 }}>
                    {title}
                  </p>
                </div>
              ))}
            </div>
            <BlockSpinner show={isLoadingOverview} />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-12 mt-4'>
          <div className='card-shadow pr-4 pl-4 pt-5 pb-5'>
            <div className='w-100'>
              <p className='page-title text-center'>
                {`Sumber Anggaran RITJ ${filterPilarValue.label} ${
                  user.role === 'admin'
                    ? user?.institusi?.name
                    : filterInChargeValue?.label
                }`}
              </p>
            </div>
            <div className='w-100 d-flex justify-content-center position-relative align-items-center'>
              <div
                className='position-absolute d-none d-lg-block'
                style={{ left: 0 }}
              >
                {['APBN', 'APBD', 'Swasta', 'Lainnya', 'Realisasi'].map(
                  (title, idx) => (
                    <div
                      key={idx}
                      className={`d-flex align-items-center ${idx < 4 && 'mb-3'}`}
                    >
                      <i
                        className='mdi mdi-bookmark mdi-24px mr-2'
                        style={{ color: fundColors.concat(['#3d3fa0'])[idx] }}
                      />
                      <p className='m-0'>{title}</p>
                    </div>
                  ),
                )}
              </div>
              <div className='w-75'>
                <BarChartFunds values={verticalChartFundValues} />
              </div>
            </div>
            <div className='mt-2 d-flex flex-wrap d-block d-lg-none'>
              {['APBN', 'APBD', 'Swasta', 'Lainnya', 'Realisasi'].map(
                (title, idx) => (
                  <div
                    key={idx}
                    className={`d-flex align-items-center ${idx < 4 && 'mr-4'}`}
                  >
                    <i
                      className='mdi mdi-bookmark mdi-18px mr-2'
                      style={{ color: fundColors.concat(['#3d3fa0'])[idx] }}
                    />
                    <p className='m-0' style={{ fontSize: 12 }}>
                      {title}
                    </p>
                  </div>
                ),
              )}
            </div>
            <BlockSpinner show={isLoadingOverview} />
          </div>
        </div>
      </div>
    </>
  );

  const Tooltip = () => (
    <ReactTooltip
      // className="kpi-tooltip"
      place='bottom'
      effect='solid'
    />
  );

  return (
    <>
      <Tooltip />
      <KeyPerformanceIndicator />
      <ViewsAndFilters />
      {filterPilarValue?.value !== 'all' &&
      (filterInChargeValue?.value !== 'all' || user.role === 'admin') ? (
        <VerticalCharts />
      ) : (
        <>
          <HorizontalCharts />
          <PieCharts />
        </>
      )}
    </>
  );
};

export default Overview;
