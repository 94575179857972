import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input, Modal } from "reactstrap";
import { TableForm } from "../../components";
import { customColorSelectValidate } from "../../helpers/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  getInstitutionsRequest,
  getUsersRequest,
  createUserRequest,
  updateUserRequest,
  deleteUserRequest,
  updateUserPasswordRequest,
} from "../../store/actions";
import { color } from "../../helpers/colors";
import Swal from "sweetalert2";

const Account = () => {
  /* HOOKS */
  const dispatch = useDispatch();

  /* LOCAL STATE */
  const [isEdit, setIsEdit] = useState(null);
  const [isEditPassword, setIsEditPassword] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [RoleOptions] = useState([
    { label: "Super Admin", value: "super-admin" },
    { label: "Admin", value: "admin" },
    { label: "Supervisor", value: "visitor" },
  ]);
  const [selectedInCharge, setSelectedInCharge] = useState(null);
  const [inChargeOptions, setInChargeOptions] = useState([]);
  const [accountName, setAccountName] = useState("");
  const [accountEmail, setAccountEmail] = useState("");
  const [leaderEmail, setLeaderEmail] = useState("");
  const [firstPassword, setFirstPassword] = useState("");
  const [showFirstPassword, setShowFirstPassword] = useState(false);
  const [secondPassword, setSecondPassword] = useState("");
  const [showSecondPassword, setShowSecondPassword] = useState(false);
  const [accountData, setAccountData] = useState([]);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [nik, setNik] = useState("");
  const [nip, setNip] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [accountDetail, setAccountDetail] = useState({});

  /* GLOBAL_STATE */
  const { institutions, isLoading: isLoadingInCharge } = useSelector(
    (state) => state.Institution
  );
  const { users, isLoading: isLoadingUsers } = useSelector(
    (state) => state.User
  );

  /* FUNCTIONS */
  const onChangeName = ({ target }) => {
    const { value } = target;
    setAccountName(value);
  };

  const onClickDetail = (account) => {
    setShowDetail(true);
    setAccountDetail(account);
  };

  const onClickEdit = (account) => {
    setIsEdit(account._id);
    setName(account?.name || "");
    setAccountName(account?.userName || "");
    setContact(account?.contact || "");
    setNik(account?.nik || "");
    setNip(account?.nip || "");
    setAccountEmail(account?.email || "");
    setLeaderEmail(account?.emailLeader || "");
    if (account?.institusi) {
      setSelectedInCharge({
        value: account?.institusi?._id,
        label: account.institusi.name,
      });
    }
  };

  const onClickEditPassword = (account) => {
    setIsEditPassword(account._id);
    setName(account?.name || "");
    setNik(account?.nik || "");
    setNip(account?.nip || "");
    setAccountName(account?.userName || "");
    setAccountEmail(account?.email || "");
    setLeaderEmail(account?.emailLeader || "");
    setContact(account?.contact || "");
    setFirstPassword("");
    setSecondPassword("");
  };

  const onClickDelete = (_id) => {
    dispatch(
      deleteUserRequest(_id, (err, resp) => {
        // console.log({ err, resp });
        if (err) {
          // error alert
        } else {
          // success alert
        }
      })
    );
  };

  const onClickSubmit = () => {
    if (isEdit) {
      const userData = {
        userName: accountName,
        name,
        nik,
        nip,
        contact,
        emailLeader:
          selectedRole?.value === "admin" ? leaderEmail : accountEmail,
        email: accountEmail,
      };
      dispatch(
        updateUserRequest(isEdit, userData, (err, resp) => {
          if (err) {
            Swal.fire({
              title: "Oops.",
              text: err,
              icon: "error",
              position: "top-end",
              confirmButtonColor: color.primary,
              timer: 10000,
            });
          } else {
            onClearForm();
            Swal.fire({
              title: "Berhasil.",
              text: "Akun berhasil diupdate.",
              icon: "success",
              position: "top-end",
              confirmButtonColor: color.primary,
              timer: 5000,
            });
          }
        })
      );
    } else if (isEditPassword) {
      dispatch(
        updateUserPasswordRequest(
          isEditPassword,
          firstPassword,
          (err, resp) => {
            if (err) {
              Swal.fire({
                title: "Oops.",
                text: err,
                icon: "error",
                position: "top-end",
                confirmButtonColor: color.primary,
                timer: 10000,
              });
            } else {
              onClearForm();
              Swal.fire({
                title: "Berhasil.",
                text: "Password berhasil diupdate.",
                icon: "success",
                position: "top-end",
                confirmButtonColor: color.primary,
                timer: 5000,
              });
            }
          }
        )
      );
    } else {
      const userData = {
        role: selectedRole?.value,
        institusi:
          selectedRole?.value === "admin" ? selectedInCharge?.value : null,
        name,
        nik,
        nip,
        contact,
        emailLeader:
          selectedRole?.value === "admin" ? leaderEmail : accountEmail,
        email: accountEmail,
        userName: accountName,
        password: firstPassword,
      };
      dispatch(
        createUserRequest(userData, (err, resp) => {
          if (err) {
            Swal.fire({
              title: "Oops.",
              text: err,
              icon: "error",
              position: "top-end",
              confirmButtonColor: color.primary,
              timer: 10000,
            });
          } else {
            onClearForm();
            Swal.fire({
              title: "Berhasil.",
              text: "Akun berhasil ditambahkan.",
              icon: "success",
              position: "top-end",
              confirmButtonColor: color.primary,
              timer: 5000,
            });
          }
        })
      );
    }
  };

  const onCLickCancel = () => {
    onClearForm();
  };

  const onClearForm = () => {
    setIsEdit(null);
    setIsEditPassword(null);
    setName("");
    setNik("");
    setNip("");
    setContact("");
    setAccountEmail("");
    setLeaderEmail("");
    setAccountName("");
    setFirstPassword("");
    setSecondPassword("");
    setSelectedInCharge(null);
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const isValidForm = () => {
    if (!selectedRole) return false;
    if (selectedRole?.value === "admin" && !selectedInCharge?.value)
      return false;
    if (!name) return false;
    if (!accountName) return false;
    if (!contact) return false;
    if (!nik) return false;
    if (!isEditPassword && !accountEmail) return false;
    if (!isEditPassword && selectedRole?.value === "admin" && !leaderEmail)
      return false;
    if (!isEdit && !firstPassword) return false;
    if (!isEdit && firstPassword !== secondPassword) return false;
    return true;
  };

  useEffect(() => {
    dispatch(getInstitutionsRequest());
    dispatch(getUsersRequest());
  }, []); // eslint-disable-line

  useEffect(() => {
    const newInChargeOptions = institutions.map((institution) => ({
      value: institution._id,
      label: institution.name,
    }));
    setInChargeOptions(newInChargeOptions);
  }, [institutions]);

  useEffect(() => {
    if (selectedRole?.value === "super-admin") {
      const newAccountData = users
        .filter(({ role }) => role === "super-admin")
        .map((account) => ({
          ...account,
          displayRole: "Super Admin",
          inCharge: "BPTJ",
        }));
      setAccountData(newAccountData);
      setSelectedInCharge(null);
      setLeaderEmail("");
    } else if (selectedRole?.value === "admin") {
      const newAccountData = users
        .filter(({ role }) => role === "admin")
        .map((account) => ({
          ...account,
          displayRole: "Admin",
          inCharge: account?.institusi?.name,
        }))
        .sort((a, b) => a?.inCharge?.localeCompare(b?.inCharge));
      setAccountData(newAccountData);
    } else if (selectedRole?.value === "visitor") {
      const newAccountData = users
        .filter(({ role }) => role === "visitor")
        .map((account) => ({
          ...account,
          displayRole: "Supervisor",
          inCharge: "Supervisor",
        }));
      setAccountData(newAccountData);
      setSelectedInCharge(null);
      setLeaderEmail("");
    } else {
      setAccountData([]);
      setSelectedInCharge(null);
      setLeaderEmail("");
    }
  }, [selectedRole, selectedInCharge, users]);

  return (
    <>
      <p className="page-title mb-4">
        {isEdit
          ? "Edit Akun"
          : isEditPassword
          ? "Edit Password"
          : "Tambah Akun"}
      </p>
      <div className="row">
        <div className="col-xs-12 col-xl-4 border-right form">
          <Select
            name="role"
            className="mb-3"
            placeholder="Pilih Tipe User / Hak User"
            isDisabled={isEdit || isEditPassword}
            value={selectedRole}
            options={RoleOptions}
            onChange={setSelectedRole}
            styles={customColorSelectValidate(selectedRole)}
            isClearable
          />
          {selectedRole?.value === "admin" && (
            <Select
              name="inCharge"
              className="mb-3"
              placeholder="Pilih Penanggung Jawab"
              isDisabled={isEdit || isEditPassword}
              value={selectedInCharge}
              options={inChargeOptions}
              onChange={setSelectedInCharge}
              styles={customColorSelectValidate(selectedInCharge)}
              isClearable
              isLoading={isLoadingInCharge}
            />
          )}
          {!isEditPassword && (
            <>
              <Input
                className="mb-3 input-data"
                placeholder="Nama"
                onChange={({ target }) => setName(target.value)}
                value={name}
                // invalid={selectedRole && !accountName}
                disabled={!selectedRole}
                style={{
                  backgroundColor: isEditPassword ? "#f2f2f2" : "",
                }}
              />
              <Input
                className="mb-3 input-data"
                placeholder="nik"
                onChange={({ target }) => {
                  if (!isNaN(target.value)) {
                    setNik(target.value);
                  }
                }}
                value={nik}
                // invalid={selectedRole && !accountName}
                disabled={!selectedRole}
                style={{
                  backgroundColor: isEditPassword ? "#f2f2f2" : "",
                }}
              />
              <Input
                className="mb-3 input-data"
                placeholder="nip"
                onChange={({ target }) => {
                  if (!isNaN(target.value)) {
                    setNip(target.value);
                  }
                }}
                value={nip}
                // invalid={selectedRole && !accountName}
                disabled={!selectedRole}
                style={{
                  backgroundColor: isEditPassword ? "#f2f2f2" : "",
                }}
              />
              <Input
                className="mb-3 input-data"
                placeholder="Kontak"
                onChange={({ target }) => {
                  if (!isNaN(target.value)) {
                    setContact(target.value);
                  }
                }}
                value={contact}
                // invalid={selectedRole && !accountName}
                disabled={!selectedRole}
                style={{
                  backgroundColor: isEditPassword ? "#f2f2f2" : "",
                }}
              />
              <Input
                className="mb-3 input-data"
                type="email"
                placeholder={`Masukan Email${
                  selectedRole?.value === "admin" ? " Administrator" : ""
                }`}
                value={accountEmail}
                onChange={({ target }) => setAccountEmail(target.value)}
                invalid={accountEmail.length && !validateEmail(accountEmail)}
                disabled={!selectedRole}
              />
              {selectedRole?.value === "admin" && (
                <Input
                  className="mb-3 input-data"
                  type="email"
                  placeholder="Masukkan Email Pimpinan / Atasan Langsung"
                  value={leaderEmail}
                  onChange={({ target }) => setLeaderEmail(target.value)}
                  invalid={leaderEmail.length && !validateEmail(leaderEmail)}
                  disabled={!selectedRole}
                />
              )}
            </>
          )}
          <Input
            className="mb-3 input-data"
            placeholder={`Username ${
              selectedRole?.value === "super-admin"
                ? "(su-nama)"
                : selectedRole?.value === "admin"
                ? "(admin-instansi)"
                : selectedRole?.value === "visitor"
                ? "(sv-instansi)"
                : ""
            }`}
            onChange={onChangeName}
            value={accountName}
            // invalid={selectedRole && !accountName}
            disabled={!selectedRole || isEditPassword}
            style={{
              backgroundColor: isEditPassword ? "#f2f2f2" : "",
            }}
          />
          {!isEdit && (
            <>
              <div style={{ position: "relative" }}>
                <Input
                  className="mb-3 input-data"
                  type={showFirstPassword ? "text" : "password"}
                  placeholder={`Password${isEditPassword ? " Baru" : ""}`}
                  value={firstPassword}
                  onChange={({ target }) => setFirstPassword(target.value)}
                  disabled={!selectedRole}
                  style={{ paddingRight: 48 }}
                  // invalid={selectedRole && !firstPassword}
                  errorMessage="Max Value 6"
                  validate={{
                    required: { value: true },
                  }}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setShowFirstPassword(!showFirstPassword)}
                >
                  {showFirstPassword ? (
                    <i className="mdi mdi-eye-off" />
                  ) : (
                    <i className="mdi mdi-eye" />
                  )}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <Input
                  className="mb-3 input-data"
                  type={showSecondPassword ? "text" : "password"}
                  placeholder={`Ulangi Password${
                    isEditPassword ? " Baru" : ""
                  }`}
                  value={secondPassword}
                  onChange={({ target }) => setSecondPassword(target.value)}
                  // invalid={
                  //   selectedRole &&
                  //   firstPassword.length > 0 &&
                  //   secondPassword.length > 0 &&
                  //   firstPassword !== secondPassword
                  // }
                  disabled={!selectedRole}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setShowSecondPassword(!showSecondPassword)}
                >
                  {showSecondPassword ? (
                    <i className="mdi mdi-eye-off" />
                  ) : (
                    <i className="mdi mdi-eye" />
                  )}
                </div>
              </div>
            </>
          )}
          <button
            onClick={onClickSubmit}
            type="button"
            // className={`${isValidForm() ? "bg-green" : "bg-grey"} mb-3`}
            // disabled={!isValidForm()}
            className={`${
              isEditPassword
                ? firstPassword.length > 0 && firstPassword === secondPassword
                  ? "bg-green"
                  : "bg-grey"
                : isValidForm()
                ? "bg-green"
                : "bg-grey"
            } mb-3`}
            disabled={
              isEditPassword
                ? firstPassword.length > 0 && firstPassword === secondPassword
                  ? false
                  : true
                : !isValidForm()
            }
          >
            {isEdit || isEditPassword ? "Simpan Perubahan" : "Tambah Akun"}
          </button>
          {(isEdit || isEditPassword) && (
            <button onClick={onCLickCancel} className="bg-red mb-3">
              Batal
            </button>
          )}
        </div>
        <div
          className="col-xs-12 col-xl-8 border-left"
          style={{ overflow: "auto" }}
        >
          <TableForm
            headers={[
              { title: "No.", alignValue: "center" },
              { title: "Username", value: "userName", alignValue: "center" },
              { title: "Role", value: "displayRole", alignValue: "center" },
              {
                title: "Penanggung Jawab",
                value: "inCharge",
                alignValue: "center",
              },
            ]}
            data={accountData}
            isEdit={isEdit || isEditPassword}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            showEditPassword
            showDetailButton
            onClickEditPassword={onClickEditPassword}
            isLoading={isLoadingUsers}
            onClickDetail={onClickDetail}
            emptyState={
              !selectedRole
                ? "Pilih Tipe User"
                : selectedRole?.value === "admin" && !selectedInCharge
                ? "Pilih Penanggung Jawab"
                : null
            }
          />
        </div>
      </div>

      <Modal
        fade={false}
        className="modal-md"
        isOpen={showDetail}
        toggle={() => setShowDetail(false)}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Detail Akun
          </h5>
          <button
            onClick={() => setShowDetail(false)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <table>
            <tbody>
              <tr>
                <td>Nama</td>
                <td>: {accountDetail?.name || "-"}</td>
              </tr>
              <tr>
                <td>NIK </td>
                <td>: {accountDetail?.nik || "-"}</td>
              </tr>
              <tr>
                <td>NIP </td>
                <td>: {accountDetail?.nip || "-"}</td>
              </tr>
              <tr>
                <td>Kontak </td>
                <td>: {accountDetail?.contact || "-"}</td>
              </tr>
              <tr>
                <td>Email </td>
                <td>: {accountDetail?.email || "-"}</td>
              </tr>
              {accountDetail.role === "admin" && (
                <tr>
                  <td>Email Atasan </td>
                  <td>: {accountDetail?.emailLeader || "-"}</td>
                </tr>
              )}
              <tr>
                <td>Username </td>
                <td>: {accountDetail?.userName || "-"}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default Account;
