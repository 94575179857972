import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input } from "reactstrap";
import { TableForm } from "../../components";
import { color, customColorSelect } from "../../helpers/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  getPilarsRequest,
  createStrategyRequest,
  updateStrategyRequest,
  deleteStrategyRequest,
} from "../../store/actions";
import Swal from "sweetalert2";

const Strategy = () => {
  /* HOOKS */
  const dispatch = useDispatch();

  /* LOCAL STATE */
  const [isEdit, setIsEdit] = useState(null);
  const [strategyName, setStrategyName] = useState("");
  const [selectedPilar, setSelectedPilar] = useState(null);
  const [pilarOptions, setPilarOptions] = useState();
  const [strategyData, setStrategyData] = useState([]);

  /* GLOBAL_STATE */
  const { pilars, isLoading } = useSelector((state) => state.MasterData);

  /* FUNCTIONS */
  const onChangeName = ({ target }) => {
    const { value } = target;
    setStrategyName(value);
  };

  const onChangeSelect = (value, { action }) => {
    setSelectedPilar(value);

    if (action === "clear") {
      setStrategyName("");
    }
  };

  const onClickEdit = (strategi) => {
    setIsEdit(strategi._id);
    setStrategyName(strategi.name);
  };

  const onClickDelete = (_id) => {
    dispatch(
      deleteStrategyRequest(_id, (err, _) => {
        if (err) {
          Swal.fire({
            title: "Oops.",
            text: `${
              err === 409
                ? "Tidak dapat menghapus strategi yang telah memiliki program."
                : "Terjadi kesalahan, coba lagi nanti!"
            }`,
            icon: "error",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 10000,
          });
        } else {
          Swal.fire({
            title: "Berhasil.",
            text: "Strategi berhasil dihapus.",
            icon: "success",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 5000,
          });
        }
      })
    );
  };

  const onClickSubmit = () => {
    if (isEdit) {
      dispatch(
        updateStrategyRequest(isEdit, { name: strategyName }, (err, _) => {
          if (err) {
            // alert
          } else {
            onClearForm();
          }
        })
      );
    } else {
      const strategyData = {
        pilar: selectedPilar.value,
        name: strategyName,
      };
      dispatch(
        createStrategyRequest(strategyData, (err, _) => {
          if (err) {
            // alert
          } else {
            onClearForm();
          }
        })
      );
    }
  };

  const onCLickCancel = () => {
    onClearForm();
  };

  const onClearForm = () => {
    setIsEdit(null);
    setStrategyName("");
  };

  /* LIFECYCLE */
  useEffect(() => {
    dispatch(getPilarsRequest());
  }, []); // eslint-disable-line

  useEffect(() => {
    const newPilarOptions = pilars.map(
      ({ _id, pilarNumber, name, strategies }) => ({
        value: _id,
        label: `PILAR ${pilarNumber}: ${name}`,
        strategies,
      })
    );
    setPilarOptions(newPilarOptions);

    if (selectedPilar) {
      const pilarId = selectedPilar.value;
      setSelectedPilar(newPilarOptions.find((obj) => obj.value === pilarId));
    }
  }, [pilars]); // eslint-disable-line

  useEffect(() => {
    if (selectedPilar) {
      const { strategies } = selectedPilar;
      setStrategyData(strategies.map(({ _id, name }) => ({ _id, name })));
    } else {
      setStrategyData([]);
    }
  }, [selectedPilar]);

  return (
    <>
      <p className="page-title mb-4">Tambah Strategi</p>
      <div className="row">
        <div className="col-xs-12 col-xl-5 border-right form">
          <Select
            name="pilar"
            className="mb-3"
            placeholder="Pilih Pilar"
            isDisabled={isEdit}
            value={selectedPilar}
            options={pilarOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
            isLoading={isLoading}
          />
          <Input
            className="mb-3"
            type="textarea"
            placeholder="Nama Strategi"
            onChange={onChangeName}
            value={strategyName}
            disabled={!selectedPilar}
            style={{
              backgroundColor: !selectedPilar ? "#f2f2f2" : "",
              minHeight: 50,
              padding: 14,
              height: 150,
            }}
          />
          <button
            onClick={onClickSubmit}
            type="button"
            // className="bg-green mb-3"
            className={`${
              isLoading || !selectedPilar || !strategyName.length
                ? "bg-grey"
                : "bg-green"
            } mb-3`}
            disabled={isLoading || !selectedPilar || !strategyName.length}
          >
            {isEdit ? "Simpan Perubahan" : "Tambah Strategi"}
          </button>
          {isEdit && (
            <button onClick={onCLickCancel} className="bg-red mb-3">
              Batal
            </button>
          )}
        </div>
        <div className="col-xs-12 col-xl-7 border-left">
          <TableForm
            headers={[
              { title: "No.", value: "pilar", alignValue: "center" },
              { title: "Nama Strategi", value: "name" },
            ]}
            data={strategyData}
            isEdit={isEdit}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            emptyState={`${
              selectedPilar
                ? "Strategi belum tersedia, silakan tambah strategi!"
                : "Pilih pilar untuk melihat data strategi"
            }`}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default Strategy;
