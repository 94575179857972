import {
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_SUCCESS,
  GET_LOCATIONS_FAILURE,
} from "./actionTypes";

export const getLocationsRequest = (filter) => ({
  type: GET_LOCATIONS_REQUEST,
  payload: { filter },
});

export const getLocationsSuccess = (response) => ({
  type: GET_LOCATIONS_SUCCESS,
  payload: response,
});

export const getLocationsFailure = (error) => ({
  type: GET_LOCATIONS_FAILURE,
  payload: error,
});
