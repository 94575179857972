import { useEffect, useState } from "react";

const Status = () => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    window.addEventListener("online", () => setStatus("online"));
    window.addEventListener("offline", () => setStatus("offline"));

    return () => {
      window.removeEventListener("offline", () => {
        setStatus("offline");
      });
      window.removeEventListener("online", () => {
        setStatus("online");
      });
    };
  }, []);

  return status;
};

export default Status;
