// import axios from "axios";
import axios from "../api";
import { baseUrl } from "../../helpers/config";
import store from "../index";

export const getNotificationsService = async () => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/notification`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};
