import { takeEvery, put, all, call } from "redux-saga/effects";

import {
  GET_DETAIL_REQUEST,
  GET_PREVIEW_DETAIL_REQUEST,
  CREATE_ACTIVITY_STATUS_REQUEST,
  UPDATE_ACTIVITY_STATUS_REQUEST,
  DELETE_ACTIVITY_STATUS_REQUEST,
  GET_REPORT_REQUEST,
  TOGGLE_YEAR_REQUEST,
} from "./actionTypes";

import {
  getDetailSuccess,
  getDetailFailure,
  getPreviewDetailSuccess,
  getPreviewDetailFailure,
  createActivityStatusSuccess,
  createActivityStatusFailure,
  updateActivityStatusSuccess,
  updateActivityStatusFailure,
  deleteActivityStatusSuccess,
  deleteActivityStatusFailure,
  getReportSuccess,
  getReportFailure,
  toggleYearSuccess,
  toggleYearFailure,
} from "./actions";

import {
  getDetailService,
  getPreviewDetailService,
  createActivityStatusService,
  updateActivityStatusService,
  deleteActivityStatusService,
  getReportService,
  toggleYearService,
} from "./services";

function* getDetailSaga({ payload: { queryString, callback } }) {
  try {
    // const { page, start, end, unit } = queryString;
    // const response = yield call(getDetailService, page, start, end, unit);
    const response = yield call(getDetailService, queryString);
    yield put(getDetailSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(getDetailFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* getPreviewDetailSaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(getPreviewDetailService, _id);
    yield put(getPreviewDetailSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(getPreviewDetailFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* createActivityStatusSaga({
  payload: { _id, activityStatusData, callback },
}) {
  try {
    const response = yield call(
      createActivityStatusService,
      _id,
      activityStatusData
    );
    yield put(createActivityStatusSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(createActivityStatusFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* updateActivityStatusSaga({
  payload: { _id, activityStatusData, callback },
}) {
  try {
    const response = yield call(
      updateActivityStatusService,
      _id,
      activityStatusData
    );
    yield put(updateActivityStatusSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updateActivityStatusFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* deleteActivityStatusSaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(deleteActivityStatusService, _id);
    yield put(deleteActivityStatusSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(deleteActivityStatusFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* getReportSaga({ payload: { queryString } }) {
  try {
    const response = yield call(getReportService, queryString);
    yield put(getReportSuccess(response));
  } catch (error) {
    yield put(getReportFailure(error));
  }
}

function* toggleYearSaga({ payload: { _id, isLock, callback}}) {
  try {
    const response = yield call(toggleYearService, _id, isLock)
    yield put(toggleYearSuccess(response))
    if (typeof callback === "function") {
      callback(null, response)
    }
  } catch (error) {
    yield put(toggleYearFailure(error))
    if (typeof callback === "function") {
      callback(error)
    }
  }
}

export default function* authSaga() {
  yield all([
    takeEvery(GET_DETAIL_REQUEST, getDetailSaga),
    takeEvery(GET_PREVIEW_DETAIL_REQUEST, getPreviewDetailSaga),
    takeEvery(CREATE_ACTIVITY_STATUS_REQUEST, createActivityStatusSaga),
    takeEvery(UPDATE_ACTIVITY_STATUS_REQUEST, updateActivityStatusSaga),
    takeEvery(DELETE_ACTIVITY_STATUS_REQUEST, deleteActivityStatusSaga),
    takeEvery(GET_REPORT_REQUEST, getReportSaga),
    takeEvery(TOGGLE_YEAR_REQUEST, toggleYearSaga),
  ]);
}
