import { takeEvery, put, all, call } from "redux-saga/effects";

import {
  GET_INSTITUTIONS_REQUEST,
  CREATE_INSTITUTION_REQUEST,
  UPDATE_INSTITUTION_REQUEST,
  DELETE_INSTITUTION_REQUEST,
} from "./actionTypes";

import {
  getInstitutionsSuccess,
  getInstitutionsFailure,
  createInstitutionSuccess,
  createInstitutionFailure,
  updateInstitutionSuccess,
  updateInstitutionFailure,
  deleteInstitutionSuccess,
  deleteInstitutionFailure,
} from "./actions";

import {
  getInstitutionsService,
  createInstitutionService,
  updateInstitutionService,
  deleteInstitutionService,
} from "./services";

function* getInstitutionsSaga({ payload: { callback } }) {
  try {
    const response = yield call(getInstitutionsService);
    yield put(getInstitutionsSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(getInstitutionsFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* createInstitutionsSaga({ payload: { institutionData, callback } }) {
  try {
    const response = yield call(createInstitutionService, institutionData);
    yield put(createInstitutionSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(createInstitutionFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* updateInstitutionsSaga({
  payload: { _id, institutionData, callback },
}) {
  try {
    const response = yield call(updateInstitutionService, _id, institutionData);
    yield put(updateInstitutionSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updateInstitutionFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* deleteInstitutionsSaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(deleteInstitutionService, _id);
    yield put(deleteInstitutionSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(deleteInstitutionFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

export default function* institutionSaga() {
  yield all([
    takeEvery(GET_INSTITUTIONS_REQUEST, getInstitutionsSaga),
    takeEvery(CREATE_INSTITUTION_REQUEST, createInstitutionsSaga),
    takeEvery(UPDATE_INSTITUTION_REQUEST, updateInstitutionsSaga),
    takeEvery(DELETE_INSTITUTION_REQUEST, deleteInstitutionsSaga),
  ]);
}
