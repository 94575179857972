export const GET_INSTITUTIONS_REQUEST = "GET_INSTITUTIONS_REQUEST";
export const GET_INSTITUTIONS_SUCCESS = "GET_INSTITUTIONS_SUCCESS";
export const GET_INSTITUTIONS_FAILURE = "GET_INSTITUTIONS_FAILURE";
export const CREATE_INSTITUTION_REQUEST = "CREATE_INSTITUTION_REQUEST";
export const CREATE_INSTITUTION_SUCCESS = "CREATE_INSTITUTION_SUCCESS";
export const CREATE_INSTITUTION_FAILURE = "CREATE_INSTITUTION_FAILURE";
export const UPDATE_INSTITUTION_REQUEST = "UPDATE_INSTITUTION_REQUEST";
export const UPDATE_INSTITUTION_SUCCESS = "UPDATE_INSTITUTION_SUCCESS";
export const UPDATE_INSTITUTION_FAILURE = "UPDATE_INSTITUTION_FAILURE";
export const DELETE_INSTITUTION_REQUEST = "DELETE_INSTITUTION_REQUEST";
export const DELETE_INSTITUTION_SUCCESS = "DELETE_INSTITUTION_SUCCESS";
export const DELETE_INSTITUTION_FAILURE = "DELETE_INSTITUTION_FAILURE";
