import {
  GET_OVERVIEW_REQUEST,
  GET_OVERVIEW_SUCCESS,
  GET_OVERVIEW_FAILURE,
} from "./actionTypes";

const initialState = {
  overview: {},
  isLoadingOverview: false,
  errorOverview: null,
};

const Overview = (state = initialState, action) => {
  switch (action.type) {
    case GET_OVERVIEW_REQUEST:
      return {
        ...state,
        isLoadingOverview: true,
        errorOverview: null,
      };
    case GET_OVERVIEW_SUCCESS:
      return {
        ...state,
        isLoadingOverview: false,
        overview: action.payload,
      };
    case GET_OVERVIEW_FAILURE:
      return {
        ...state,
        isLoadingOverview: false,
        errorOverview: action.payload,
      };
    default:
      return state;
  }
};

export default Overview;
