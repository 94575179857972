import React, { useState, useEffect } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsRequest } from "../store/actions";
import profileBptj from "../assets/images/profileBptj.png";
import { BlockSpinner } from "../components";
import moment from "moment";
import "moment/locale/id";
moment.locale("id");

const NotificationDropdown = () => {
  const dispatch = useDispatch();
  const { notifications, isLoading } = useSelector(
    (state) => state.Notification
  );
  const [menu, setMenu] = useState(false);
  const toggle = () => setMenu(!menu);

  useEffect(() => {
    dispatch(getNotificationsRequest());
  }, []); // eslint-disable-line

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon waves-effect"
          id="page-header-notifications-dropdown"
          tag="button"
        >
          <i className="mdi mdi-bell-outline"></i>
          {Boolean(notifications.length) && <div
            style={{
              height: 12,
              width: 12,
              borderRadius: 6,
              backgroundColor: "red",
              position: "absolute",
              top: 23,
              right: 13,
              border: "solid 1.5px white"
            }}
          />}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg p-0" right>
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h5
                  className="m-0 font-size-14 text-center text-bold"
                  style={{ color: "#555bae" }}
                >
                  {" "}
                  Pemberitahuan (
                  {notifications.length > 100
                    ? notifications.length + "+"
                    : notifications.length}
                  ){" "}
                </h5>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ maxHeight: "50vh" }}>
            {notifications.slice(0, 100).map((notif, notifIndex) => (
              <div className="text-reset notification-item" key={notifIndex}>
                <div className="media" style={{ alignItems: "center" }}>
                  <div className="avatar-xs mr-3">
                    <span className="avatar-title bg-success rounded-circle font-size-16">
                      <img
                        src={profileBptj}
                        height={40}
                        width={40}
                        style={{ borderRadius: 20 }}
                        alt="LOGO"
                      />
                    </span>
                  </div>
                  <div className="media-body">
                    <div className="">
                      <p className="mb-1 font-size-12">{notif?.text}</p>
                      <p
                        className="mb-1"
                        style={{ fontSize: 10, color: "#555bae" }}
                      >
                        {moment(notif.createdAt).fromNow()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <BlockSpinner show={isLoading} />
          </SimpleBar>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
