import { takeEvery, put, all, call } from "redux-saga/effects";

import {
  GET_USERS_REQUEST,
  CREATE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  UPDATE_USER_PASSWORD_REQUEST,
} from "./actionTypes";

import {
  getUsersSuccess,
  getUsersFailure,
  createUserSuccess,
  createUserFailure,
  updateUserSuccess,
  updateUserFailure,
  deleteUserSuccess,
  deleteUserFailure,
  updateUserPasswordSuccess,
  updateUserPasswordFailure,
} from "./actions";

import {
  getUsersService,
  createUserService,
  updateUserService,
  deleteUserService,
  updateUserPasswordService,
} from "./services";

function* getUsersSaga({ payload: { callback } }) {
  try {
    const response = yield call(getUsersService);
    yield put(getUsersSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(getUsersFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* createUserSaga({ payload: { userData, callback } }) {
  try {
    const response = yield call(createUserService, userData);
    yield put(createUserSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(createUserFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* updateUserSaga({ payload: { _id, userData, callback } }) {
  try {
    const response = yield call(updateUserService, _id, userData);
    yield put(updateUserSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updateUserFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* deleteUserSaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(deleteUserService, _id);
    yield put(deleteUserSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(deleteUserFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* updateUserPasswordSaga({ payload: { _id, password, callback } }) {
  try {
    const response = yield call(updateUserPasswordService, _id, password);
    yield put(updateUserPasswordSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updateUserPasswordFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

export default function* userSaga() {
  yield all([
    takeEvery(GET_USERS_REQUEST, getUsersSaga),
    takeEvery(CREATE_USER_REQUEST, createUserSaga),
    takeEvery(UPDATE_USER_REQUEST, updateUserSaga),
    takeEvery(DELETE_USER_REQUEST, deleteUserSaga),
    takeEvery(UPDATE_USER_PASSWORD_REQUEST, updateUserPasswordSaga),
  ]);
}
