import {
  GET_IKU_REQUEST,
  GET_IKU_SUCCESS,
  GET_IKU_FAILURE,
  UPDATE_IKU_REQUEST,
  UPDATE_IKU_SUCCESS,
  UPDATE_IKU_FAILURE,
  GET_IKU_ACCUMULATION_REQUEST,
  GET_IKU_ACCUMULATION_SUCCESS,
  GET_IKU_ACCUMULATION_FAILURE,
} from "./actionTypes";

const initialState = {
  IKU: [],
  accumulation: 0,
  isLoadingIku: false,
  isLoadingAccumulation: false,
  errorIku: null,
  errorAccumulation: null,
};

const IKU = (state = initialState, action) => {
  switch (action.type) {
    case GET_IKU_REQUEST:
    case UPDATE_IKU_REQUEST:
      return {
        ...state,
        isLoadingIku: true,
        errorIku: null,
      };
    case GET_IKU_ACCUMULATION_REQUEST:
      return {
        ...state,
        isLoadingAccumulation: true,
        errorAccumulation: null,
      };

    case GET_IKU_SUCCESS:
      return {
        ...state,
        isLoadingIku: false,
        IKU: action.payload,
      };
    case UPDATE_IKU_SUCCESS:
      return {
        ...state,
        isLoadingIku: false,
        IKU: state.IKU.map((iku) =>
          iku.number === action.payload.number ? action.payload : iku
        ),
      };
    case GET_IKU_ACCUMULATION_SUCCESS:
      return {
        ...state,
        isLoadingAccumulation: false,
        accumulation: action.payload,
      };

    case GET_IKU_FAILURE:
    case UPDATE_IKU_FAILURE:
      return {
        ...state,
        isLoadingIku: false,
        errorIku: action.payload,
      };
    case GET_IKU_ACCUMULATION_FAILURE:
      return {
        ...state,
        isLoadingAccumulation: false,
        errorAccumulation: action.payload,
      };
    default:
      return state;
  }
};

export default IKU;
