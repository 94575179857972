import React, { useState } from "react";
import { GMapsCard } from "../../components";
import { color } from "../../helpers/colors";
import { Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { createHelpRequest } from "../../store/actions";
import YouTube from "react-youtube";
import Swal from "sweetalert2";

const Help = () => {
  const dispatch = useDispatch();
  const { isLoadingHelp } = useSelector((state) => state.Help);
  const { user } = useSelector((state) => state.Auth);
  const [isContainedHtmlTags, setIsContainedHtmlTags] = useState(false);
  const [helpData, setHelpData] = useState({
    name: "",
    subject: "",
    description: "",
  });

  const onChangeInput = ({ target }) => {
    const { name, value } = target;
    setHelpData((prev) => ({ ...prev, [name]: value }));
    if (name === "description") {
      setIsContainedHtmlTags(/<(.|\n)*?>/g.test(value));
    }
  };

  const onClickSubmit = () => {
    dispatch(
      createHelpRequest(helpData, (err, resp) => {
        if (err) {
          Swal.fire({
            title: "Oops.",
            text: "Terjadi kesalahan, coba lagi nanti!",
            icon: "error",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 10000,
          });
        } else {
          setHelpData({
            name: "",
            subject: "",
            description: "",
          });
          Swal.fire({
            title: "Berhasil.",
            text: "Laporan telah berhasil dikirim, anda akan dihubungi oleh admin melalui email akun ini jika laporan telah ditinjau.",
            icon: "success",
            position: "top-end",
            confirmButtonColor: color.primary,
          });
        }
      })
    );
  };

  return (
    <>
      <div className="row mr-4 ml-4">
        <div
          style={{
            position: "relative",
            width: "100%",
            paddingBottom: "33%",
          }}
        >
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <GMapsCard lat={-6.174403} lng={106.822217} zoom={15} />
          </div>
        </div>
      </div>
      <div className="row d-flex align-content-between mr-2 ml-2">
        <div className="col-xs-12 col-md-6 p-3">
          <div>
            <div className="w-100 mb-3">
              <p className="text-bold" style={{ fontSize: 18 }}>
                Informasi Kontak
              </p>
              <div className="d-flex">
                <div style={{ width: 20 }}>
                  <i
                    className="mdi mdi-map-marker mr-2"
                    style={{ color: color.primary, fontSize: 16 }}
                  />
                </div>
                <div>
                  <p className="text-bold m-0">Alamat</p>
                  <p>
                    Gedung Karya, Jl. Medan Merdeka Barat No.8, RT.2/RW.3,
                    Gambir, Kecamatan Gambir, Kota Jakarta Pusat, Daerah Khusus
                    Ibukota Jakarta 10110
                  </p>
                </div>
              </div>
              <div className="d-flex">
                <div style={{ width: 20 }}>
                  <i
                    className="mdi mdi-phone-in-talk mr-2"
                    style={{ color: color.primary, fontSize: 16 }}
                  />
                </div>
                <div>
                  <p className="text-bold m-0">Telepon</p>
                  <p>(021) 2279 1412</p>
                </div>
              </div>
            </div>
            <div className="w-100">
              <a
                href={`${
                  user?.role === "super-admin"
                    ? "https://api.spiritritj.com/files/1644480229461-ManualBook%20Superadmin.pdf"
                    : user?.role === "admin"
                    ? "https://api.spiritritj.com/files/1644480299324-ManualBook%20Admin.pdf"
                    : "https://api.spiritritj.com/files/1644479713001-ManualBook%20Supervisor.pdf"
                }`}
                target="_blank"
                rel="noopener noreferrer"
                download={true}
              >
                <button
                  className="btn"
                  style={{
                    width: "100%",
                    backgroundColor: color.danger,
                    color: "white",
                    height: 48,
                    fontWeight: "bold",
                  }}
                >
                  Download Manual Book
                </button>
              </a>
            </div>
          </div>
          <div className="mt-5 border">
            <YouTube
              videoId={`${
                user?.role === "super-admin"
                  ? "Lub63pKjYUM"
                  : user?.role === "admin"
                  ? "s89e3cuvRhk"
                  : "G2m-JFAfxs0"
              }`}
              opts={{
                width: "100%",
                playerVars: {
                  autoplay: 1,
                },
              }}
              onReady={(event) => event.target.pauseVideo()}
            />
          </div>
        </div>
        <div className="col-xs-12 col-md-6 p-3">
          <p>Atau kirim pesan anda melalui form berikut:</p>
          <p className="text-bold mb-2">Nama</p>
          <Input
            className="mb-3"
            type="text"
            placeholder="Nama"
            name="name"
            onChange={onChangeInput}
            value={helpData.name}
            style={{
              padding: 14,
              height: 48,
            }}
          />
          <p className="text-bold mb-2">Subjek</p>
          <Input
            className="mb-3"
            type="text"
            placeholder="Subjek"
            name="subject"
            onChange={onChangeInput}
            value={helpData.subject}
            style={{
              padding: 14,
              height: 48,
            }}
          />
          <p className={`text-bold mb-${isContainedHtmlTags ? "0" : "2"}`}>
            Pesan
          </p>
          {isContainedHtmlTags && (
            <small style={{ color: "red" }}>
              Pesan mangandung karakter yang tidak diperbolehkan (tag html)
            </small>
          )}
          <Input
            className="mb-4"
            type="textarea"
            placeholder="Ketik Pesan"
            name="description"
            onChange={onChangeInput}
            value={helpData.description}
            style={{
              minHeight: 50,
              padding: 14,
              height: 150,
            }}
          />
          <div className="w-100">
            <button
              className="btn"
              style={{
                width: "100%",
                backgroundColor: color.success,
                color: "white",
                height: 48,
                fontWeight: "bold",
              }}
              disabled={
                isContainedHtmlTags ||
                !helpData?.name ||
                !helpData?.subject ||
                !helpData?.description ||
                isLoadingHelp
              }
              onClick={onClickSubmit}
            >
              {isLoadingHelp ? <Spinner /> : "Kirim"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
