// import axios from "axios";
import axios from "../api";
import { baseUrl } from "../../helpers/config";
import store from "../index";

export const getIkuService = async () => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/iku`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const updateIkuService = async (ikuNumber, value) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "patch",
      url: `${baseUrl}/api/iku/${ikuNumber}`,
      headers: { Authorization },
      data: { value },
    });
    // return response.data?.data;
    return response.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const getIkuAccumulationService = async () => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/iku/accumulation`,
      headers: { Authorization },
    });
    return response.data?.total?.toFixed(1) * 1;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};
