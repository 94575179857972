import React, { useRef, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import LoginBackground from "../../assets/images/loginBG.png";
import Logo from "../../assets/images/logo-dark@2x.png";
import { userResetPasswordRequest } from "../../store/actions";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { color } from "../../helpers/colors";

const ResetPassword = () => {
  const { id, token } = useParams();
  const passwordConfirmRef = useRef(null);
  const submitRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    password: "",
    showPassword: false,
    passwordConfirm: "",
    showPasswordConfirm: false,
  });

  const onChangeInput = ({ target: { name, value } }) => {
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const onClickShowPassword = (name) => () => {
    setState((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const validatePassword = () => {
    if (state.password.length < 6) return false;
    if (state.password !== state.passwordConfirm) return false;
    return true;
  };

  const handleSubmit = () => {
    const userData = {
      id,
      token,
      password: state.password,
    };
    dispatch(
      userResetPasswordRequest(userData, (err, resp) => {
        if (err) {
          Swal.fire({
            title: "Oops.",
            text: "Terjadi kesalahan, silakan coba lagi!",
            icon: "error",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 5000,
          });
        } else {
          history.push("/login");
        }
      })
    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          background: `url(${LoginBackground}) no-repeat center center fixed`,
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          // overflow: "auto",
          justifyContent: "center",
        }}
      >
        <div
          className="form"
          style={{
            backgroundColor: "white",
            borderRadius: 12,
            width: 513,
            // position: "absolute",
            // right: "7%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px 36px 36px 48px",
            maxWidth: "86%",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ maxWidth: "40%", marginBottom: 60, marginTop: 20 }}
          />
          <p className="text-center m-0">Reset Password</p>

          <Form style={{ width: "100%" }}>
            <FormGroup style={{ marginTop: 36 }}>
              <Label style={{ fontWeight: "bold" }} for="password">
                Password Baru
              </Label>
              <div style={{ position: "relative" }}>
                <Input
                  id="password"
                  name="password"
                  placeholder="Minimal 6 karakter"
                  type={state.showPassword ? "text" : "password"}
                  style={{
                    backgroundColor: "#f9f9f9",
                    height: 48,
                    paddingRight: 40,
                  }}
                  onKeyDown={handleEnter}
                  value={state.password}
                  onChange={onChangeInput}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 5,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: 10,
                    cursor: "pointer",
                  }}
                  onClick={onClickShowPassword("showPassword")}
                >
                  {state.showPassword ? (
                    <i className="mdi mdi-eye-off" />
                  ) : (
                    <i className="mdi mdi-eye" />
                  )}
                </div>
              </div>
            </FormGroup>
            <FormGroup style={{ marginTop: 36 }}>
              <Label style={{ fontWeight: "bold" }} for="passwordConfirm">
                Konfirmasi Password Baru
              </Label>
              <div style={{ position: "relative" }}>
                <Input
                  ref={passwordConfirmRef}
                  id="passwordConfirm"
                  name="passwordConfirm"
                  placeholder="Minimal 6 karakter"
                  type={state.showPasswordConfirm ? "text" : "password"}
                  style={{
                    backgroundColor: "#f9f9f9",
                    height: 48,
                    paddingRight: 40,
                  }}
                  onKeyDown={({ key }) => {
                    if (key === "Enter") {
                      submitRef.current.click();
                    }
                  }}
                  value={state.passwordConfirm}
                  onChange={onChangeInput}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 5,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: 10,
                    cursor: "pointer",
                  }}
                  onClick={onClickShowPassword("showPasswordConfirm")}
                >
                  {state.showPasswordConfirm ? (
                    <i className="mdi mdi-eye-off" />
                  ) : (
                    <i className="mdi mdi-eye" />
                  )}
                </div>
              </div>
            </FormGroup>
          </Form>
          <button
            ref={submitRef}
            onClick={handleSubmit}
            className={`${!validatePassword() ? "bg-grey" : "bg-darkblue"}`}
            style={{ marginTop: 36, minHeight: 54 }}
            disabled={!validatePassword()}
          >
            Reset Password
          </button>
          <div className="w-100" style={{ marginTop: 13 }}>
              <Link to="/login" style={{ color: "#242393" }}>
                Kembali ke Halaman Login
              </Link>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ResetPassword;
