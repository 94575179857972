export const GET_PILARS_REQUEST = "GET_PILARS_REQUEST";
export const GET_PILARS_SUCCESS = "GET_PILARS_SUCCESS";
export const GET_PILARS_FAILURE = "GET_PILARS_FAILURE";
export const CREATE_PILAR_REQUEST = "CREATE_PILAR_REQUEST";
export const CREATE_PILAR_SUCCESS = "CREATE_PILAR_SUCCESS";
export const CREATE_PILAR_FAILURE = "CREATE_PILAR_FAILURE";
export const UPDATE_PILAR_REQUEST = "UPDATE_PILAR_REQUEST";
export const UPDATE_PILAR_SUCCESS = "UPDATE_PILAR_SUCCESS";
export const UPDATE_PILAR_FAILURE = "UPDATE_PILAR_FAILURE";
export const DELETE_PILAR_REQUEST = "DELETE_PILAR_REQUEST";
export const DELETE_PILAR_SUCCESS = "DELETE_PILAR_SUCCESS";
export const DELETE_PILAR_FAILURE = "DELETE_PILAR_FAILURE";
export const CREATE_STRATEGY_REQUEST = "CREATE_STRATEGY_REQUEST";
export const CREATE_STRATEGY_SUCCESS = "CREATE_STRATEGY_SUCCESS";
export const CREATE_STRATEGY_FAILURE = "CREATE_STRATEGY_FAILURE";
export const UPDATE_STRATEGY_REQUEST = "UPDATE_STRATEGY_REQUEST";
export const UPDATE_STRATEGY_SUCCESS = "UPDATE_STRATEGY_SUCCESS";
export const UPDATE_STRATEGY_FAILURE = "UPDATE_STRATEGY_FAILURE";
export const DELETE_STRATEGY_REQUEST = "DELETE_STRATEGY_REQUEST";
export const DELETE_STRATEGY_SUCCESS = "DELETE_STRATEGY_SUCCESS";
export const DELETE_STRATEGY_FAILURE = "DELETE_STRATEGY_FAILURE";
export const CREATE_PROGRAM_REQUEST = "CREATE_PROGRAM_REQUEST";
export const CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS";
export const CREATE_PROGRAM_FAILURE = "CREATE_PROGRAM_FAILURE";
export const UPDATE_PROGRAM_REQUEST = "UPDATE_PROGRAM_REQUEST";
export const UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS";
export const UPDATE_PROGRAM_FAILURE = "UPDATE_PROGRAM_FAILURE";
export const DELETE_PROGRAM_REQUEST = "DELETE_PROGRAM_REQUEST";
export const DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS";
export const DELETE_PROGRAM_FAILURE = "DELETE_PROGRAM_FAILURE";
export const CREATE_ACTIVITY_REQUEST = "CREATE_ACTIVITY_REQUEST";
export const CREATE_ACTIVITY_SUCCESS = "CREATE_ACTIVITY_SUCCESS";
export const CREATE_ACTIVITY_FAILURE = "CREATE_ACTIVITY_FAILURE";
export const UPDATE_ACTIVITY_REQUEST = "UPDATE_ACTIVITY_REQUEST";
export const UPDATE_ACTIVITY_SUCCESS = "UPDATE_ACTIVITY_SUCCESS";
export const UPDATE_ACTIVITY_FAILURE = "UPDATE_ACTIVITY_FAILURE";
export const DELETE_ACTIVITY_REQUEST = "DELETE_ACTIVITY_REQUEST";
export const DELETE_ACTIVITY_SUCCESS = "DELETE_ACTIVITY_SUCCESS";
export const DELETE_ACTIVITY_FAILURE = "DELETE_ACTIVITY_FAILURE";
export const CREATE_ASSIGNMENT_REQUEST = "CREATE_ASSIGNMENT_REQUEST";
export const CREATE_ASSIGNMENT_SUCCESS = "CREATE_ASSIGNMENT_SUCCESS";
export const CREATE_ASSIGNMENT_FAILURE = "CREATE_ASSIGNMENT_FAILURE";
export const UPDATE_ASSIGNMENT_REQUEST = "UPDATE_ASSIGNMENT_REQUEST";
export const UPDATE_ASSIGNMENT_SUCCESS = "UPDATE_ASSIGNMENT_SUCCESS";
export const UPDATE_ASSIGNMENT_FAILURE = "UPDATE_ASSIGNMENT_FAILURE";
export const DELETE_ASSIGNMENT_REQUEST = "DELETE_ASSIGNMENT_REQUEST";
export const DELETE_ASSIGNMENT_SUCCESS = "DELETE_ASSIGNMENT_SUCCESS";
export const DELETE_ASSIGNMENT_FAILURE = "DELETE_ASSIGNMENT_FAILURE";
export const GET_ACTIVITIES_REQUEST = "GET_ACTIVITIES_REQUEST";
export const GET_ACTIVITIES_SUCCESS = "GET_ACTIVITIES_SUCCESS";
export const GET_ACTIVITIES_FAILURE = "GET_ACTIVITIES_FAILURE";
