// import axios from "axios";
import axios from "../api";
import { baseUrl } from "../../helpers/config";
import store from "../index";

export const userLoginService = async (userData) => {
  try {
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/user/login`,
      data: userData,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.status;
    }
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const userLogoutService = async () => {
  try {
    const refreshToken = store.getState()?.Auth?.user?.refreshToken;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/user/logout`,
      data: { refreshToken },
    });
    if (response.status === 200) {
      return response.data;
    } else {
      throw response.status;
    }
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const userForgetPasswordService = async (email) => {
  try {
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/user/reset-password`,
      data: { email },
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      throw response;
    }
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const userResetPasswordService = async (userData) => {
  try {
    const { id, token, password } = userData;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "patch",
      url: `${baseUrl}/api/user/new-password/${id}`,
      headers: { Authorization },
      data: { password },
    });
    if (response?.status === 200) {
      return response?.data;
    } else {
      throw response;
    }
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};
