import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import logoLight from "../../assets/images/logo-light@2x.png";
import logoSmall from "../../assets/images/logo-sm.png";
import NotificationDropdown from "../../components/NotificationDropdown";
import ProfileMenu from "../../components/ProfileMenu";
import { color } from "../../helpers/colors";
import { useOnlineStatus } from "../../hooks";
import Swal from "sweetalert2";

const TopBar = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [search, setSearch] = useState("");
  const onlineStatus = useOnlineStatus();

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const onChangeSearch = ({ target }) => {
    const { value } = target;
    setSearch(value);
  };

  const onKeyDown = ({ key }) => {
    if (key === "Enter") onSearch();
  };

  const onSearch = () => {
    if (pathname === "/detail") {
      history.push(`/detail${search ? "?search=" + search : ""}`);
    }
  };

  const toggleMenu = () => {
    props.toggleMenuCallback();
  };

  useEffect(() => {
    if (!search) onSearch();
  }, [search]); // eslint-disable-line

  useEffect(() => {
    // if (onlineStatus === "online") {
    //   Swal.fire({
    //     title: "Koneksi terhubung.",
    //     icon: "success",
    //     position: "top-end",
    //     confirmButtonColor: color.primary,
    //     timer: 5000,
    //   });
    // } else
    if (onlineStatus === "offline") {
      Swal.fire({
        title: "Koneksi terputus.",
        icon: "error",
        position: "top-end",
        confirmButtonColor: color.primary,
        timer: 5000,
      });
    }
  }, [onlineStatus]);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSmall} alt="" height="68" />
                </span>
                <span className="logo-lg">
                  <img src={logoLight} alt="" height="34" />
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSmall} alt="" height="44" />
                </span>
                <span className="logo-lg">
                  <img
                    src={logoLight}
                    alt=""
                    height="50"
                    style={{ marginTop: "40px" }}
                  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={toggleMenu}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>

            {pathname === "/detail" && (
              <>
                <div className="app-search d-none d-lg-block ml-2">
                  <div className="position-relative">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Cari..."
                      value={search}
                      onChange={onChangeSearch}
                      onKeyDown={onKeyDown}
                    />
                    <span
                      className="fa fa-search"
                      style={{ cursor: "pointer" }}
                      onClick={onSearch}
                    ></span>
                  </div>
                </div>

                <div className="dropdown d-inline-block d-lg-none ml-2">
                  <button
                    type="button"
                    className="btn header-item noti-icon waves-effect"
                    id="page-header-search-dropdown"
                    onClick={toggleSearch}
                  >
                    <i className="mdi mdi-magnify"></i>
                  </button>
                  <div
                    className={
                      isSearchOpen
                        ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                        : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                    }
                  >
                    <div className="p-3">
                      <div className="form-group m-0">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Cari..."
                            aria-label="Recipient's username"
                            value={search}
                            onChange={onChangeSearch}
                            onKeyDown={onKeyDown}
                          />
                          <div className="input-group-append">
                            <button
                              className="btn btn-primary"
                              onClick={onSearch}
                            >
                              <i className="mdi mdi-magnify"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="d-flex">
            {onlineStatus && (
              <i
                className={`mdi ${
                  onlineStatus === "online" ? "mdi-wifi" : "mdi-wifi-off"
                } mr-3`}
                style={{
                  alignSelf: "center",
                  fontSize: 24,
                  color:
                    onlineStatus === "online" ? color.primary : color.error,
                }}
              />
            )}
            <ProfileMenu />
            <NotificationDropdown />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default TopBar;
