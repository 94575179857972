import React from "react";
import { Spinner } from "reactstrap";
import { blueWheelColors, color } from "../../helpers/colors";
import { Popconfirm } from "antd";
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'

const TableForm = ({
  headers,
  data,
  onClickEdit,
  onClickDelete,
  isEdit,
  emptyState,
  showEditPassword,
  showDetailButton,
  onClickEditPassword,
  onClickDetail,
  isLoading,
}) => {
  return (
    <>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            background: "white",
            opacity: 0.5,
          }}
        >
          <Spinner />
        </div>
      )}
      <table
        className="table table-striped card-shadow"
        style={{
          borderCollapse: "collapse",
          borderRadius: "1em",
          overflow: "hidden",
        }}
      >
        <thead>
          <tr>
            {headers.map((header, headerIndex) => (
              <th
                className="text-center"
                key={headerIndex}
                style={{
                  backgroundColor:
                    headerIndex === 0 ? blueWheelColors[3] : blueWheelColors[2],
                  color: "#ffffff",
                }}
              >
                {header.title}
              </th>
            ))}
            <th
              style={{
                backgroundColor: blueWheelColors[2],
                color: "#ffffff",
              }}
            ></th>
          </tr>
        </thead>
        <tbody>
          {data.map((value, valueIndex) => (
            <tr
              key={valueIndex}
              style={{
                backgroundColor: isEdit === value._id ? color.primary : "",
                color: isEdit === value._id ? "white" : null,
              }}
            >
              {headers.map((header, HeaderIndex) => (
                <td align={header.alignValue} key={HeaderIndex}>
                  {header.title === "No."
                    ? `${valueIndex + 1}.`
                    : header.title === "Nama Pilar"
                    ? `PILAR ${value.pilar}: ${value[header.value]}`
                    : value[header.value]}
                </td>
              ))}
              <td>
                <div className="d-flex justify-content-end">
                  {showDetailButton && <button
                    disabled={isEdit || isLoading}
                    className={`${isEdit || isLoading ? "disabled" : "detail"}`}
                    onClick={() => onClickDetail?.(value)}
                  >
                    Detail
                  </button>}
                  <button
                    disabled={isEdit || isLoading}
                    className={`${
                      isEdit || isLoading ? "disabled" : "edit"
                    } ml-1`}
                    onClick={() => onClickEdit(value)}
                  >
                    Edit
                  </button>
                  {showEditPassword && (
                    <button
                      disabled={isEdit || isLoading}
                      className={`${
                        isEdit || isLoading ? "disabled" : "edit-pass"
                      } ml-1`}
                      onClick={() => onClickEditPassword?.(value)}
                    >
                      <p
                        style={{
                          fontSize: 8,
                          lineHeight: 1.25,
                          marginTop: 3.45,
                        }}
                      >
                        Edit
                        <br />
                        Password
                      </p>
                    </button>
                  )}
                  <Popconfirm
                    placement="left"
                    title={"Ingin menghapus data ini?"}
                    onConfirm={() => onClickDelete(value._id)}
                    okText="Hapus"
                    cancelText="Batal"
                    className={`${
                      isEdit || isLoading ? "disabled" : "delete"
                    } ml-1`}
                    disabled={isEdit || isLoading}
                    okButtonProps={{
                      danger: true,
                      icon: <i className="far fa-trash-alt mr-1" />,
                    }}
                  >
                    <button>Hapus</button>
                  </Popconfirm>
                </div>
              </td>
            </tr>
          ))}
          {!isLoading && !data.length && (
            <tr>
              <td colSpan={headers.length + 1} align="center">
                {emptyState || "Data kosong"}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default TableForm;
