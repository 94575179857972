// import axios from "axios";
import axios from "../api";
import { baseUrl } from "../../helpers/config";
import store from "../index";

export const getPilarsService = async () => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/pilar`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const createPilarService = async (pilarData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/pilar`,
      headers: { Authorization },
      data: pilarData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const updatePilarService = async (_id, pilarData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "put",
      url: `${baseUrl}/api/pilar/${_id}`,
      headers: { Authorization },
      data: pilarData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const deletePilarService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "delete",
      url: `${baseUrl}/api/pilar/${_id}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const createStrategyService = async (strategyData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/strategy`,
      headers: { Authorization },
      data: strategyData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const updateStrategyService = async (_id, strategyData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "put",
      url: `${baseUrl}/api/strategy/${_id}`,
      headers: { Authorization },
      data: strategyData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const deleteStrategyService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "delete",
      url: `${baseUrl}/api/strategy/${_id}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const createProgramService = async (programData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/program`,
      headers: { Authorization },
      data: programData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const updateProgramService = async (_id, programData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "put",
      url: `${baseUrl}/api/program/${_id}`,
      headers: { Authorization },
      data: programData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const deleteProgramService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "delete",
      url: `${baseUrl}/api/program/${_id}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const createActivityService = async (activityData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/activity`,
      headers: { Authorization },
      data: activityData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const updateActivityService = async (_id, activityData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "put",
      url: `${baseUrl}/api/activity/${_id}`,
      headers: { Authorization },
      data: activityData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const deleteActivityService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "delete",
      url: `${baseUrl}/api/activity/${_id}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const createAssignmentService = async (assignmentData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/activity/assign`,
      headers: { Authorization },
      data: assignmentData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const updateAssignmentService = async (_id, assignmentData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "put",
      url: `${baseUrl}/api/activity/detail/${_id}`,
      headers: { Authorization },
      data: assignmentData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const deleteAssignmentService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "delete",
      url: `${baseUrl}/api/activity/detail/${_id}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const getActivitiesService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/activity/${_id}`,
      headers: { Authorization },
    });
    if (response.data?.data?.activityDetail) {
      return response.data?.data?.activityDetail;
    } else {
      throw response
    }
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};
