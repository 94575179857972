export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";
export const USER_FORGET_PASSWORD_REQUEST = "USER_FORGET_PASSWORD_REQUEST";
export const USER_FORGET_PASSWORD_SUCCESS = "USER_FORGET_PASSWORD_SUCCESS";
export const USER_FORGET_PASSWORD_FAILURE = "USER_FORGET_PASSWORD_FAILURE";
export const USER_RESET_PASSWORD_REQUEST = "USER_RESET_PASSWORD_REQUEST";
export const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
export const USER_RESET_PASSWORD_FAILURE = "USER_RESET_PASSWORD_FAILURE";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";
