import {
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
} from "./actionTypes";

export const getNotificationsRequest = () => ({
  type: GET_NOTIFICATIONS_REQUEST,
});

export const getNotificationsSuccess = (response) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: response,
});

export const getNotificationsFailure = (error) => ({
  type: GET_NOTIFICATIONS_FAILURE,
  payload: error,
});
