import {
  GET_OVERVIEW_REQUEST,
  GET_OVERVIEW_SUCCESS,
  GET_OVERVIEW_FAILURE,
} from "./actionTypes";

export const getOverviewRequest = (
  queryString = { pilar: "all", incharge: "none", start: 2018, end: 2029 }
) => ({
  type: GET_OVERVIEW_REQUEST,
  payload: { queryString },
});

export const getOverviewSuccess = (response) => ({
  type: GET_OVERVIEW_SUCCESS,
  payload: response,
});

export const getOverviewFailure = (error) => ({
  type: GET_OVERVIEW_FAILURE,
  payload: error,
});
