import React, { useState, useEffect } from "react";
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getIkuRequest, updateIkuRequest } from "../../store/actions";
import { color } from "../../helpers/colors";

const IKU = () => {
  const dispatch = useDispatch();
  const { IKU, isLoading } = useSelector((state) => state.IKU);

  useEffect(() => {
    dispatch(getIkuRequest());
  }, []); // eslint-disable-line
  return (
    <>
      <p className="page-title">Input Indikator Kinerja Utama</p>
      <div className="row" style={{ position: "relative" }}>
        <div className="col-xl-6 col-lg-12">
          {IKU.slice(0, 4).map((data, idx) => (
            <IKUForm key={idx} data={data} />
          ))}
        </div>
        <div className="col-xl-6 col-lg-12">
          {IKU.slice(4).map((data, idx) => (
            <IKUForm key={idx + 5} data={data} />
          ))}
        </div>
        {isLoading && (
          <div
            style={{
              display: "flex",
              flex: 1,
              position: "absolute",
              right: 0,
              left: 0,
              top: 0,
              bottom: 0,
              backgroundColor: "white",
              opacity: 0.7,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner
              style={{
                height: 100,
                width: 100,
                color: color.primary,
                fontSize: 20,
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default IKU;

const IKUForm = ({ data }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(data?.inputValue || 0);

  const onChangeValue = ({ target }) => {
    const { value } = target;
    if (!isNaN(value)) setValue(value);
  };

  const onClickButton = ({ target }) => {
    const { name } = target;
    if (name === "edit") {
      setIsEdit(true);
      setValue(data?.inputValue || 0);
    } else if (name === "cancel") {
      setIsEdit(false);
    } else {
      dispatch(
        updateIkuRequest(data.number, value, (err, resp) => {
          if (err) {
            // alert error
          } else {
            setIsEdit(false);
          }
        })
      );
    }
  };

  return (
    <>
      <div className="card-shadow d-flex mb-3 p-3 pb-4 iku-form">
        <div className="d-flex pr-2">
          <p className="text-bold">{data.number}.</p>
        </div>
        <div className="d-flex-1 w-100">
          <p className="text-bold m-0">{data.name}</p>
          <p className="description">{data.description}</p>
          <div className="row">
            <div className="col-7 position-relative">
              <input
                className="w-100"
                disabled={!isEdit}
                style={{ backgroundColor: !isEdit ? "#f2f2f2" : "" }}
                defaultValue={data?.inputValue || 0}
                value={isEdit ? value : data?.inputValue || 0}
                onChange={onChangeValue}
              />
              <p className="position-absolute unit">
                {data.unit === "minutes" ? "min" : data.unit}
              </p>
            </div>
            <div className="col-5 row">
              <div className="col p-0">
                <button
                  name={isEdit ? "cancel" : "edit"}
                  onClick={onClickButton}
                  className={`w-100 ${isEdit ? "bg-red" : "bg-purple"}`}
                >
                  {isEdit ? "Batal" : "Edit"}
                </button>
              </div>
              <div className="col m-0">
                {isEdit && (
                  <button
                    name="submit"
                    onClick={onClickButton}
                    className="w-100 bg-green"
                  >
                    Simpan
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
