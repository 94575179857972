import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";

// BPTJ
import Auth from "./auth/reducer";
import MasterData from "./MasterData/reducer";
import upload from "./upload/reducer";
import Institution from "./Institution/recucer";
import User from "./User/recucer";
import Detail from "./Detail/reducer";
import IKU from "./IKU/reducer";
import Overview from "./Overview/reducer";
import Help from "./Help/reducer";
import Location from "./Location/reducer";
import Notification from "./Notification/reducer";

import { USER_LOGOUT_SUCCESS } from "./auth/actionTypes";

const appReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Auth,
  MasterData,
  upload,
  Institution,
  User,
  Detail,
  IKU,
  Overview,
  Help,
  Location,
  Notification,
});

const rootReducer = (state, action) => {
  if (action.type === USER_LOGOUT_SUCCESS) {
    storage.removeItem("persist:root");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
