import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_FORGET_PASSWORD_REQUEST,
  USER_FORGET_PASSWORD_SUCCESS,
  USER_FORGET_PASSWORD_FAILURE,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAILURE,
} from "./actionTypes";

const initialState = {
  user: null,
  error: null,
  isLoading: false,
};

const Auth = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
    case USER_LOGOUT_REQUEST:
    case USER_FORGET_PASSWORD_REQUEST:
    case USER_RESET_PASSWORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isLoading: false,
      };
    case USER_LOGOUT_SUCCESS:
    case USER_FORGET_PASSWORD_SUCCESS:
    case USER_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        user: null,
        isLoading: false,
      };

    case USER_LOGIN_FAILURE:
    case USER_FORGET_PASSWORD_FAILURE:
    case USER_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case "REFRESH_TOKEN":
      return {
        ...state,
        user: {
          ...state.user,
          token: action.payload,
        },
      };
    default:
      return state;
  }
};

export default Auth;
