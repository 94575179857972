import {
  GET_DETAIL_REQUEST,
  GET_DETAIL_SUCCESS,
  GET_DETAIL_FAILURE,
  GET_PREVIEW_DETAIL_REQUEST,
  GET_PREVIEW_DETAIL_SUCCESS,
  GET_PREVIEW_DETAIL_FAILURE,
  CREATE_ACTIVITY_STATUS_REQUEST,
  CREATE_ACTIVITY_STATUS_SUCCESS,
  CREATE_ACTIVITY_STATUS_FAILURE,
  UPDATE_ACTIVITY_STATUS_REQUEST,
  UPDATE_ACTIVITY_STATUS_SUCCESS,
  UPDATE_ACTIVITY_STATUS_FAILURE,
  DELETE_ACTIVITY_STATUS_REQUEST,
  DELETE_ACTIVITY_STATUS_SUCCESS,
  DELETE_ACTIVITY_STATUS_FAILURE,
  GET_REPORT_REQUEST,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAILURE,
  TOGGLE_YEAR_REQUEST,
  TOGGLE_YEAR_SUCCESS,
  TOGGLE_YEAR_FAILURE,
} from "./actionTypes";

export const getDetailRequest = (
  queryString = { search: "", page: 1, unit: null, start: 2018, end: 2029 }
) => ({
  type: GET_DETAIL_REQUEST,
  payload: { queryString },
});

export const getDetailSuccess = (response) => ({
  type: GET_DETAIL_SUCCESS,
  payload: response,
});

export const getDetailFailure = (error) => ({
  type: GET_DETAIL_FAILURE,
  payload: error,
});

export const getPreviewDetailRequest = (_id, callback) => ({
  type: GET_PREVIEW_DETAIL_REQUEST,
  payload: { _id, callback },
});

export const getPreviewDetailSuccess = (response) => ({
  type: GET_PREVIEW_DETAIL_SUCCESS,
  payload: response,
});

export const getPreviewDetailFailure = (error) => ({
  type: GET_PREVIEW_DETAIL_FAILURE,
  payload: error,
});

export const createActivityStatusRequest = (
  _id,
  activityStatusData,
  callback
) => ({
  type: CREATE_ACTIVITY_STATUS_REQUEST,
  payload: { _id, activityStatusData, callback },
});

export const createActivityStatusSuccess = (response) => ({
  type: CREATE_ACTIVITY_STATUS_SUCCESS,
  payload: response,
});

export const createActivityStatusFailure = (error) => ({
  type: CREATE_ACTIVITY_STATUS_FAILURE,
  payload: error,
});

export const updateActivityStatusRequest = (
  _id,
  activityStatusData,
  callback
) => ({
  type: UPDATE_ACTIVITY_STATUS_REQUEST,
  payload: { _id, activityStatusData, callback },
});

export const updateActivityStatusSuccess = (response) => ({
  type: UPDATE_ACTIVITY_STATUS_SUCCESS,
  payload: response,
});

export const updateActivityStatusFailure = (error) => ({
  type: UPDATE_ACTIVITY_STATUS_FAILURE,
  payload: error,
});

export const deleteActivityStatusRequest = (_id, callback) => ({
  type: DELETE_ACTIVITY_STATUS_REQUEST,
  payload: { _id, callback },
});

export const deleteActivityStatusSuccess = (response) => ({
  type: DELETE_ACTIVITY_STATUS_SUCCESS,
  payload: response,
});

export const deleteActivityStatusFailure = (error) => ({
  type: DELETE_ACTIVITY_STATUS_FAILURE,
  payload: error,
});

export const getReportRequest = (
  queryString = { page: "all", unit: "all", start: 2018, end: 2029 }
) => ({
  type: GET_REPORT_REQUEST,
  payload: { queryString },
});

export const getReportSuccess = (response) => ({
  type: GET_REPORT_SUCCESS,
  payload: response,
});

export const getReportFailure = (error) => ({
  type: GET_REPORT_FAILURE,
  payload: error,
});

export const toggleYearRequest = (_id, isLock, callback) => ({
  type: TOGGLE_YEAR_REQUEST,
  payload: { _id, isLock, callback },
});

export const toggleYearSuccess = (response) => ({
  type: TOGGLE_YEAR_SUCCESS,
  payload: response,
});

export const toggleYearFailure = (error) => ({
  type: TOGGLE_YEAR_FAILURE,
  payload: error,
});
