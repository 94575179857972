import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input } from "reactstrap";
import { TableForm } from "../../components";
import { customColorSelect, color } from "../../helpers/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  getPilarsRequest,
  createProgramRequest,
  updateProgramRequest,
  deleteProgramRequest,
} from "../../store/actions";
import Swal from "sweetalert2";

const Program = () => {
  /* HOOKS */
  const dispatch = useDispatch();

  /* LOCAL STATE */
  const [isEdit, setIsEdit] = useState(null);
  const [programName, setProgramName] = useState("");
  const [selectedPilar, setSelectedPilar] = useState(null);
  const [pilarOptions, setPilarOptions] = useState([]);
  const [selectedStrategy, setSelectedStrategy] = useState(null);
  const [strategyOptions, setStrategyOptions] = useState([]);
  const [programData, setProgramData] = useState([]);

  /* GLOBAL_STATE */
  const { pilars, isLoading } = useSelector((state) => state.MasterData);

  /* FUNCTIONS */
  const onChangeName = ({ target }) => {
    const { value } = target;
    setProgramName(value);
  };

  const onChangeSelect = (value, { name, action }) => {
    if (name === "pilar") {
      setSelectedPilar(value);
      setSelectedStrategy(null);
    } else if (name === "strategy") {
      setSelectedStrategy(value);
    }

    if (action === "clear") {
      setProgramName("");
    }
  };

  const onClickEdit = (program) => {
    setIsEdit(program._id);
    setProgramName(program.name);
  };

  const onClickDelete = (_id) => {
    dispatch(
      deleteProgramRequest(_id, (err, resp) => {
        if (err) {
          Swal.fire({
            title: "Oops.",
            text: `${
              err === 409
                ? "Tidak dapat menghapus program yang telah memiliki kegiatan."
                : "Terjadi kesalahan, coba lagi nanti!"
            }`,
            icon: "error",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 10000,
          });
        } else {
          Swal.fire({
            title: "Berhasil.",
            text: "Program berhasil dihapus.",
            icon: "success",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 5000,
          });
        }
      })
    );
  };

  const onClickSubmit = () => {
    if (isEdit) {
      dispatch(
        updateProgramRequest(isEdit, { name: programName }, (err, resp) => {
          if (err) {
            // alert
          } else {
            onClearForm();
          }
        })
      );
    } else {
      const programData = {
        pilar: selectedPilar.value,
        strategy: selectedStrategy.value,
        name: programName,
      };
      dispatch(
        createProgramRequest(programData, (err, resp) => {
          if (err) {
            // alert
          } else {
            onClearForm();
          }
        })
      );
    }
  };

  const onCLickCancel = () => {
    onClearForm();
  };

  const onClearForm = () => {
    setIsEdit(null);
    setProgramName("");
  };

  /* LIFECYCLE */
  useEffect(() => {
    dispatch(getPilarsRequest());
  }, []); // eslint-disable-line

  useEffect(() => {
    const newPilarOptions = pilars.map((pilar) => ({
      value: pilar._id,
      label: `PILAR ${pilar.pilarNumber} ${pilar.name}`,
      strategies: pilar.strategies,
    }));
    setPilarOptions(newPilarOptions);

    if (selectedPilar) {
      const pilarId = selectedPilar.value;
      const newSelectedPilar = newPilarOptions.find(
        (pilarOption) => pilarOption.value === pilarId
      );
      setSelectedPilar(newSelectedPilar);
    }
  }, [pilars]); // eslint-disable-line

  useEffect(() => {
    if (selectedPilar) {
      const newStrategyOptions = selectedPilar.strategies.map((strategy) => ({
        value: strategy._id,
        label: strategy.name,
        programs: strategy.programs,
      }));
      setStrategyOptions(newStrategyOptions);

      if (selectedStrategy) {
        const strategyId = selectedStrategy.value;
        const newSelectedStrategy = newStrategyOptions.find(
          (strategyOption) => strategyOption.value === strategyId
        );
        setSelectedStrategy(newSelectedStrategy);
      }
    } else {
      setStrategyOptions([]);
    }
  }, [selectedPilar]); // eslint-disable-line

  useEffect(() => {
    if (selectedStrategy) {
      const newProgramData = selectedStrategy.programs.map(({ _id, name }) => ({
        _id,
        name,
      }));
      setProgramData(newProgramData);
    } else {
      setProgramData([]);
    }
  }, [selectedStrategy]);

  return (
    <>
      <p className="page-title mb-4">Tambah Program</p>
      <div className="row">
        <div className="col-xs-12 col-xl-5 border-right form">
          <Select
            name="pilar"
            className="mb-3"
            placeholder="Pilih Pilar"
            isDisabled={isEdit}
            value={selectedPilar}
            options={pilarOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
          />
          <Select
            name="strategy"
            className="mb-3"
            placeholder="Pilih Strategi"
            isDisabled={isEdit || !selectedPilar}
            value={selectedStrategy}
            options={strategyOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
          />
          <Input
            className="mb-3"
            type="textarea"
            placeholder="Nama Program"
            onChange={onChangeName}
            value={programName}
            disabled={!selectedStrategy}
            style={{
              backgroundColor: !selectedStrategy ? "#f2f2f2" : "",
              minHeight: 50,
              padding: 14,
              height: 150,
            }}
          />
          <button
            onClick={onClickSubmit}
            type="button"
            className={`${
              isLoading || !selectedStrategy || !programName.length
                ? "bg-grey"
                : "bg-green"
            } mb-3`}
            disabled={isLoading || !selectedStrategy || !programName.length}
          >
            {isEdit ? "Simpan Perubahan" : "Tambah Program"}
          </button>
          {isEdit && (
            <button onClick={onCLickCancel} className="bg-red mb-3">
              Batal
            </button>
          )}
        </div>
        <div className="col-xs-12 col-xl-7 border-left">
          <TableForm
            headers={[
              { title: "No.", value: "pilar", alignValue: "center" },
              { title: "Nama Program", value: "name" },
            ]}
            data={programData}
            isEdit={isEdit}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            emptyState={`${
              selectedStrategy
                ? "Program belum tersedia, silakan tambah program!"
                : "Pilih pilar dan strategi untuk melihat data program"
            }`}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default Program;
