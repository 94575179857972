import { takeEvery, put, all, call } from "redux-saga/effects";

import {
  GET_PILARS_REQUEST,
  CREATE_PILAR_REQUEST,
  UPDATE_PILAR_REQUEST,
  DELETE_PILAR_REQUEST,
  CREATE_STRATEGY_REQUEST,
  UPDATE_STRATEGY_REQUEST,
  DELETE_STRATEGY_REQUEST,
  CREATE_PROGRAM_REQUEST,
  UPDATE_PROGRAM_REQUEST,
  DELETE_PROGRAM_REQUEST,
  CREATE_ACTIVITY_REQUEST,
  UPDATE_ACTIVITY_REQUEST,
  DELETE_ACTIVITY_REQUEST,
  CREATE_ASSIGNMENT_REQUEST,
  UPDATE_ASSIGNMENT_REQUEST,
  DELETE_ASSIGNMENT_REQUEST,
  GET_ACTIVITIES_REQUEST,
} from "./actionTypes";

import {
  getPilarsSuccess,
  getPilarsFailure,
  createPilarSuccess,
  createPilarFailure,
  updatePilarSuccess,
  updatePilarFailure,
  deletePilarSuccess,
  deletePilarFailure,
  createStrategySuccess,
  createStrategyFailure,
  updateStrategySuccess,
  updateStrategyFailure,
  deleteStrategySuccess,
  deleteStrategyFailure,
  createProgramSuccess,
  createProgramFailure,
  updateProgramSuccess,
  updateProgramFailure,
  deleteProgramSuccess,
  deleteProgramFailure,
  createActivitySuccess,
  createActivityFailure,
  updateActivitySuccess,
  updateActivityFailure,
  deleteActivitySuccess,
  deleteActivityFailure,
  createAssignmentSuccess,
  createAssignmentFailure,
  updateAssignmentSuccess,
  updateAssignmentFailure,
  deleteAssignmentSuccess,
  deleteAssignmentFailure,
  getActivitiesSuccess,
  getActivitiesFailure,
} from "./actions";

import {
  getPilarsService,
  createPilarService,
  updatePilarService,
  deletePilarService,
  createStrategyService,
  updateStrategyService,
  deleteStrategyService,
  createProgramService,
  updateProgramService,
  deleteProgramService,
  createActivityService,
  updateActivityService,
  deleteActivityService,
  createAssignmentService,
  updateAssignmentService,
  deleteAssignmentService,
  getActivitiesService,
} from "./services";

function* getPilarsSaga() {
  try {
    const response = yield call(getPilarsService);
    yield put(getPilarsSuccess(response));
  } catch (error) {
    yield put(getPilarsFailure(error));
  }
}

function* createPilarSaga({ payload: { pilarData, callback } }) {
  try {
    const response = yield call(createPilarService, pilarData);
    yield put(createPilarSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(createPilarFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* updatePilarSaga({ payload: { _id, pilarData, callback } }) {
  try {
    const response = yield call(updatePilarService, _id, pilarData);
    yield put(updatePilarSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updatePilarFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* deletePilarSaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(deletePilarService, _id);
    yield put(deletePilarSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(deletePilarFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* createStrategySaga({ payload: { strategyData, callback } }) {
  try {
    const response = yield call(createStrategyService, strategyData);
    yield put(createStrategySuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(createStrategyFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* updateStrategySaga({ payload: { _id, strategyData, callback } }) {
  try {
    const response = yield call(updateStrategyService, _id, strategyData);
    yield put(updateStrategySuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updateStrategyFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* deleteStrategySaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(deleteStrategyService, _id);
    yield put(deleteStrategySuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(deleteStrategyFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* createProgramSaga({ payload: { programData, callback } }) {
  try {
    const response = yield call(createProgramService, programData);
    yield put(createProgramSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(createProgramFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* updateProgramSaga({ payload: { _id, programData, callback } }) {
  try {
    const response = yield call(updateProgramService, _id, programData);
    yield put(updateProgramSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updateProgramFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* deleteProgramSaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(deleteProgramService, _id);
    yield put(deleteProgramSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(deleteProgramFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* createActivitySaga({ payload: { activityData, callback } }) {
  try {
    const response = yield call(createActivityService, activityData);
    yield put(createActivitySuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(createActivityFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* updateActivitySaga({ payload: { _id, activityData, callback } }) {
  try {
    const response = yield call(updateActivityService, _id, activityData);
    yield put(updateActivitySuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updateActivityFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* deleteActivitySaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(deleteActivityService, _id);
    yield put(deleteActivitySuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(deleteActivityFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* createAssignmentSaga({ payload: { assignmentData, callback } }) {
  try {
    const response = yield call(createAssignmentService, assignmentData);
    yield put(createAssignmentSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(createAssignmentFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* updateAssignmentSaga({ payload: { _id, assignmentData, callback } }) {
  try {
    const response = yield call(updateAssignmentService, _id, assignmentData);
    yield put(updateAssignmentSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updateAssignmentFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* deleteAssignmentSaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(deleteAssignmentService, _id);
    yield put(deleteAssignmentSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(deleteAssignmentFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* getActivitiesSaga({ payload: { _id, callback } }) {
  try {
    const response = yield call(getActivitiesService, _id);
    yield put(getActivitiesSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(getActivitiesFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

export default function* masterDataSaga() {
  yield all([
    takeEvery(GET_PILARS_REQUEST, getPilarsSaga),
    takeEvery(CREATE_PILAR_REQUEST, createPilarSaga),
    takeEvery(UPDATE_PILAR_REQUEST, updatePilarSaga),
    takeEvery(DELETE_PILAR_REQUEST, deletePilarSaga),
    takeEvery(CREATE_STRATEGY_REQUEST, createStrategySaga),
    takeEvery(UPDATE_STRATEGY_REQUEST, updateStrategySaga),
    takeEvery(DELETE_STRATEGY_REQUEST, deleteStrategySaga),
    takeEvery(CREATE_PROGRAM_REQUEST, createProgramSaga),
    takeEvery(UPDATE_PROGRAM_REQUEST, updateProgramSaga),
    takeEvery(DELETE_PROGRAM_REQUEST, deleteProgramSaga),
    takeEvery(CREATE_ACTIVITY_REQUEST, createActivitySaga),
    takeEvery(UPDATE_ACTIVITY_REQUEST, updateActivitySaga),
    takeEvery(DELETE_ACTIVITY_REQUEST, deleteActivitySaga),
    takeEvery(CREATE_ASSIGNMENT_REQUEST, createAssignmentSaga),
    takeEvery(UPDATE_ASSIGNMENT_REQUEST, updateAssignmentSaga),
    takeEvery(DELETE_ASSIGNMENT_REQUEST, deleteAssignmentSaga),
    takeEvery(GET_ACTIVITIES_REQUEST, getActivitiesSaga),
  ]);
}
