import { takeEvery, put, all, call } from "redux-saga/effects";
import { GET_NOTIFICATIONS_REQUEST } from "./actionTypes";
import { getNotificationsSuccess, getNotificationsFailure } from "./actions";
import { getNotificationsService } from "./services";

function* getNotificationsSaga() {
  try {
    const response = yield call(getNotificationsService);
    yield put(getNotificationsSuccess(response));
  } catch (error) {
    yield put(getNotificationsFailure(error));
  }
}

export default function* institutionSaga() {
  yield all([takeEvery(GET_NOTIFICATIONS_REQUEST, getNotificationsSaga)]);
}
