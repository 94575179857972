import React, { useRef, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import LoginBackground from "../../assets/images/loginBG.png";
import Logo from "../../assets/images/logo-dark@2x.png";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userLoginRequest } from "../../store/actions";
import { Spinner } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from "sweetalert2";
import { color } from "../../helpers/colors";

const Login = () => {
  /* HOOKS */
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const submitRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  /* LOCAL_STATE */
  const [userData, setUserData] = useState({
    userName: "",
    password: "",
  });
  const [isValidCaptcha, setIsValidCaptcha] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  /* GLOBAL_STATE */
  const { isLoading } = useSelector((state) => state.Auth);

  /* FUNCTIONS */
  const onChangeInput = ({ target: { name, value } }) => {
    setUserData({ ...userData, [name]: value });
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const handleLogin = () => {
    if (isValidCaptcha) {
    // if (true) {
      dispatch(
        userLoginRequest(userData, history, (err, resp) => {
          if (err) {
            Swal.fire({
              title: "Oops.",
              text: `${
                err === 404
                  ? "Username tidak ditemukan!"
                  : err === 400
                  ? "Password tidak sesuai!"
                  : "Terjadi kesalahan, coba lagi nanti!"
              }`,
              icon: "error",
              position: "top-end",
              confirmButtonColor: color.primary,
              timer: 5000,
            });
          }
        })
      );
    }
  };

  const onChangeCaptcha = (value) => {
    setIsValidCaptcha(value);
  };

  const invalidateForm = () => {
    return Boolean(
      isLoading ||
        !userData.userName.length ||
        !userData.password.length ||
        !isValidCaptcha
        // false
    );
  };

  return (
    <React.Fragment>
      <div
        style={{
          background: `url(${LoginBackground}) no-repeat center center fixed`,
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="form"
          style={{
            backgroundColor: "white",
            borderRadius: 12,
            width: 513,
            // position: "absolute",
            // right: "7%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px 36px 48px 36px",
            maxWidth: "86%",
            overflow: "auto",
            maxHeight: "100vh",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ maxWidth: "40%", marginBottom: 60, marginTop: 20 }}
          />
          {/* <p style={{ fontSize: 30, fontWeight: 600 }}>Login Account</p> */}
          <p className="text-center m-0">
            Gunakan Username dan Password anda untuk login. Website ini
            digunakan untuk spesifik user.
          </p>

          <Form style={{ width: "100%" }}>
            <FormGroup style={{ marginTop: 36 }}>
              <Label style={{ fontWeight: "bold" }} for="userName">
                Username
              </Label>
              <Input
                ref={usernameRef}
                id="userName"
                name="userName"
                placeholder="admin-instansi"
                type="text"
                style={{ backgroundColor: "#f9f9f9", height: 48 }}
                autoFocus
                onKeyDown={handleEnter}
                value={userData.userName}
                onChange={onChangeInput}
              />
            </FormGroup>
            <FormGroup style={{ marginTop: 36 }}>
              <Label style={{ fontWeight: "bold" }} for="password">
                Password
              </Label>
              <div style={{ position: "relative" }}>
                <Input
                  ref={passwordRef}
                  id="password"
                  name="password"
                  placeholder="Masukan Password"
                  type={showPassword ? "text" : "password"}
                  style={{
                    backgroundColor: "#f9f9f9",
                    height: 48,
                    paddingRight: 40,
                  }}
                  onKeyDown={({ key }) => {
                    if (key === "Enter") {
                      submitRef.current.click();
                    }
                  }}
                  value={userData.password}
                  onChange={onChangeInput}
                />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 5,
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    padding: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className="mdi mdi-eye-off" />
                  ) : (
                    <i className="mdi mdi-eye" />
                  )}
                </div>
              </div>
            </FormGroup>
          </Form>
          <ReCAPTCHA
            sitekey="6LcvhaUdAAAAAIBunPS3G7Hd-RMZ4NYksssM_cpD"
            onChange={onChangeCaptcha}
            onErrored={() => setIsValidCaptcha(false)}
            onExpired={() => setIsValidCaptcha(false)}
          />
          <button
            ref={submitRef}
            onClick={handleLogin}
            // className={`${
            //   isLoading ||
            //   !userData.userName.length ||
            //   !userData.password.length ||
            //   !isValidCaptcha
            //     ? "bg-grey"
            //     : "bg-darkblue"
            // }`}
            className={`${invalidateForm() ? "bg-grey" : "bg-darkblue"}`}
            style={{ marginTop: 36, minHeight: 54 }}
            // disabled={
            //   isLoading ||
            //   !userData.userName.length ||
            //   !userData.password.length ||
            //   !isValidCaptcha
            // }
            disabled={invalidateForm()}
          >
            {isLoading ? <Spinner /> : "Login"}
          </button>
          <div className="w-100" style={{ marginTop: 13 }}>
            <Link to="/forget-password" style={{ color: "#242393" }}>
              <i className="mdi mdi-lock"></i> Lupa Password?
            </Link>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
