import { takeEvery, put, all, call } from "redux-saga/effects";

import {
  GET_IKU_REQUEST,
  UPDATE_IKU_REQUEST,
  GET_IKU_ACCUMULATION_REQUEST,
} from "./actionTypes";

import {
  getIkuSuccess,
  getIkuFailure,
  updateIkuSuccess,
  updateIkuFailure,
  getIkuAccumulationSuccess,
  getIkuAccumulationFailure,
} from "./actions";

import {
  getIkuService,
  updateIkuService,
  getIkuAccumulationService,
} from "./services";

function* getIkuSaga() {
  try {
    const response = yield call(getIkuService);
    yield put(getIkuSuccess(response));
  } catch (error) {
    yield put(getIkuFailure(error));
  }
}

function* updateIkuSaga({ payload: { ikuNumber, value, callback } }) {
  try {
    const response = yield call(updateIkuService, ikuNumber, value);
    yield put(updateIkuSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(updateIkuFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* getIkuAccumulationSaga() {
  try {
    const response = yield call(getIkuAccumulationService);
    yield put(getIkuAccumulationSuccess(response));
  } catch (error) {
    yield put(getIkuAccumulationFailure(error));
  }
}

export default function* ikuSaga() {
  yield all([
    takeEvery(GET_IKU_REQUEST, getIkuSaga),
    takeEvery(UPDATE_IKU_REQUEST, updateIkuSaga),
    takeEvery(GET_IKU_ACCUMULATION_REQUEST, getIkuAccumulationSaga),
  ]);
}
