import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Input } from "reactstrap";
import { TableForm } from "../../components";
import { color, customColorSelect } from "../../helpers/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  getPilarsRequest,
  createPilarRequest,
  updatePilarRequest,
  deletePilarRequest,
} from "../../store/actions";
import Swal from "sweetalert2";

const Pilar = () => {
  /* HOOKS */
  const dispatch = useDispatch();

  /* LOCAL STATE */
  const [isEdit, setIsEdit] = useState(null);
  const [pilarName, setPilarName] = useState("");
  const [selectedPilar, setSelectedPilar] = useState(null);
  const [pilarOptions, setPilarOptions] = useState();
  const [pilarData, setPilarData] = useState([]);

  /* GLOBAL_STATE */
  const { pilars, isLoading } = useSelector((state) => state.MasterData);

  /* FUNCTIONS */
  const onChangeName = ({ target }) => {
    const { value } = target;
    setPilarName(value.toUpperCase());
  };

  const onChangeSelect = (value) => {
    setSelectedPilar(value);
  };

  const onClickEdit = (pilar) => {
    setIsEdit(pilar._id);
    setPilarName(pilar.name);
    setSelectedPilar({
      value: pilar._id,
      label: `${pilar.pilar}. ${pilar.name}`,
      pilarNumber: pilar.pilar,
    });
  };

  const onClickDelete = (_id) => {
    dispatch(
      deletePilarRequest(_id, (err, _) => {
        if (err) {
          Swal.fire({
            title: "Oops.",
            text: `${
              err === 406
                ? "Tidak dapat menghapus pilar yang telah memiliki strategi!"
                : "Terjadi kesalahan, coba lagi nanti!"
            }`,
            icon: "error",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 10000,
          });
        } else {
          Swal.fire({
            title: "Berhasil.",
            text: "Pilar berhasil dihapus.",
            icon: "success",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 5000,
          });
        }
      })
    );
  };

  const onClickSubmit = () => {
    const pilarData = {
      name: pilarName,
      pilarNumber: selectedPilar?.pilarNumber,
    };
    if (isEdit) {
      const _id = isEdit;
      dispatch(
        updatePilarRequest(_id, pilarData, (err, _) => {
          if (err) {
            // error alert
          } else {
            onClearForm();
          }
        })
      );
    } else {
      dispatch(
        createPilarRequest(pilarData, (err, _) => {
          if (err) {
            // error alert
          } else {
            onClearForm();
          }
        })
      );
    }
  };

  const onCLickCancel = () => {
    onClearForm();
  };

  const onClearForm = () => {
    setIsEdit(null);
    setSelectedPilar(null);
    setPilarName("");
  };

  /* LIFECYCLE */
  useEffect(() => {
    dispatch(getPilarsRequest());
  }, []); // eslint-disable-line

  useEffect(() => {
    setPilarData(
      pilars.map(({ _id, name, pilarNumber }) => ({
        _id,
        name,
        pilar: pilarNumber,
      }))
    );
  }, [pilars]); // eslint-disable-line

  useEffect(() => {
    const newPilarOptions = [];
    for (let newPilar = 1; newPilar <= 9; newPilar++) {
      let isNew = true;
      pilarData.forEach(({ pilar }) => {
        if (newPilar === pilar) {
          isNew = false;
          return;
        }
      });
      if (isNew) {
        newPilarOptions.push({
          value: newPilar,
          label: newPilar.toString(),
          pilarNumber: newPilar,
        });
      }
    }
    setPilarOptions(newPilarOptions);
  }, [pilarData]);

  return (
    <>
      <p className="page-title mb-4">Tambah Pilar</p>
      <div className="row">
        <div className="col-xs-12 col-xl-5 border-right form">
          <Select
            name="pilar"
            className="mb-3"
            placeholder="Pilih Pilar"
            isDisabled={isEdit}
            value={selectedPilar}
            options={isLoading ? [] : pilarOptions}
            onChange={onChangeSelect}
            styles={customColorSelect}
            isClearable
            isLoading={isLoading}
          />
          <Input
            className="mb-3 input-data"
            type="textarea"
            placeholder="Nama Pilar"
            onChange={onChangeName}
            value={pilarName}
            disabled={!selectedPilar}
            style={{
              backgroundColor: !selectedPilar ? "#f2f2f2" : "",
              minHeight: 50,
              padding: 14,
              height: 150,
            }}
          />
          <button
            onClick={onClickSubmit}
            type="button"
            className={`${
              isLoading || !pilarName.length || !selectedPilar
                ? "bg-grey"
                : "bg-green"
            } mb-3`}
            disabled={isLoading || !pilarName.length || !selectedPilar}
          >
            {isEdit ? "Simpan Perubahan" : "Tambah Pilar"}
          </button>
          {isEdit && (
            <button onClick={onCLickCancel} className="bg-red mb-3">
              Batal
            </button>
          )}
        </div>
        <div className="col-xs-12 col-xl-7 border-left">
          <TableForm
            headers={[
              { title: "No.", value: "pilar", alignValue: "center" },
              { title: "Nama Pilar", value: "name" },
            ]}
            data={pilarData}
            isEdit={isEdit}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};

export default Pilar;
