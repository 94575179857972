import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { userLogoutRequest } from "../../store/actions";
import { useHistory } from "react-router";

export default function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(userLogoutRequest(history));
  }, []); // eslint-disable-line
  return <></>;
}
