// import axios from "axios";
import axios from "../api";
import { baseUrl } from "../../helpers/config";
import store from "../index";

export const getInstitutionsService = async () => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "get",
      url: `${baseUrl}/api/institusi`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const createInstitutionService = async (institutionData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/institusi`,
      headers: { Authorization },
      data: institutionData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const updateInstitutionService = async (_id, institutionData) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "put",
      url: `${baseUrl}/api/institusi/${_id}`,
      headers: { Authorization },
      data: institutionData,
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const deleteInstitutionService = async (_id) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const response = await axios({
      method: "delete",
      url: `${baseUrl}/api/institusi/${_id}`,
      headers: { Authorization },
    });
    return response.data?.data;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};
