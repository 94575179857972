import {
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  UPLOAD_IMAGE_REQUEST,
  UPLOAD_IMAGE_SUCCESS,
  UPLOAD_IMAGE_FAILURE,
} from "./actionTypes";

export const uploadFileRequest = (file, callback) => ({
  type: UPLOAD_FILE_REQUEST,
  payload: { file, callback },
});

export const uploadFileSuccess = (response) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: response,
});

export const uploadFileFailure = (error) => ({
  type: UPLOAD_FILE_FAILURE,
  payload: error,
});

export const uploadImageRequest = (image, callback) => ({
  type: UPLOAD_IMAGE_REQUEST,
  payload: { image, callback },
});

export const uploadImageSuccess = (response) => ({
  type: UPLOAD_IMAGE_SUCCESS,
  payload: response,
});

export const uploadImageFailure = (error) => ({
  type: UPLOAD_IMAGE_FAILURE,
  payload: error,
});
