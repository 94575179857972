import {
  CREATE_HELP_REQUEST,
  CREATE_HELP_SUCCESS,
  CREATE_HELP_FAILURE,
} from "./actionTypes";

export const createHelpRequest = (helpData, callback) => ({
  type: CREATE_HELP_REQUEST,
  payload: { helpData, callback },
});

export const createHelpSuccess = (response) => ({
  type: CREATE_HELP_SUCCESS,
  payload: response,
});

export const createHelpFailure = (error) => ({
  type: CREATE_HELP_FAILURE,
  payload: error,
});
