export const formatCurrency = (money) => {
  return Intl.NumberFormat("id-ID", {
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(money);
};

export const formatCurrencyToNumber = (money) => {
  return Number(money.toString().split(".").join(""));
};

export const validateLatLng = (lat, lng) => {
  if (!lat || lat < -90 || lat > 90) return false;
  if (!lng || lng < -180 || lng > 180) return false;
  return true;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
