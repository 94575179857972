import React from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
  InfoWindow,
} from "react-google-maps";
import { compose, withProps, withState, withHandlers } from "recompose";
import { googleMapsApi } from "../../helpers/config";
import {
  MapMarkerPlaning,
  MapMarkerDoing,
  MapMarkerDone,
} from "../../assets/images/MapMarker";
import { color } from "../../helpers/colors";

const GoogleMapsComponen = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleMapsApi}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  withState("zoom", "setZoom", 11),
  withState("id", "setId", ""),
  withState("isVisible", "setIsVisible", false),
  withHandlers(() => {
    const refs = {
      map: undefined,
    };

    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      onZoomChanged:
        ({ setZoom }) =>
        () => {
          setZoom(refs.map.getZoom());
        },
      onClick:
        ({ setId }) =>
        (id) => {
          setId(id);
        },
      onClickReviewer:
        ({ isVisible, setIsVisible }) =>
        () => {
          setIsVisible(!isVisible);
        },
    };
  })
)((props) => (
  <GoogleMap
    defaultCenter={{ lat: -6.176312, lng: 106.828605 }}
    zoom={props.zoom}
    ref={props.onMapMounted}
    onZoomChanged={props.onZoomChanged}
    onClick={props.onClick}
  >
    <>
      {props.locations.map((location, locationIndex) => (
        <Marker
          key={locationIndex}
          position={location.latLng}
          icon={
            location?.status === "Selesai"
              ? MapMarkerDone
              : location?.status === "Pelaksanaan"
              ? MapMarkerDoing
              : MapMarkerPlaning
          }
          onClick={() => props.onClick(location?.detailId)}
        >
          {location?.detailId === props.id && (
            <InfoWindow onCloseClick={() => props.onClick("")}>
              <div style={{ maxWidth: "200px", fontSize: 10 }}>
                <p className="text-bold m-0">Penanggung Jawab</p>
                <p className="mb-2">{location.inChargeName}</p>
                <p className="text-bold m-0">Pilar</p>
                <p className="mb-2">{location.pilarName}</p>
                <p className="text-bold m-0">Strategi</p>
                <p className="mb-2">{location.strategyName}</p>
                <p className="text-bold m-0">Program</p>
                <p className="mb-2">{location.programName}</p>
                <p className="text-bold m-0">Kegiatan</p>
                <p className="mb-2">{location.activityName}</p>
                <p
                  className="text-center"
                  style={{ color: color.primary, cursor: "pointer" }}
                  onClick={props.onClickDetailActivity(location?.detailId)}
                >
                  Detail Kegiatan
                </p>
              </div>
            </InfoWindow>
          )}
        </Marker>
      ))}
    </>
  </GoogleMap>
));

const GMapsLocations = ({ locations = [], onClickDetailActivity }) => (
  <GoogleMapsComponen locations={locations} onClickDetailActivity={onClickDetailActivity} />
);

export default GMapsLocations;
