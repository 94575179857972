import {
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_REQUEST,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  USER_FORGET_PASSWORD_REQUEST,
  USER_FORGET_PASSWORD_SUCCESS,
  USER_FORGET_PASSWORD_FAILURE,
  USER_RESET_PASSWORD_REQUEST,
  USER_RESET_PASSWORD_SUCCESS,
  USER_RESET_PASSWORD_FAILURE,
} from "./actionTypes";

export const userLoginRequest = (userData, history, callback) => ({
  type: USER_LOGIN_REQUEST,
  payload: { userData, history, callback },
});

export const userLoginSuccess = (response) => ({
  type: USER_LOGIN_SUCCESS,
  payload: response,
});

export const userLoginFailure = (error) => ({
  type: USER_LOGIN_FAILURE,
  payload: error,
});

export const userLogoutRequest = (history) => ({
  type: USER_LOGOUT_REQUEST,
  payload: { history },
});

export const userLogoutSuccess = (response) => ({
  type: USER_LOGOUT_SUCCESS,
  payload: response,
});

export const userLogoutFailure = (error) => ({
  type: USER_LOGOUT_FAILURE,
  payload: error,
});

export const userForgetPasswordRequest = (userEmail, callback) => ({
  type: USER_FORGET_PASSWORD_REQUEST,
  payload: { userEmail, callback },
});

export const userForgetPasswordSuccess = (response) => ({
  type: USER_FORGET_PASSWORD_SUCCESS,
  payload: response,
});

export const userForgetPasswordFailure = (error) => ({
  type: USER_FORGET_PASSWORD_FAILURE,
  payload: error,
});

export const userResetPasswordRequest = (userData, callback) => ({
  type: USER_RESET_PASSWORD_REQUEST,
  payload: { userData, callback },
});

export const userResetPasswordSuccess = (response) => ({
  type: USER_RESET_PASSWORD_SUCCESS,
  payload: response,
});

export const userResetPasswordFailure = (error) => ({
  type: USER_RESET_PASSWORD_FAILURE,
  payload: error,
});
