import React, { useState, useLayoutEffect } from "react";
import ReactSpeedometer from "react-d3-speedometer";
import { blueWheelColors } from "../../helpers/colors";

const Speedo = ({ value }) => {
  const [val, setVal] = useState(50);
  const [width, setWidth] = useState(0);

  useLayoutEffect(() => {
    function updateSize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return (
    <>
      <div className="speedo-container card-shadow">
        <div className="percentage" onClick={() => setVal(val + 1)}>
          <p>{`${value || 0}%`}</p>
        </div>
        <div className="speedo mr-4 ml-4 mt-4">
          <div className="aspect-ratio">
            <div className="speedo-chart">
              <ReactSpeedometer
                minValue={0}
                maxValue={100}
                value={value || 0}
                needleColor="#242393"
                segmentColors={blueWheelColors
                  .map((color) => [color, "#ffffff"])
                  .join(",")
                  .split(",")}
                customSegmentStops={[
                  0, 12, 13, 24.5, 25.5, 37, 38, 49.5, 50.5, 62, 63, 74.5, 75.5,
                  87, 88, 100,
                ]}
                labelFontSize={0}
                forceRender={true}
                maxSegmentLabels={1}
                fluidWidth
                width={width}
                ringWidth={40}
                needleHeightRatio={0.6}
                currentValueText="Akumulasi Capaian"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Speedo;
