import { useEffect, useState } from "react";
import { getInstitutionsRequest } from "../store/actions";
import { useDispatch } from "react-redux";

export const useFilterPilarOptions = (all) => {
  const [filterPilarOptions] = useState([
    { value: 1, label: "Pilar 1" },
    { value: 2, label: "Pilar 2" },
    { value: 3, label: "Pilar 3" },
    { value: 4, label: "Pilar 4" },
    { value: 5, label: "Pilar 5" },
    { value: 6, label: "Pilar 6" },
    { value: 7, label: "Pilar 7" },
    { value: 8, label: "Pilar 8" },
    { value: 9, label: "Pilar 9" },
  ]);

  if (all) {
    return [{ value: "all", label: "Semua Pilar" }, ...filterPilarOptions];
  }

  return filterPilarOptions;
};

export const useFilterInChangeOptions = (all) => {
  const [filterInChargeOptions, setFilterInChargeOptions] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getInstitutionsRequest((_, resp) => {
        if (resp) {
          const newFilterInChargeOptions = resp.map(({ _id, name }) => ({
            value: _id,
            label: name,
          }));
          setFilterInChargeOptions(newFilterInChargeOptions);
        }
      })
    );
  }, []); // eslint-disable-line

  if (all) {
    return [
      { value: "all", label: "Semua Penanggung Jawab" },
      ...filterInChargeOptions,
    ];
  }

  return filterInChargeOptions;
};

export const useFilterYearOptions = (start = 2018, end = 2029) => {
  const [filterYearOptions, setFilterYearOptions] = useState([]);

  useEffect(() => {
    const newFilterYearOptions = [];
    for (let year = start; year <= end; year++) {
      newFilterYearOptions.push({
        value: year,
        label: year.toString(),
      });
    }
    setFilterYearOptions(newFilterYearOptions);
  }, [start, end]);
  return filterYearOptions;
};
