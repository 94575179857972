import { takeEvery, put, all, call } from "redux-saga/effects";

import { GET_LOCATIONS_REQUEST } from "./actionTypes";

import { getLocationsSuccess, getLocationsFailure } from "./actions";

import { getLocationsService } from "./services";

function* getLocationsSaga({ payload: { filter } }) {
  try {
    const response = yield call(getLocationsService, filter);
    yield put(getLocationsSuccess(response));
  } catch (error) {
    yield put(getLocationsFailure(error));
  }
}

export default function* institutionSaga() {
  yield all([takeEvery(GET_LOCATIONS_REQUEST, getLocationsSaga)]);
}
