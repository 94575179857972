import { takeEvery, put, all, call } from "redux-saga/effects";

import { CREATE_HELP_REQUEST } from "./actionTypes";

import { createHelpSuccess, createHelpFailure } from "./actions";

import { createHelpService } from "./services";

function* createHelpSaga({ payload: { helpData, callback } }) {
  try {
    const response = yield call(createHelpService, helpData);
    yield put(createHelpSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(createHelpFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

export default function* authSaga() {
  yield all([takeEvery(CREATE_HELP_REQUEST, createHelpSaga)]);
}
