import { takeEvery, put, all, call } from "redux-saga/effects";

import { UPLOAD_FILE_REQUEST, UPLOAD_IMAGE_REQUEST } from "./actionTypes";

import {
  uploadFileSuccess,
  uploadFileFailure,
  uploadImageSuccess,
  uploadImageFailure,
} from "./actions";

import { uploadFileService, uploadImageService } from "./services";

function* uploadFileSaga({ payload: { file, callback } }) {
  try {
    const response = yield call(uploadFileService, file);
    yield put(uploadFileSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(uploadFileFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

function* uploadImageSaga({ payload: { image, callback } }) {
  try {
    const response = yield call(uploadImageService, image);
    yield put(uploadImageSuccess(response));
    if (typeof callback === "function") {
      callback(null, response);
    }
  } catch (error) {
    yield put(uploadImageFailure(error));
    if (typeof callback === "function") {
      callback(error);
    }
  }
}

export default function* uploadSaga() {
  yield all([
    takeEvery(UPLOAD_FILE_REQUEST, uploadFileSaga),
    takeEvery(UPLOAD_IMAGE_REQUEST, uploadImageSaga),
  ]);
}
