// import axios from "axios";
import axios from "../api";
import { baseUrl } from "../../helpers/config";
import store from "../index";

export const uploadFileService = async (file) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const data = new FormData();
    data.append("file", file);
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/upload/file`,
      headers: { Authorization },
      data: data,
    });
    return response.data?.fileUrl;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};

export const uploadImageService = async (image) => {
  try {
    const token = store.getState()?.Auth?.user?.token;
    const Authorization = `Bearer ${token}`;
    const data = new FormData();
    data.append("image", image);
    const response = await axios({
      method: "post",
      url: `${baseUrl}/api/upload/image`,
      headers: {
        Authorization,
        "content-type": "multipart/form-data",
      },
      data: data,
    });
    return response.data?.fileUrl;
  } catch (error) {
    return Promise.reject(error?.response?.status || error);
  }
};
