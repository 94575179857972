import React, { Component } from "react";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { compose, withProps, lifecycle } from "recompose";
import { googleMapsApi } from "../../helpers/config";

const Gmaps = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleMapsApi}4&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        position: null,
        onMarkerMounted: (ref) => {
          refs.marker = ref;
        },

        onPositionChanged: () => {
          const position = refs.marker.getPosition();
          this.props.handleGeolocation(position);
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)(({ lat, lng, onMarkerMounted, onPositionChanged }) => (
  <GoogleMap defaultZoom={15} defaultCenter={{ lat, lng }}>
    <Marker
      position={{ lat, lng }}
      draggable={false}
      ref={onMarkerMounted}
      onPositionChanged={onPositionChanged}
    />
  </GoogleMap>
));

class ViewMaps extends Component {
  state = {
    isMarkerShown: false,
  };

  render() {
    const { lat, lng } = this.props;
    return (
      <>
        <Gmaps lat={lat} lng={lng} isMarkerShown={true} />
      </>
    );
  }
}

export default ViewMaps;
