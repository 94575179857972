import {
  GET_INSTITUTIONS_REQUEST,
  GET_INSTITUTIONS_SUCCESS,
  GET_INSTITUTIONS_FAILURE,
  CREATE_INSTITUTION_REQUEST,
  CREATE_INSTITUTION_SUCCESS,
  CREATE_INSTITUTION_FAILURE,
  UPDATE_INSTITUTION_REQUEST,
  UPDATE_INSTITUTION_SUCCESS,
  UPDATE_INSTITUTION_FAILURE,
  DELETE_INSTITUTION_REQUEST,
  DELETE_INSTITUTION_SUCCESS,
  DELETE_INSTITUTION_FAILURE,
} from "./actionTypes";

export const getInstitutionsRequest = (callback) => ({
  type: GET_INSTITUTIONS_REQUEST,
  payload: { callback },
});

export const getInstitutionsSuccess = (response) => ({
  type: GET_INSTITUTIONS_SUCCESS,
  payload: response,
});

export const getInstitutionsFailure = (error) => ({
  type: GET_INSTITUTIONS_FAILURE,
  payload: error,
});

export const createInstitutionRequest = (institutionData, callback) => ({
  type: CREATE_INSTITUTION_REQUEST,
  payload: { institutionData, callback },
});

export const createInstitutionSuccess = (response) => ({
  type: CREATE_INSTITUTION_SUCCESS,
  payload: response,
});

export const createInstitutionFailure = (error) => ({
  type: CREATE_INSTITUTION_FAILURE,
  payload: error,
});

export const updateInstitutionRequest = (_id, institutionData, callback) => ({
  type: UPDATE_INSTITUTION_REQUEST,
  payload: { _id, institutionData, callback },
});

export const updateInstitutionSuccess = (response) => ({
  type: UPDATE_INSTITUTION_SUCCESS,
  payload: response,
});

export const updateInstitutionFailure = (error) => ({
  type: UPDATE_INSTITUTION_FAILURE,
  payload: error,
});

export const deleteInstitutionRequest = (_id, callback) => ({
  type: DELETE_INSTITUTION_REQUEST,
  payload: { _id, callback },
});

export const deleteInstitutionSuccess = (response) => ({
  type: DELETE_INSTITUTION_SUCCESS,
  payload: response,
});

export const deleteInstitutionFailure = (error) => ({
  type: DELETE_INSTITUTION_FAILURE,
  payload: error,
});
