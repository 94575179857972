import React, { useRef, useState } from "react";
import {
  Form,
  FormGroup,
  // Label,
  Input,
} from "reactstrap";
import LoginBackground from "../../assets/images/loginBG.png";
import Logo from "../../assets/images/logo-dark@2x.png";
import { userForgetPasswordRequest } from "../../store/actions";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { validateEmail } from "../../helpers/utils";
import { color } from "../../helpers/colors";

const ForgetPassword = () => {
  const usernameRef = useRef(null);
  const submitRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [isReset, setIsReset] = useState(false);

  const onChangeInput = ({ target }) => {
    const { value } = target;
    setEmail(value);
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      submitRef.current.click();
      event.preventDefault();
    }
  };

  const handleLogin = () => {
    if (isReset) {
      history.push("/login");
    } else {
      dispatch(
        userForgetPasswordRequest(email, (err, resp) => {
          if (err) {
            Swal.fire({
              title: `${err === 404 ? "Email Tidak Ditemukan" : "Oops."}`,
              text: `${
                err === 404
                  ? "Email yang anda masukan tidak terdaftar."
                  : "Terjadi kesalahan, silakan coba lagi!"
              }`,
              icon: "error",
              position: "top-end",
              confirmButtonColor: color.primary,
              timer: 5000,
            });
          } else {
            Swal.fire({
              title: "Berhasil.",
              text: "Password baru anda segera diproses. Silahkan lakukan pengecekan pada email terdaftar!",
              icon: "success",
              position: "top-end",
              confirmButtonColor: color.primary,
              timer: 5000,
            });
            setIsReset(true);
          }
        })
      );
    }
  };

  return (
    <React.Fragment>
      <div
        style={{
          background: `url(${LoginBackground}) no-repeat center center fixed`,
          backgroundSize: "cover",
          width: "100%",
          height: "100%",
          position: "fixed",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          display: "flex",
          alignItems: "center",
          // overflow: "auto",
          justifyContent: "center",
        }}
      >
        <div
          className="form"
          style={{
            backgroundColor: "white",
            borderRadius: 12,
            width: 513,
            // position: "absolute",
            // right: "7%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "30px 36px 36px 48px",
            maxWidth: "86%",
          }}
        >
          <img
            src={Logo}
            alt="Logo"
            style={{ maxWidth: "40%", marginBottom: 60, marginTop: 20 }}
          />
          {/* <p style={{ fontSize: 30, fontWeight: 600 }}>Lupa Password</p> */}
          <p className="text-center m-0">
            {isReset
              ? `Password baru anda segera diproses. Silahkan lakukan pengecekan pada email terdaftar.`
              : "Masukkan email yang terdaftar untuk akun anda."}
          </p>

          {!isReset && (
            <Form style={{ width: "100%" }}>
              <FormGroup style={{ marginTop: 36 }}>
                <Input
                  ref={usernameRef}
                  id="username"
                  name="username"
                  placeholder="Masukan Email"
                  type="text"
                  style={{
                    backgroundColor: "#f9f9f9",
                    height: 48,
                    textAlign: "center",
                  }}
                  autoFocus
                  onKeyDown={handleEnter}
                  onChange={onChangeInput}
                  value={email}
                />
              </FormGroup>
            </Form>
          )}
          <button
            ref={submitRef}
            onClick={handleLogin}
            className={`${validateEmail(email) ? "bg-darkblue" : "bg-grey"}`}
            style={{ marginTop: 36, minHeight: 54 }}
            disabled={!validateEmail(email)}
          >
            {isReset ? "Kembali ke Halaman Login" : "Kirim"}
          </button>
          {!isReset && (
            <div className="w-100" style={{ marginTop: 13 }}>
              <Link to="/login" style={{ color: "#242393" }}>
                Kembali ke Halaman Login
              </Link>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ForgetPassword;
