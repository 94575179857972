import React, { useState, Fragment, useEffect, useRef } from "react";
import Select from "react-select";
import {
  useFilterPilarOptions,
  useFilterInChangeOptions,
  useFilterYearOptions,
  useOnlineStatus,
} from "../../hooks";
import {
  activityColors,
  fundColors,
  customColorSelect,
  customColorSelect2,
  blueWheelColors,
  color,
} from "../../helpers/colors";
import {
  Modal,
  Input,
  Label,
  FormGroup,
  CustomInput,
  Spinner,
  Collapse,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getDetailRequest,
  getPreviewDetailRequest,
  createActivityStatusRequest,
  updateActivityStatusRequest,
  deleteActivityStatusRequest,
  uploadFileRequest,
  getReportRequest,
  toggleYearRequest,
} from "../../store/actions";
import { GMapsSelect, GMapsCardMultiple } from "../../components/Map";
import { formatCurrency, formatCurrencyToNumber } from "../../helpers/utils";
import { Popconfirm } from "antd";
import "antd/dist/antd.css";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { BlockSpinner } from "../../components";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import SweetAlert from "react-bootstrap-sweetalert";
import { Table } from "react-bootstrap";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";
import "moment/locale/id"; // without this line it didn't work
moment.locale("id");

const Detail = () => {
  /* HOOKS */
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryString = new URLSearchParams(search);
  const keyword = queryString.get("search");
  const onlineStatus = useOnlineStatus();

  /* LOCAL STATE */
  const filterPilarOptions = useFilterPilarOptions();
  const [filterPilarValue, setFilterPilarValue] = useState({
    value: 1,
    label: "Pilar 1",
  });

  const filterInChargeOptions = useFilterInChangeOptions();
  const [filterInChargeValue, setFilterInChargeValue] = useState(null);

  const filterStartYearOptions = useFilterYearOptions();
  const [filterStartYearValue, setFilterStartValue] = useState(null);

  const filterEndYearOptions = useFilterYearOptions(
    filterStartYearValue?.value || 2018
  );
  const [filterEndYearValue, setFilterEndYearValue] = useState(null);

  const [isOpenInChargeList, setIsOpenInChargeList] = useState(false);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  // const [isChangedForm, setIsChangedForm] = useState(false);

  const [inChargeList, setInChargeList] = useState(null);
  const [previewData, setPreviewData] = useState(null);

  const [isEdit, setIsEdit] = useState(false);

  const [isOpenReport, setIsOpenReport] = useState(false);

  /* GLOBAL STATE */
  const { detail, isLoading, isLoadingPreview } = useSelector(
    (state) => state.Detail
  );
  const { isLoading: isLoadingInstitution } = useSelector(
    (state) => state.Institution
  );
  const { user } = useSelector((state) => state.Auth);

  /* FUNCTIONS */
  const getAndSetPreviewDetail = (_id) => {
    dispatch(
      getPreviewDetailRequest(_id, (err, resp) => {
        if (err) {
          // error
        } else {
          setPreviewData(resp);
        }
      })
    );
  };

  const toggleInChargeList = (activity, index) => () => {
    if (activity) {
      setInChargeList({ ...activity, title: `${index}. ${activity.name}` });
      setIsOpenInChargeList(true);
    } else {
      setInChargeList(null);
      setIsOpenInChargeList(false);
    }
  };

  const togglePreviewDetail = (_id) => () => {
    if (_id) {
      setIsOpenPreview(true);
      getAndSetPreviewDetail(_id);
    } else {
      setIsOpenPreview(false);
      setPreviewData(null);
    }
  };

  const toggleForm = (isEdit, isChange) => () => {
    if (!isOpenForm) {
      setIsOpenForm(!isOpenForm);
      setIsEdit(isEdit);
    } else {
      if (isChange) {
        Swal.fire({
          title: "Perubahan data belum disimpan!",
          text: "Yakin ingin keluar?",
          icon: "info",
          showDenyButton: true,
          denyButtonText: `Iya`,
          denyButtonColor: color.error,
          confirmButtonText: "Tidak",
          confirmButtonColor: color.primary,
        }).then(({ isDenied }) => {
          if (isDenied) toggleForm(false)();
        });
      } else {
        setIsOpenForm(!isOpenForm);
        setIsEdit(isEdit);
      }
    }
  };

  const onChangeFilter = (option, { name }) => {
    switch (name) {
      case "pilar":
        setFilterPilarValue(option || null);
        break;
      case "in-charge":
        setFilterInChargeValue(option || null);
        break;
      case "start-year":
        setFilterStartValue(option);
        if (!option) {
          setFilterEndYearValue(null);
        } else if (filterEndYearValue?.value < option?.value) {
          setFilterEndYearValue(option);
        }
        break;
      case "end-year":
        setFilterEndYearValue(option);
        break;
      default:
        break;
    }
  };

  const onRefreshDetail = () => {
    const search = keyword || "";
    const page = filterPilarValue.value;
    const unit = filterInChargeValue?.value;
    const start = filterStartYearValue?.value || 2018;
    const end = filterEndYearValue?.value || 2029;
    dispatch(getDetailRequest({ search, page, unit, start, end }));
  };

  const onToggleYear =
    (
      _id,
      isLock,
      { inChargeName = "", activityName = "", year = "" },
      detailId
    ) =>
    () => {
      if (_id) {
        if (user?.role === "super-admin") {
          if (onlineStatus === "offline") {
            Swal.fire({
              title: "Koneksi terputus.",
              text: "Koneksi terputus, silahkan periksa kembali koneksi internet anda",
              icon: "error",
              position: "top-end",
              confirmButtonColor: color.primary,
              timer: 5000,
            });
          } else {
            dispatch(
              toggleYearRequest(_id, isLock, (err, resp) => {
                if (err) {
                  Swal.fire({
                    title: "Maaf.",
                    text: "Terjadi kesalahan, silakan coba lagi nanti.",
                    icon: "error",
                    position: "top-end",
                    confirmButtonColor: color.primary,
                  });
                } else {
                  onRefreshDetail();
                  toggleInChargeList()();
                  Swal.fire({
                    title: isLock ? "Akses Dikunci" : "Akses Dibuka",
                    html: `Berhasil ${
                      isLock ? "mengunci" : "membuka akses"
                    } kegiatan: <br/><i>${activityName} > ${inChargeName} > ${year}</i>`,
                    icon: "success",
                    position: "top-end",
                    confirmButtonColor: color.primary,
                  });
                }
              })
            );
          }
        } else if (user?.role === "admin") {
          if (isLock) {
            togglePreviewDetail(detailId)();
          } else {
            const nowYear = new Date().getFullYear();
            Swal.fire({
              title: "Maaf.",
              html:
                year < nowYear
                  ? "Akses perubahan data otomatis terkunci pada 31 Januari tahun berikutnya. <br/><br/>" +
                    "Apabila terlambat melaporkan kegiatan, Hubungi BPTJ lewat menu Bantuan untuk meminta akses perubahan data dibuka.<br/><br/>" +
                    "<i>Cantumkan Nama Kegiatan dan Tahun pelaksanaan.</i>"
                  : year === nowYear
                  ? "Akses perubahan data telah terkunci, silahkan Hubungi BPTJ lewat menu Bantuan untuk meminta akses dibuka. <br/><br/>" +
                    "<i>Cantumkan Nama Kegiatan dan Tahun pelaksanaan.</i>"
                  : "Untuk perubahan data tahun mendatang, akses akan terbuka saat memasuki tahun berjalan.",
              icon: "warning",
              position: "top-end",
              confirmButtonColor: color.primary,
            });
          }
        }
      }
    };

  /* LIFECYCLE */
  useEffect(() => {
    onRefreshDetail();
    // eslint-disable-next-line
  }, [
    filterPilarValue,
    filterInChargeValue,
    filterStartYearValue,
    filterEndYearValue,
    keyword,
  ]);

  // useEffect(() => {
  //   console.log({ onlineStatus });
  // }, [onlineStatus]);

  /* COMPONENTS */
  const ButtonAndFilters = () => (
    <div
      className="actions-group"
      style={{ position: "relative", zIndex: 200 }}
    >
      <div className="action-container">
        <button
          className="btn"
          style={{ backgroundColor: color.danger, color: "white", height: 42 }}
          onClick={() => setIsOpenReport(true)}
        >
          Cetak Report
        </button>
      </div>
      <div className="action-container">
        <p>Filter by :</p>
        {!keyword && (
          <Select
            name="pilar"
            className="action filter-pilar mr-2"
            placeholder="Pilar"
            value={filterPilarValue}
            onChange={onChangeFilter}
            options={filterPilarOptions}
            styles={customColorSelect}
          />
        )}
        {user?.role !== "admin" && (
          <Select
            name="in-charge"
            className="action filter-in-change mr-2"
            placeholder="Penanggung Jawab"
            value={filterInChargeValue}
            onChange={onChangeFilter}
            options={filterInChargeOptions}
            isClearable
            styles={customColorSelect}
            isLoading={isLoadingInstitution}
          />
        )}
        <Select
          name="start-year"
          className="action filter-year mr-2"
          placeholder="Tahun Awal"
          value={filterStartYearValue}
          onChange={onChangeFilter}
          options={filterStartYearOptions}
          isClearable
          styles={customColorSelect}
        />
        <Select
          name="end-year"
          className="action filter-year"
          placeholder="Tahun Akhir"
          value={filterEndYearValue}
          onChange={onChangeFilter}
          options={filterEndYearOptions}
          isClearable
          isDisabled={!filterStartYearValue}
          styles={customColorSelect}
        />
      </div>
    </div>
  );

  const TableDetailPilar = () => {
    const headerYears = [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
    const headerFunds = ["APBN", "APBD", "Swasta", "Lainnya", "Total"];
    const bodyYears = [
      2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
    ];
    const bodyFunds = ["APBN", "APBD", "Swasta", "Lainnya"];
    return (
      <>
        <div className="card card-custom gutter-b card-stretch mt-3">
          <div style={{ overflow: "auto", maxHeight: "75vh", height: "100%" }}>
            <BlockSpinner show={isLoading} />
            <Table hover style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead
                className="text-center"
                style={{
                  position: "sticky",
                  top: 0,
                  backgroundColor: "white",
                  zIndex: 100,
                }}
              >
                <tr>
                  <th
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: blueWheelColors[3],
                      color: "#ffffff",
                      verticalAlign: "middle",
                      borderTopLeftRadius: 6,
                      border: "none",
                    }}
                    rowSpan={2}
                  >
                    No.
                  </th>
                  <th
                    style={{
                      position: "sticky",
                      left: 46,
                      minWidth: 550,
                      backgroundColor: blueWheelColors[2],
                      color: "#ffffff",
                      verticalAlign: "middle",
                      border: "none",
                    }}
                    rowSpan={2}
                    colSpan={3}
                  >
                    Pilar - Strategi - Program - Kegiatan
                  </th>
                  <th
                    style={{
                      backgroundColor: blueWheelColors[3],
                      color: "#ffffff",
                      border: "none",
                    }}
                    colSpan={12}
                  >
                    Tahun (dalam 2000)
                  </th>
                  <th
                    style={{
                      backgroundColor: blueWheelColors[2],
                      color: "#ffffff",
                      border: "none",
                    }}
                    colSpan={5}
                  >
                    Rencana Anggaran (dalam Juta){" "}
                  </th>
                  <th
                    style={{
                      backgroundColor: blueWheelColors[3],
                      color: "#ffffff",
                      borderTopRightRadius: 7,
                      border: "none",
                    }}
                    colSpan={5}
                  >
                    Realisasi Anggaran (dalam Juta)
                  </th>
                </tr>
                <tr>
                  {headerYears.map((year) => (
                    <th
                      style={{
                        backgroundColor: blueWheelColors[3],
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      {year}
                    </th>
                  ))}
                  {headerFunds.map((fund) => (
                    <th
                      style={{
                        backgroundColor: blueWheelColors[2],
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      {fund}
                    </th>
                  ))}
                  {headerFunds.map((fund) => (
                    <th
                      style={{
                        backgroundColor: blueWheelColors[3],
                        color: "#ffffff",
                        border: "none",
                      }}
                    >
                      {fund}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {detail.map((pilar) => {
                  return (
                    <Fragment key={pilar._id}>
                      <tr>
                        <td
                          style={{
                            position: "sticky",
                            left: 0,
                            backgroundColor: blueWheelColors[5],
                            color: "#ffffff",
                            border: "none",
                            top: 90,
                            zIndex: 100,
                          }}
                          align="center"
                        >
                          {keyword && (pilar?.pilarNumber || "")}
                        </td>
                        <td
                          style={{
                            position: "sticky",
                            left: 46,
                            backgroundColor: blueWheelColors[5],
                            color: "#ffffff",
                            border: "none",
                            top: 90,
                            zIndex: 100,
                          }}
                          colSpan={3}
                        >
                          {pilar.name}
                        </td>
                        <td
                          colSpan={22}
                          style={{
                            backgroundColor: blueWheelColors[5],
                            color: "#ffffff",
                            border: "none",
                            position: "sticky",
                            top: 90,
                            zIndex: 99,
                          }}
                        />
                      </tr>
                      {pilar?.strategies
                        .filter((strategy) => {
                          let filterResult = false;
                          for (let i = 0; i < strategy?.programs?.length; i++) {
                            for (
                              let j = 0;
                              j < strategy?.programs[i]?.activities?.length;
                              j++
                            ) {
                              if (
                                strategy?.programs[i]?.activities[j]
                                  ?.activityDetail?.length
                              ) {
                                filterResult = true;
                                break;
                              }
                            }
                          }
                          return filterResult;
                        })
                        .map((strategy, strategyIndex) => {
                          return (
                            <Fragment key={strategy._id}>
                              <tr>
                                <td
                                  align="center"
                                  style={{
                                    position: "sticky",
                                    left: 0,
                                    backgroundColor: "white",
                                    top: 134.5,
                                    zIndex: 100,
                                  }}
                                >
                                  {strategyIndex + 1}.
                                </td>
                                <td
                                  style={{
                                    position: "sticky",
                                    left: 46,
                                    backgroundColor: "white",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    maxWidth: 500,
                                    top: 134.5,
                                    zIndex: 100,
                                  }}
                                  colSpan={3}
                                >
                                  {strategy.name}
                                </td>
                                <td
                                  style={{
                                    position: "sticky",
                                    left: 0,
                                    backgroundColor: "white",
                                    top: 134.5,
                                    zIndex: 99,
                                  }}
                                  colSpan={22}
                                />
                              </tr>
                              {strategy.programs
                                .filter((program) => {
                                  let result = false;
                                  for (
                                    let i = 0;
                                    i < program?.activities?.length;
                                    i++
                                  ) {
                                    if (
                                      program?.activities?.[i]?.activityDetail
                                        ?.length
                                    ) {
                                      result = true;
                                      break;
                                    }
                                  }
                                  return result;
                                })
                                .map((program, programIndex) => {
                                  const ProgramList = () => {
                                    // const [isOpenCollapse, setIsOpenCollapse] =
                                    //   useState(
                                    //     Boolean(keyword?.length) ||
                                    //       Boolean(
                                    //         strategyIndex === 0 &&
                                    //           programIndex === 0
                                    //       )
                                    //   );
                                    return (
                                      <Fragment key={program._id}>
                                        <tr>
                                          <td
                                            style={{
                                              position: "sticky",
                                              left: 0,
                                              backgroundColor: "white",
                                              top: 180,
                                              zIndex: 100,
                                            }}
                                          >
                                            <div>
                                              {/* <i
                                                className={
                                                  isOpenCollapse
                                                    ? "ion ion-md-arrow-dropdown"
                                                    : "ion ion-md-arrow-dropright"
                                                }
                                                style={{
                                                  fontSize: 30,
                                                  color: color.primary,
                                                  marginTop: -5,
                                                }}
                                              /> */}
                                            </div>
                                          </td>
                                          <td
                                            style={{
                                              position: "sticky",
                                              left: 46,
                                              backgroundColor: "white",
                                              top: 179.5,
                                              zIndex: 100,
                                            }}
                                          >
                                            {String.fromCharCode(
                                              programIndex + 65
                                            )}
                                            .
                                          </td>
                                          <td
                                            style={{
                                              position: "sticky",
                                              left: 84,
                                              backgroundColor: "white",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              maxWidth: 500,
                                              top: 179.5,
                                              zIndex: 100,
                                            }}
                                            colSpan={2}
                                          >
                                            {program.name}
                                          </td>
                                          <td
                                            style={{
                                              position: "sticky",
                                              left: 84,
                                              backgroundColor: "white",
                                              overflow: "hidden",
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              maxWidth: 500,
                                              top: 179.5,
                                              zIndex: 99,
                                            }}
                                            colSpan={22}
                                          />
                                        </tr>
                                        {program.activities
                                          .filter(
                                            (activity) =>
                                              activity?.activityDetail?.length
                                          )
                                          .map((activity, activityIndex) => {
                                            let totalBudgetPlaning = 0;
                                            let totalBudgetRealization = 0;
                                            const status =
                                              activity?.activityDetail?.[0]
                                                ?.status?.status;
                                            return (
                                              <Fragment key={activity._id}>
                                                <tr>
                                                  <td
                                                    style={{
                                                      position: "sticky",
                                                      left: 0,
                                                      backgroundColor: "white",
                                                    }}
                                                  ></td>
                                                  <td
                                                    style={{
                                                      position: "sticky",
                                                      left: 46,
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    <div>
                                                      {filterInChargeValue ||
                                                      user?.role === "admin" ? (
                                                        <i
                                                          className="mdi mdi-bookmark mdi-24px"
                                                          style={{
                                                            color:
                                                              activityColors[
                                                                status ===
                                                                "Pelaksanaan"
                                                                  ? 1
                                                                  : status ===
                                                                    "Selesai"
                                                                  ? 2
                                                                  : 0
                                                              ],
                                                          }}
                                                        />
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  </td>
                                                  <td
                                                    style={{
                                                      position: "sticky",
                                                      left: 84,
                                                      backgroundColor: "white",
                                                      verticalAlign: "middle",
                                                    }}
                                                    align="center"
                                                  >
                                                    {activityIndex + 1}.
                                                  </td>
                                                  <td
                                                    onClick={
                                                      filterInChargeValue ||
                                                      user?.role === "admin"
                                                        ? togglePreviewDetail(
                                                            activity
                                                              .activityDetail?.[0]
                                                              ?._id
                                                          )
                                                        : toggleInChargeList(
                                                            activity,
                                                            activityIndex + 1
                                                          )
                                                    }
                                                    style={{
                                                      position: "sticky",
                                                      left: 119,
                                                      backgroundColor: "white",
                                                      verticalAlign: "middle",
                                                      overflow: "hidden",
                                                      textOverflow: "ellipsis",
                                                      whiteSpace: "nowrap",
                                                      maxWidth: 500,
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    {activity.name}
                                                  </td>
                                                  {bodyYears.map((year) => {
                                                    const inChargesFiltered =
                                                      activity.activityDetail.filter(
                                                        ({ implementations }) =>
                                                          implementations.find(
                                                            (val) =>
                                                              val.year === year
                                                          )
                                                      );
                                                    let implementationId = null;
                                                    let isLock = true;
                                                    if (
                                                      filterInChargeValue ||
                                                      user?.role === "admin"
                                                    ) {
                                                      inChargesFiltered?.[0]?.implementations.forEach(
                                                        (val) => {
                                                          if (
                                                            val.year === year
                                                          ) {
                                                            implementationId =
                                                              val._id;
                                                            if (
                                                              val.isLock ===
                                                                false &&
                                                              isLock === true
                                                            ) {
                                                              isLock = false;
                                                            }
                                                          }
                                                        }
                                                      );
                                                    }
                                                    const inChargesTotal =
                                                      inChargesFiltered.length;
                                                    return (
                                                      <td key={year}>
                                                        <div
                                                          style={{
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: 4,
                                                            display: "flex",
                                                            justifyContent:
                                                              "center",
                                                            alignItems:
                                                              "center",
                                                            paddingTop: 1.5,
                                                            color: "white",
                                                            backgroundColor:
                                                              inChargesTotal
                                                                ? blueWheelColors[5]
                                                                : "#f6f6f6",
                                                            // cursor: "pointer"
                                                            cursor:
                                                              inChargesTotal
                                                                ? "pointer"
                                                                : "default",
                                                            // cursor:
                                                            //   filterInChargeValue &&
                                                            //   Boolean(
                                                            //     inChargesTotal
                                                            //   )
                                                            //     ? "pointer"
                                                            //     : "default",
                                                          }}
                                                          onClick={onToggleYear(
                                                            implementationId,
                                                            !isLock,
                                                            {
                                                              year,
                                                              activityName:
                                                                activity.name,
                                                              inChargeName:
                                                                filterInChargeValue?.label,
                                                            },
                                                            activity
                                                              .activityDetail?.[0]
                                                              ?._id
                                                          )}
                                                        >
                                                          {filterInChargeValue ||
                                                          user?.role === "admin"
                                                            ? Boolean(
                                                                inChargesTotal
                                                              ) && (
                                                                <i
                                                                  className={`fas fa-lock${
                                                                    isLock
                                                                      ? ""
                                                                      : "-open"
                                                                  }`}
                                                                />
                                                              )
                                                            : inChargesTotal
                                                            ? inChargesTotal
                                                            : ""}
                                                        </div>
                                                      </td>
                                                    );
                                                  })}
                                                  {bodyFunds.map((fund) => {
                                                    let budgedPlaning = 0;
                                                    const imp =
                                                      activity.activityDetail.map(
                                                        ({ implementations }) =>
                                                          implementations
                                                      );
                                                    imp.forEach(
                                                      (yearProgressList) => {
                                                        yearProgressList.forEach(
                                                          (yearProgress) => {
                                                            yearProgress.progress.forEach(
                                                              (progress) => {
                                                                progress.budgets.forEach(
                                                                  (budged) => {
                                                                    if (
                                                                      budged.name ===
                                                                      fund
                                                                    )
                                                                      budgedPlaning +=
                                                                        budged.planing;
                                                                  }
                                                                );
                                                              }
                                                            );
                                                          }
                                                        );
                                                      }
                                                    );
                                                    totalBudgetPlaning +=
                                                      budgedPlaning;
                                                    return (
                                                      <td
                                                        key={fund}
                                                        style={{
                                                          verticalAlign:
                                                            "middle",
                                                        }}
                                                        align="center"
                                                      >
                                                        {formatCurrency(
                                                          budgedPlaning
                                                        )}
                                                      </td>
                                                    );
                                                  })}
                                                  <td
                                                    style={{
                                                      verticalAlign: "middle",
                                                    }}
                                                    align="center"
                                                  >
                                                    {formatCurrency(
                                                      totalBudgetPlaning
                                                    )}
                                                  </td>
                                                  {bodyFunds.map((fund) => {
                                                    let budgedRealization = 0;
                                                    const imp =
                                                      activity.activityDetail.map(
                                                        ({ implementations }) =>
                                                          implementations
                                                      );
                                                    imp.forEach(
                                                      (yearProgressList) => {
                                                        yearProgressList.forEach(
                                                          (yearProgress) => {
                                                            yearProgress.progress.forEach(
                                                              (progress) => {
                                                                progress.budgets.forEach(
                                                                  (budged) => {
                                                                    if (
                                                                      budged.name ===
                                                                      fund
                                                                    )
                                                                      budgedRealization +=
                                                                        budged.realization;
                                                                  }
                                                                );
                                                              }
                                                            );
                                                          }
                                                        );
                                                      }
                                                    );
                                                    totalBudgetRealization +=
                                                      budgedRealization;
                                                    return (
                                                      <td
                                                        key={fund}
                                                        style={{
                                                          verticalAlign:
                                                            "middle",
                                                        }}
                                                        align="center"
                                                      >
                                                        {formatCurrency(
                                                          budgedRealization
                                                        )}
                                                      </td>
                                                    );
                                                  })}
                                                  <td
                                                    style={{
                                                      verticalAlign: "middle",
                                                    }}
                                                    align="center"
                                                  >
                                                    {formatCurrency(
                                                      totalBudgetRealization
                                                    )}
                                                  </td>
                                                </tr>
                                              </Fragment>
                                            );
                                          })}
                                      </Fragment>
                                    );
                                  };
                                  return <ProgramList />;
                                })}
                            </Fragment>
                          );
                        })}
                    </Fragment>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </>
      // <>
      //   <div
      //     style={{
      //       // marginRight: -22,
      //       // marginLeft: -44,
      //       overflow: "auto",
      //       // height: "75vh",
      //       // position: "sticky",
      //       // top: 0
      //     }}
      //     className="mt-4"
      //   >
      //     {isLoading && (
      //       <div
      //         style={{
      //           position: "absolute",
      //           display: "flex",
      //           justifyContent: "center",
      //           alignItems: "center",
      //           width: "100%",
      //           height: "100%",
      //           background: "white",
      //           opacity: 0.5,
      //         }}
      //       >
      //         <Spinner />
      //       </div>
      //     )}
      //     <table
      //       className="table card-shadow"
      //       style={{
      //         // borderCollapse: "collapse",
      //         // borderRadius: 6,
      //         overflow: "hidden",
      //         // overflowX: "scroll",
      //         // tableLayout: "fixed",
      //         // width: 1690,
      //         // minWidth: 1400,
      //         // width: "auto",
      //         // marginLeft: 22,
      //         // marginRight: 22,"
      //         // position: "relative",
      //         // borderCollapse: "collapse",
      //       }}
      //     >
      //       <thead>
      //         <tr>
      //           <th
      //             className="text-center"
      //             align="center"
      //             style={{
      //               backgroundColor: blueWheelColors[3],
      //               color: "#ffffff",
      //               verticalAlign: "middle",

      //               position: "absolute",
      //               display: "inline-block",
      //               height: 92,
      //               border: 0,
      //               width: 40,
      //               paddingTop: 35,
      //               borderTopLeftRadius: 10,
      //             }}
      //             rowSpan={2}
      //           >
      //             No.
      //           </th>
      //           <th
      //             className="text-center"
      //             align="center"
      //             style={{
      //               backgroundColor: blueWheelColors[2],
      //               color: "#ffffff",
      //               verticalAlign: "middle",
      //               // width: "444px",
      //               // minWidth: 570,

      //               marginLeft: 40,
      //               position: "absolute",
      //               display: "inline-block",
      //               height: 92,
      //               border: 0,
      //               width: 560,
      //               paddingTop: 35,
      //             }}
      //             rowSpan={2}
      //             colSpan={3}
      //           >
      //             Pilar - Strategi - Program - Kegiatan
      //           </th>
      //           <th />
      //           <th />
      //           <th
      //             className="text-center"
      //             align="center"
      //             style={{
      //               backgroundColor: blueWheelColors[3],
      //               color: "#ffffff",
      //               paddingLeft: 600,
      //             }}
      //             colSpan={12}
      //           >
      //             Tahun (dalam 2000)
      //           </th>
      //           <th
      //             className="text-center"
      //             align="center"
      //             style={{
      //               backgroundColor: blueWheelColors[2],
      //               color: "#ffffff",
      //             }}
      //             colSpan={5}
      //           >
      //             Rencana Anggaran (dalam Juta)
      //           </th>
      //           <th
      //             className="text-center"
      //             align="center"
      //             style={{
      //               backgroundColor: blueWheelColors[3],
      //               color: "#ffffff",
      //             }}
      //             colSpan={5}
      //           >
      //             Realisasi Anggaran (dalam Juta)
      //           </th>
      //         </tr>

      //         <tr>
      //           <th />
      //           <th />
      //           <th />
      //           {/* <th /> */}
      //           {headerYears.map((year) => (
      //             <th
      //               className="text-center"
      //               key={year}
      //               align="center"
      //               style={{
      //                 backgroundColor: blueWheelColors[3],
      //                 color: "#ffffff",
      //                 borderWidth: 0,
      //                 paddingLeft: year === 18 ? 560 : 0,
      //               }}
      //             >
      //               {year}
      //             </th>
      //           ))}
      //           {headerFunds.map((fund) => (
      //             <th
      //               className="text-center"
      //               key={fund}
      //               align="center"
      //               style={{
      //                 backgroundColor: blueWheelColors[2],
      //                 color: "#ffffff",
      //                 borderWidth: 0,
      //               }}
      //             >
      //               {fund}
      //             </th>
      //           ))}
      //           {headerFunds.map((fund) => (
      //             <th
      //               className="text-center"
      //               key={fund}
      //               align="center"
      //               style={{
      //                 backgroundColor: blueWheelColors[3],
      //                 color: "#ffffff",
      //                 borderWidth: 0,
      //               }}
      //             >
      //               {fund}
      //             </th>
      //           ))}
      //         </tr>
      //       </thead>

      //       <tbody>
      //         {detail.map((pilar) => (
      //           <Fragment key={pilar._id}>
      //             <tr
      //               style={{
      //                 backgroundColor: blueWheelColors[5],
      //                 color: "#ffffff",

      //                 position: "absolute",
      //                 display: "inline-block",
      //                 border: 0,
      //                 marginRight: 22,
      //               }}
      //             >
      //               <td align="center" style={{ width: 40 }}>
      //                 {keyword && (pilar?.pilarNumber || "")}
      //               </td>
      //               <td
      //                 colSpan={25}
      //                 style={{
      //                   overflow: "hidden",
      //                   textOverflow: "ellipsis",
      //                   whiteSpace: "nowrap",
      //                   // width: 560,
      //                   width: "100%",
      //                 }}
      //               >
      //                 {pilar.name}
      //               </td>
      //             </tr>
      //             {pilar.strategies
      //               .filter((strategy) => {
      //                 let filterResult = false;
      //                 for (let i = 0; i < strategy?.programs?.length; i++) {
      //                   for (
      //                     let j = 0;
      //                     j < strategy?.programs[i]?.activities?.length;
      //                     j++
      //                   ) {
      //                     if (
      //                       strategy?.programs[i]?.activities[j]?.activityDetail
      //                         ?.length
      //                     ) {
      //                       filterResult = true;
      //                       break;
      //                     }
      //                   }
      //                 }
      //                 return filterResult;
      //               })
      //               .map((strategy, strategyIndex) => (
      //                 <Fragment key={strategy._id}>
      //                   {strategyIndex === 0 && <tr style={{ height: 45 }} />}
      //                   {/* <tr style={{ height: 25 }} /> */}
      //                   {/* {strategyIndex === 0 ? <tr style={{ height: 45 }} /> : <tr style={{ height: 25 }} />} */}
      //                   <tr style={{ height: 45 }}>
      //                     <td
      //                       align="center"
      //                       style={{
      //                         position: "absolute",
      //                         display: "inline-block",
      //                         height: 92,
      //                         border: 0,
      //                         width: 40,
      //                       }}
      //                     >
      //                       {strategyIndex + 1}.
      //                     </td>
      //                     <td
      //                       colSpan={3}
      //                       style={{
      //                         overflow: "hidden",
      //                         textOverflow: "ellipsis",
      //                         whiteSpace: "nowrap",
      //                         width: 560,
      //                         marginLeft: 40,
      //                         position: "absolute",
      //                         display: "inline-block",
      //                         height: 92,
      //                         border: 0,
      //                       }}
      //                     >
      //                       {strategy.name}
      //                     </td>
      //                     <td colSpan={22} />
      //                   </tr>
      //                   {strategy.programs
      //                     .filter((program) => {
      //                       let result = false;
      //                       for (
      //                         let i = 0;
      //                         i < program?.activities?.length;
      //                         i++
      //                       ) {
      //                         if (
      //                           program?.activities?.[i]?.activityDetail?.length
      //                         ) {
      //                           result = true;
      //                           break;
      //                         }
      //                       }
      //                       return result;
      //                     })
      //                     .map((program, programIndex) => {
      //                       const ProgramList = () => {
      //                         const [isOpenCollapse, setIsOpenCollapse] =
      //                           useState(
      //                             Boolean(keyword?.length) ||
      //                               Boolean(
      //                                 strategyIndex === 0 && programIndex === 0
      //                               )
      //                           );
      //                         // useState(Boolean(keyword?.length) || false);
      //                         // useState(true);
      //                         return (
      //                           <Fragment key={program._id}>
      //                             {/* {programIndex === 0 && (
      //                               <tr style={{ height: 20 }} />
      //                             )} */}
      //                             {/* <tr style={{ height: 20 }} /> */}
      //                             <tr
      //                               onClick={() =>
      //                                 setIsOpenCollapse(!isOpenCollapse)
      //                               }
      //                               style={{
      //                                 cursor: "pointer",
      //                                 height: 45,
      //                               }}
      //                             >
      //                               <td />
      //                               {/* <td /> */}
      //                               <td
      //                                 align="center"
      //                                 style={{
      //                                   position: "absolute",
      //                                   display: "inline-block",
      //                                   height: 92,
      //                                   width: 45,
      //                                 }}
      //                               >
      //                                 <div>
      //                                   <i
      //                                     className={
      //                                       isOpenCollapse
      //                                         ? "ion ion-md-arrow-dropdown"
      //                                         : "ion ion-md-arrow-dropright"
      //                                     }
      //                                     style={{
      //                                       fontSize: 30,
      //                                       color: color.primary,
      //                                       marginTop: -5,
      //                                     }}
      //                                   />
      //                                 </div>
      //                               </td>
      //                               <td
      //                                 align="center"
      //                                 style={{
      //                                   position: "absolute",
      //                                   display: "inline-block",
      //                                   height: 92,
      //                                   width: 45,
      //                                   paddingLeft: 40,
      //                                 }}
      //                               >
      //                                 {String.fromCharCode(programIndex + 65)}.
      //                               </td>
      //                               <td
      //                                 colSpan={2}
      //                                 style={{
      //                                   overflow: "hidden",
      //                                   textOverflow: "ellipsis",
      //                                   whiteSpace: "nowrap",
      //                                   maxWidth: 480,

      //                                   position: "absolute",
      //                                   display: "inline-block",
      //                                   height: 92,
      //                                   marginLeft: 55,
      //                                 }}
      //                               >
      //                                 {program.name}
      //                               </td>
      //                               <td colSpan={22} />
      //                             </tr>
      //                             {program.activities
      //                               .filter(
      //                                 (activity) =>
      //                                   activity?.activityDetail?.length
      //                               )
      //                               .map((activity, activityIndex) => {
      //                                 let totalBudgetPlaning = 0;
      //                                 let totalBudgetRealization = 0;
      //                                 const status =
      //                                   activity?.activityDetail?.[0]?.status
      //                                     ?.status;
      //                                 return (
      //                                   isOpenCollapse && (
      //                                     <Fragment key={activity._id}>
      //                                       {/* {activityIndex === 0 && (
      //                                         <tr style={{ height: 20 }} />
      //                                       )} */}
      //                                       {activity?.activityDetail?.length >
      //                                         0 && (
      //                                         <tr>
      //                                           <td
      //                                             style={{
      //                                               verticalAlign: "middle",
      //                                               position: "absolute",
      //                                               display: "inline-block",
      //                                               height: 92,
      //                                               width: 80,
      //                                               // marginLeft: 20,
      //                                               backgroundColor: "white",
      //                                             }}
      //                                           />
      //                                           <td
      //                                             style={{
      //                                               verticalAlign: "middle",
      //                                               position: "absolute",
      //                                               display: "inline-block",
      //                                               height: 92,
      //                                               marginLeft: 20,
      //                                               backgroundColor: "white",
      //                                               paddingLeft: 35,
      //                                             }}
      //                                           >
      //                                             <div
      //                                               style={{ marginTop: -3 }}
      //                                             >
      //                                               {filterInChargeValue ||
      //                                               user?.role === "admin" ? (
      //                                                 <i
      //                                                   className="mdi mdi-bookmark mdi-24px"
      //                                                   style={{
      //                                                     color:
      //                                                       activityColors[
      //                                                         status ===
      //                                                         "Pelaksanaan"
      //                                                           ? 1
      //                                                           : status ===
      //                                                             "Selesai"
      //                                                           ? 2
      //                                                           : 0
      //                                                       ],
      //                                                   }}
      //                                                 />
      //                                               ) : (
      //                                                 ""
      //                                               )}
      //                                             </div>
      //                                           </td>
      //                                           <td
      //                                             style={{
      //                                               verticalAlign: "middle",
      //                                               width: 40,
      //                                               position: "absolute",
      //                                               display: "inline-block",
      //                                               height: 92,
      //                                               marginLeft: 80,
      //                                               backgroundColor: "white",
      //                                               paddingTop: 15,
      //                                             }}
      //                                           >
      //                                             {activityIndex + 1}.
      //                                           </td>
      //                                           <td
      //                                             onClick={
      //                                               filterInChargeValue ||
      //                                               user?.role === "admin"
      //                                                 ? togglePreviewDetail(
      //                                                     activity
      //                                                       .activityDetail?.[0]
      //                                                       ?._id
      //                                                   )
      //                                                 : toggleInChargeList(
      //                                                     activity,
      //                                                     activityIndex + 1
      //                                                   )
      //                                             }
      //                                             style={{
      //                                               overflow: "hidden",
      //                                               textOverflow: "ellipsis",
      //                                               whiteSpace: "nowrap",
      //                                               width: 480,
      //                                               verticalAlign: "middle",
      //                                               cursor: "pointer",
      //                                               position: "absolute",
      //                                               display: "inline-block",
      //                                               height: 92,
      //                                               marginLeft: 120,
      //                                               backgroundColor: "white",
      //                                               paddingTop: 15,
      //                                             }}
      //                                           >
      //                                             {activity.name}
      //                                           </td>
      //                                           <td />
      //                                           <td />
      //                                           {bodyYears.map((year) => {
      //                                             const inChargesFiltered =
      //                                               activity.activityDetail.filter(
      //                                                 ({ implementations }) =>
      //                                                   implementations.find(
      //                                                     (val) =>
      //                                                       val.year === year
      //                                                   )
      //                                               );
      //                                             let implementationId = null;
      //                                             let isLock = true;
      //                                             if (
      //                                               filterInChargeValue ||
      //                                               user?.role === "admin"
      //                                             ) {
      //                                               inChargesFiltered?.[0]?.implementations.forEach(
      //                                                 (val) => {
      //                                                   if (val.year === year) {
      //                                                     implementationId =
      //                                                       val._id;
      //                                                     if (
      //                                                       val.isLock ===
      //                                                         false &&
      //                                                       isLock === true
      //                                                     ) {
      //                                                       isLock = false;
      //                                                     }
      //                                                   }
      //                                                 }
      //                                               );
      //                                             }
      //                                             const inChargesTotal =
      //                                               inChargesFiltered.length;
      //                                             return (
      //                                               <td
      //                                                 key={year}
      //                                                 style={{
      //                                                   verticalAlign: "middle",
      //                                                 }}
      //                                               >
      //                                                 <div
      //                                                   style={{
      //                                                     width: 28,
      //                                                     height: 28,
      //                                                     borderRadius: 4,
      //                                                     display: "flex",
      //                                                     justifyContent:
      //                                                       "center",
      //                                                     alignItems: "center",
      //                                                     paddingTop: 1.5,
      //                                                     color: "white",
      //                                                     marginLeft:
      //                                                       year === 2018
      //                                                         ? 560
      //                                                         : 0,
      //                                                     backgroundColor:
      //                                                       inChargesTotal
      //                                                         ? blueWheelColors[5]
      //                                                         : "#f6f6f6",
      //                                                     cursor:
      //                                                       filterInChargeValue &&
      //                                                       Boolean(
      //                                                         inChargesTotal
      //                                                       )
      //                                                         ? "pointer"
      //                                                         : "default",
      //                                                   }}
      //                                                   onClick={onToggleYear(
      //                                                     implementationId,
      //                                                     !isLock,
      //                                                     {
      //                                                       year,
      //                                                       activityName:
      //                                                         activity.name,
      //                                                       inChargeName:
      //                                                         filterInChargeValue?.label,
      //                                                     }
      //                                                   )}
      //                                                 >
      //                                                   {filterInChargeValue ||
      //                                                   user?.role === "admin"
      //                                                     ? Boolean(
      //                                                         inChargesTotal
      //                                                       ) && (
      //                                                         <i
      //                                                           className={`fas fa-lock${
      //                                                             isLock
      //                                                               ? ""
      //                                                               : "-open"
      //                                                           }`}
      //                                                         />
      //                                                       )
      //                                                     : inChargesTotal
      //                                                     ? inChargesTotal
      //                                                     : ""}
      //                                                 </div>
      //                                               </td>
      //                                             );
      //                                           })}
      //                                           {bodyFunds.map((fund) => {
      //                                             let budgedPlaning = 0;
      //                                             const imp =
      //                                               activity.activityDetail.map(
      //                                                 ({ implementations }) =>
      //                                                   implementations
      //                                               );
      //                                             imp.forEach(
      //                                               (yearProgressList) => {
      //                                                 yearProgressList.forEach(
      //                                                   (yearProgress) => {
      //                                                     yearProgress.progress.forEach(
      //                                                       (progress) => {
      //                                                         progress.budgets.forEach(
      //                                                           (budged) => {
      //                                                             if (
      //                                                               budged.name ===
      //                                                               fund
      //                                                             )
      //                                                               budgedPlaning +=
      //                                                                 budged.planing;
      //                                                           }
      //                                                         );
      //                                                       }
      //                                                     );
      //                                                   }
      //                                                 );
      //                                               }
      //                                             );
      //                                             totalBudgetPlaning +=
      //                                               budgedPlaning;
      //                                             return (
      //                                               <td
      //                                                 key={fund}
      //                                                 style={{
      //                                                   verticalAlign: "middle",
      //                                                 }}
      //                                                 align="center"
      //                                               >
      //                                                 {formatCurrency(
      //                                                   budgedPlaning
      //                                                 )}
      //                                               </td>
      //                                             );
      //                                           })}
      //                                           <td
      //                                             style={{
      //                                               verticalAlign: "middle",
      //                                             }}
      //                                             align="center"
      //                                           >
      //                                             {formatCurrency(
      //                                               totalBudgetPlaning
      //                                             )}
      //                                           </td>
      //                                           {bodyFunds.map((fund) => {
      //                                             let budgedRealization = 0;
      //                                             const imp =
      //                                               activity.activityDetail.map(
      //                                                 ({ implementations }) =>
      //                                                   implementations
      //                                               );
      //                                             imp.forEach(
      //                                               (yearProgressList) => {
      //                                                 yearProgressList.forEach(
      //                                                   (yearProgress) => {
      //                                                     yearProgress.progress.forEach(
      //                                                       (progress) => {
      //                                                         progress.budgets.forEach(
      //                                                           (budged) => {
      //                                                             if (
      //                                                               budged.name ===
      //                                                               fund
      //                                                             )
      //                                                               budgedRealization +=
      //                                                                 budged.realization;
      //                                                           }
      //                                                         );
      //                                                       }
      //                                                     );
      //                                                   }
      //                                                 );
      //                                               }
      //                                             );
      //                                             totalBudgetRealization +=
      //                                               budgedRealization;
      //                                             return (
      //                                               <td
      //                                                 key={fund}
      //                                                 style={{
      //                                                   verticalAlign: "middle",
      //                                                 }}
      //                                                 align="center"
      //                                               >
      //                                                 {formatCurrency(
      //                                                   budgedRealization
      //                                                 )}
      //                                               </td>
      //                                             );
      //                                           })}
      //                                           <td
      //                                             style={{
      //                                               verticalAlign: "middle",
      //                                             }}
      //                                             align="center"
      //                                           >
      //                                             {formatCurrency(
      //                                               totalBudgetRealization
      //                                             )}
      //                                           </td>
      //                                         </tr>
      //                                       )}
      //                                     </Fragment>
      //                                   )
      //                                 );
      //                               })}
      //                           </Fragment>
      //                         );
      //                       };
      //                       return <ProgramList />;
      //                     })}
      //                 </Fragment>
      //               ))}
      //           </Fragment>
      //         ))}
      //       </tbody>
      //     </table>
      //   </div>
      // </>
    );
  };

  const ModalInChargeList = () => {
    const headerYears = [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
    const headerFunds = ["APBN", "APBD", "Swasta", "Lainnya", "Total"];
    const bodyYears = [
      2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029,
    ];
    const bodyFunds = ["APBN", "APBD", "Swasta", "Lainnya"];

    return (
      <Modal
        fade={false}
        className="modal-xl"
        isOpen={isOpenInChargeList}
        toggle={toggleInChargeList(null)}
        centered
      >
        <div className="modal-header">
          <button
            onClick={toggleInChargeList(null)}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="card card-custom gutter-b card-stretch">
            <div
              style={{ overflow: "auto", maxHeight: "75vh", height: "100%" }}
            >
              <Table
                hover
                style={{ borderCollapse: "collapse", width: "100%" }}
              >
                <thead
                  className="text-center"
                  style={{
                    position: "sticky",
                    top: 0,
                    backgroundColor: "white",
                    zIndex: 100,
                  }}
                >
                  <tr>
                    <th
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: blueWheelColors[3],
                        color: "#ffffff",
                        verticalAlign: "middle",
                        borderTopLeftRadius: 6,
                        border: "none",
                      }}
                      rowSpan={2}
                    >
                      No.
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        left: 46,
                        minWidth: 300,
                        backgroundColor: blueWheelColors[2],
                        color: "#ffffff",
                        verticalAlign: "middle",
                        border: "none",
                      }}
                      rowSpan={2}
                      colSpan={3}
                    >
                      Pilar - Strategi - Program - Kegiatan
                    </th>
                    <th
                      style={{
                        backgroundColor: blueWheelColors[3],
                        color: "#ffffff",
                        border: "none",
                      }}
                      colSpan={12}
                    >
                      Tahun (dalam 2000)
                    </th>
                    <th
                      style={{
                        backgroundColor: blueWheelColors[2],
                        color: "#ffffff",
                        border: "none",
                      }}
                      colSpan={5}
                    >
                      Rencana Anggaran (dalam Juta){" "}
                    </th>
                    <th
                      style={{
                        backgroundColor: blueWheelColors[3],
                        color: "#ffffff",
                        borderTopRightRadius: 7,
                        border: "none",
                      }}
                      colSpan={5}
                    >
                      Realisasi Anggaran (dalam Juta)
                    </th>
                  </tr>
                  <tr>
                    {headerYears.map((year) => (
                      <th
                        style={{
                          backgroundColor: blueWheelColors[3],
                          color: "#ffffff",
                          border: "none",
                        }}
                      >
                        {year}
                      </th>
                    ))}
                    {headerFunds.map((fund) => (
                      <th
                        style={{
                          backgroundColor: blueWheelColors[2],
                          color: "#ffffff",
                          border: "none",
                        }}
                      >
                        {fund}
                      </th>
                    ))}
                    {headerFunds.map((fund) => (
                      <th
                        style={{
                          backgroundColor: blueWheelColors[3],
                          color: "#ffffff",
                          border: "none",
                        }}
                      >
                        {fund}
                      </th>
                    ))}
                  </tr>
                  <tr>
                    <th
                      style={{
                        position: "sticky",
                        left: 0,
                        backgroundColor: blueWheelColors[5],
                        color: "white",
                        border: "none",
                      }}
                    ></th>
                    <th
                      style={{
                        position: "sticky",
                        left: 46,
                        backgroundColor: blueWheelColors[5],
                        color: "white",
                        border: "none",
                        zIndex: 100,
                      }}
                      className="text-left"
                      colSpan={15}
                    >
                      {inChargeList?.title}
                    </th>
                    <th
                      style={{
                        position: "sticky",
                        left: 46,
                        backgroundColor: blueWheelColors[5],
                        color: "white",
                        border: "none",
                      }}
                      className="text-left"
                      colSpan={10}
                    ></th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr
                    style={
                      {
                        backgroundColor: blueWheelColors[5],
                        color: "#ffffff",
                        position: "sticky",
                        top: 90,
                        zIndex: 100,
                        left: 0,
                      }
                    }
                  >
                    <td
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        border: "none",
                        // backgroundColor: blueWheelColors[5],
                        // color: "#ffffff",
                        // position: "sticky",
                        // top: 90,
                        // zIndex: 100,
                        // left: 0,
                      }}
                      align="center"
                    />
                    <td
                      colSpan={25}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        border: "none",
                        // backgroundColor: blueWheelColors[5],
                        // color: "#ffffff",
                        // position: "sticky",
                        // top: 90,
                        // zIndex: 100,
                        // left: 100,
                        position: "sticky",
                        top: 90,
                        zIndex: 100,
                        left: 0,
                      }}
                    >
                      {inChargeList?.title}
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      style={{
                        backgroundColor: blueWheelColors[5],
                        color: "#ffffff",
                        position: "sticky",
                        left: 0,
                      }}
                      colSpan={26}
                    >
                      {inChargeList?.title}
                    </td>
                  </tr> */}
                  {/* <tr>
                    <td
                      // align="center"
                      style={{
                        verticalAlign: "middle",
                        position: "relative",
                        left: 0,
                        backgroundColor: "white",
                      }}
                      colSpan={25}
                    >
                      {inChargeList?.title}
                    </td>
                  </tr> */}
                  {inChargeList?.activityDetail?.map(
                    (inCharge, inChargeIndex) => {
                      let totalBudgetPlaning = 0;
                      let totalBudgetRealization = 0;
                      return (
                        <tr key={inCharge._id}>
                          <td
                            align="center"
                            style={{
                              verticalAlign: "middle",
                              position: "sticky",
                              left: 0,
                              backgroundColor: "white",
                            }}
                          >
                            {inChargeIndex + 1}.
                          </td>
                          <td
                            align="center"
                            style={{
                              verticalAlign: "middle",
                              width: 10,
                              position: "sticky",
                              left: 46,
                              backgroundColor: "white",
                            }}
                          >
                            <i
                              className="mdi mdi-bookmark mdi-24px"
                              style={{
                                color:
                                  activityColors[
                                    inCharge?.status?.status === "Pelaksanaan"
                                      ? 1
                                      : inCharge?.status?.status === "Selesai"
                                      ? 2
                                      : 0
                                  ],
                              }}
                            />
                          </td>
                          <td
                            align="left"
                            style={{
                              verticalAlign: "middle",
                              cursor: "pointer",
                              position: "sticky",
                              left: 94,
                              backgroundColor: "white",
                            }}
                            onClick={togglePreviewDetail(inCharge._id)}
                            colSpan={2}
                          >
                            {inCharge?.inCharge?.name}
                          </td>
                          {bodyYears.map((year) => {
                            let implementationId = null;
                            let isLock = true;
                            inCharge.implementations.forEach((val) => {
                              if (val.year === year) {
                                implementationId = val._id;
                                if (val.isLock === false && isLock === true) {
                                  isLock = false;
                                }
                              }
                            });
                            const isImplemented = inCharge.implementations.find(
                              (val) => val.year === year
                            );
                            return (
                              <td
                                align="center"
                                key={year}
                                style={{ verticalAlign: "middle" }}
                              >
                                <div
                                  style={{
                                    width: 28,
                                    height: 28,
                                    borderRadius: 4,
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    paddingTop: 1.5,
                                    color: "white",
                                    backgroundColor: isImplemented
                                      ? blueWheelColors[5]
                                      : "#f6f6f6",
                                    cursor: isImplemented
                                      ? "pointer"
                                      : "default",
                                  }}
                                  onClick={onToggleYear(
                                    implementationId,
                                    !isLock,
                                    {
                                      year,
                                      activityName: inChargeList?.title,
                                      inChargeName: inCharge?.inCharge?.name,
                                    }
                                  )}
                                >
                                  {isImplemented && (
                                    <i
                                      className={`fas fa-lock${
                                        isLock ? "" : "-open"
                                      }`}
                                    />
                                  )}
                                </div>
                              </td>
                            );
                          })}
                          {bodyFunds.map((fund) => {
                            let budgedPlaning = 0;
                            const { implementations } = inCharge;
                            implementations.forEach((yearProgress) => {
                              yearProgress.progress.forEach((progress) => {
                                progress.budgets.forEach((budged) => {
                                  if (budged.name === fund)
                                    budgedPlaning += budged.planing;
                                });
                              });
                            });
                            totalBudgetPlaning += budgedPlaning;
                            return (
                              <td
                                key={fund}
                                style={{ verticalAlign: "middle" }}
                                align="center"
                              >
                                {formatCurrency(budgedPlaning)}
                              </td>
                            );
                          })}
                          <td
                            style={{ verticalAlign: "middle" }}
                            align="center"
                          >
                            {formatCurrency(totalBudgetPlaning)}
                          </td>
                          {bodyFunds.map((fund) => {
                            let budgedRealization = 0;
                            const { implementations } = inCharge;
                            implementations.forEach((yearProgress) => {
                              yearProgress.progress.forEach((progress) => {
                                progress.budgets.forEach((budged) => {
                                  if (budged.name === fund)
                                    budgedRealization += budged.realization;
                                });
                              });
                            });
                            totalBudgetRealization += budgedRealization;
                            return (
                              <td
                                key={fund}
                                style={{ verticalAlign: "middle" }}
                                align="center"
                              >
                                {formatCurrency(budgedRealization)}
                              </td>
                            );
                          })}
                          <td
                            style={{ verticalAlign: "middle" }}
                            align="center"
                          >
                            {formatCurrency(totalBudgetRealization)}
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </Table>
            </div>
          </div>
          {/* <div
            style={{
              marginRight: -17,
              marginLeft: -17,
              overflow: "auto",
              maxHeight: "70vh",
            }}
            className="mt-4"
          >
            <table
              className="table card-shadow table-hover"
              style={{
                overflow: "hidden",
                marginLeft: 22,
              }}
            >
              <thead>
                <tr>
                  <th
                    className="text-center"
                    align="center"
                    style={{
                      backgroundColor: blueWheelColors[3],
                      color: "#ffffff",
                      verticalAlign: "middle",
                    }}
                    rowSpan={2}
                  >
                    No.
                  </th>
                  <th
                    className="text-center"
                    align="center"
                    style={{
                      backgroundColor: blueWheelColors[2],
                      color: "#ffffff",
                      verticalAlign: "middle",
                      // width: "444px",
                      minWidth: 500,
                    }}
                    rowSpan={2}
                    colSpan={2}
                  >
                    Detail Penanggung Jawab
                  </th>
                  <th
                    className="text-center"
                    align="center"
                    style={{
                      backgroundColor: blueWheelColors[3],
                      color: "#ffffff",
                    }}
                    colSpan={12}
                  >
                    Tahun (dalam 2000)
                  </th>
                  <th
                    className="text-center"
                    align="center"
                    style={{
                      backgroundColor: blueWheelColors[2],
                      color: "#ffffff",
                    }}
                    colSpan={5}
                  >
                    Rencana Anggaran (dalam Juta)
                  </th>
                  <th
                    className="text-center"
                    align="center"
                    style={{
                      backgroundColor: blueWheelColors[3],
                      color: "#ffffff",
                    }}
                    colSpan={5}
                  >
                    Realisasi Anggaran (dalam Juta)
                  </th>
                </tr>

                <tr>
                  {headerYears.map((year) => (
                    <th
                      className="text-center"
                      key={year}
                      align="center"
                      style={{
                        backgroundColor: blueWheelColors[3],
                        color: "#ffffff",
                        borderWidth: 0,
                      }}
                    >
                      {year}
                    </th>
                  ))}
                  {headerFunds.map((fund) => (
                    <th
                      className="text-center"
                      key={fund}
                      align="center"
                      style={{
                        backgroundColor: blueWheelColors[2],
                        color: "#ffffff",
                        borderWidth: 0,
                      }}
                    >
                      {fund}
                    </th>
                  ))}
                  {headerFunds.map((fund) => (
                    <th
                      className="text-center"
                      key={fund}
                      align="center"
                      style={{
                        backgroundColor: blueWheelColors[3],
                        color: "#ffffff",
                        borderWidth: 0,
                      }}
                    >
                      {fund}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr
                  style={{
                    backgroundColor: blueWheelColors[5],
                    color: "#ffffff",
                  }}
                >
                  <td align="center" />
                  <td
                    colSpan={24}
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      // maxWidth: 500,
                    }}
                  >
                    {inChargeList?.title}
                  </td>
                </tr>
                {inChargeList?.activityDetail?.map(
                  (inCharge, inChargeIndex) => {
                    let totalBudgetPlaning = 0;
                    let totalBudgetRealization = 0;
                    return (
                      <tr key={inCharge._id}>
                        <td align="center" style={{ verticalAlign: "middle" }}>
                          {inChargeIndex + 1}.
                        </td>
                        <td
                          align="center"
                          style={{ verticalAlign: "middle", width: 10 }}
                        >
                          <i
                            className="mdi mdi-bookmark mdi-24px"
                            style={{
                              color:
                                activityColors[
                                  inCharge?.status?.status === "Pelaksanaan"
                                    ? 1
                                    : inCharge?.status?.status === "Selesai"
                                    ? 2
                                    : 0
                                ],
                            }}
                          />
                        </td>
                        <td
                          align="left"
                          style={{ verticalAlign: "middle", cursor: "pointer" }}
                          onClick={togglePreviewDetail(inCharge._id)}
                        >
                          {inCharge?.inCharge?.name}
                        </td>
                        {bodyYears.map((year) => {
                          let implementationId = null;
                          let isLock = true;
                          inCharge.implementations.forEach((val) => {
                            if (val.year === year) {
                              implementationId = val._id;
                              if (val.isLock === false && isLock === true) {
                                isLock = false;
                              }
                            }
                          });
                          const isImplemented = inCharge.implementations.find(
                            (val) => val.year === year
                          );
                          return (
                            <td
                              align="center"
                              key={year}
                              style={{ verticalAlign: "middle" }}
                            >
                              <div
                                style={{
                                  width: 28,
                                  height: 28,
                                  borderRadius: 4,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  paddingTop: 1.5,
                                  color: "white",
                                  backgroundColor: isImplemented
                                    ? blueWheelColors[5]
                                    : "#f6f6f6",
                                  cursor: isImplemented ? "pointer" : "default",
                                }}
                                onClick={onToggleYear(
                                  implementationId,
                                  !isLock,
                                  {
                                    year,
                                    activityName: inChargeList?.title,
                                    inChargeName: inCharge?.inCharge?.name,
                                  }
                                )}
                              >
                                {isImplemented && (
                                  <i
                                    className={`fas fa-lock${
                                      isLock ? "" : "-open"
                                    }`}
                                  />
                                )}
                              </div>
                            </td>
                          );
                        })}
                        {bodyFunds.map((fund) => {
                          let budgedPlaning = 0;
                          const { implementations } = inCharge;
                          implementations.forEach((yearProgress) => {
                            yearProgress.progress.forEach((progress) => {
                              progress.budgets.forEach((budged) => {
                                if (budged.name === fund)
                                  budgedPlaning += budged.planing;
                              });
                            });
                          });
                          totalBudgetPlaning += budgedPlaning;
                          return (
                            <td
                              key={fund}
                              style={{ verticalAlign: "middle" }}
                              align="center"
                            >
                              {formatCurrency(budgedPlaning)}
                            </td>
                          );
                        })}
                        <td style={{ verticalAlign: "middle" }} align="center">
                          {formatCurrency(totalBudgetPlaning)}
                        </td>
                        {bodyFunds.map((fund) => {
                          let budgedRealization = 0;
                          const { implementations } = inCharge;
                          implementations.forEach((yearProgress) => {
                            yearProgress.progress.forEach((progress) => {
                              progress.budgets.forEach((budged) => {
                                if (budged.name === fund)
                                  budgedRealization += budged.realization;
                              });
                            });
                          });
                          totalBudgetRealization += budgedRealization;
                          return (
                            <td
                              key={fund}
                              style={{ verticalAlign: "middle" }}
                              align="center"
                            >
                              {formatCurrency(budgedRealization)}
                            </td>
                          );
                        })}
                        <td style={{ verticalAlign: "middle" }} align="center">
                          {formatCurrency(totalBudgetRealization)}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div> */}

          <div className="d-flex flex-wrap">
            <div className="d-flex align-items-center mr-2">
              <p className="m-0">Status Kegiatan :</p>
            </div>
            {["Perencanaan", "Pelaksanaan", "Selesai"].map((title, idx) => (
              <div
                key={idx}
                className={`d-flex align-items-center ${idx < 3 && "mr-4"}`}
              >
                <i
                  className="mdi mdi-bookmark mdi-24px mr-2"
                  style={{ color: activityColors[idx] }}
                />
                <p className="m-0">{title}</p>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    );
  };

  const ModalPreview = () => {
    const [isOpenDownload, setIsOpenDownload] = useState(false);
    const [documents, setDocuments] = useState([]);

    const toggleDownload = (documents) => () => {
      // console.log(documents);
      if (documents) {
        setDocuments(documents);
        setIsOpenDownload(true);
      } else {
        setDocuments([]);
        setIsOpenDownload(false);
      }
    };

    const onDeleteStatus = (_id) => () => {
      dispatch(
        deleteActivityStatusRequest(_id, (err, resp) => {
          if (err) {
            // alert
          } else {
            getAndSetPreviewDetail(previewData._id);
            onRefreshDetail();
          }
        })
      );
    };

    return (
      <Modal
        className="modal-xl"
        isOpen={isOpenPreview}
        toggle={togglePreviewDetail(null)}
        fade={false}
      >
        <Tooltip />
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Preview Status Kegiatan
          </h5>
          <div>
            {user?.role === "admin" && (
              <>
                <button
                  onClick={toggleForm(false)}
                  className="btn mr-2"
                  style={{
                    backgroundColor: color.success,
                    color: "white",
                    height: 42,
                    width: 150,
                  }}
                >
                  Tambah
                </button>
              </>
            )}
            <button
              onClick={togglePreviewDetail(null)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          {isLoadingPreview ? (
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                height: "80vh",
                background: "white",
                zIndex: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spinner size="xl" />
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-4 mb-5 border-right">
                <p className="text-bold">Penanggung Jawab</p>
                <p>{previewData?.inCharge?.name}</p>

                <p className="text-bold mt-4">Pilar 1</p>
                <p>{previewData?.pilar?.name}</p>

                <p className="text-bold mt-4">Strategi</p>
                <p>{previewData?.strategy?.name}</p>

                <p className="text-bold mt-4">Program</p>
                <p>{previewData?.program?.name}</p>

                <p className="text-bold mt-4">Kegiatan</p>
                <p>{previewData?.activity?.name}</p>
              </div>

              <div className="col-lg-4 mb-5 border-left border-right">
                {previewData?.implementations.filter(
                  (val) => val.progress.length
                ).length === 0 && (
                  <p className="text-bold">Belum ada progress kegiatan</p>
                )}
                {previewData?.implementations
                  .filter((val) => val.progress.length)
                  .sort((a, b) => b.year - a.year)
                  .map((yearImplementation, idx, yearImplementationRow) => {
                    const ProgressList = () => {
                      const [isOpenCollapse, setIsOpenCollapse] =
                        useState(false);
                      return (
                        <div key={idx}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            className={`${
                              isOpenCollapse ? "none" : "border-bottom"
                            }`}
                          >
                            <p className="text-bold">
                              {`Tahun ${yearImplementation.year}`}
                              <i
                                className={`ml-2 fas fa-lock${
                                  yearImplementation?.isLock === false
                                    ? "-open"
                                    : ""
                                }`}
                              />
                            </p>

                            <i
                              className={
                                isOpenCollapse
                                  ? "ion ion-md-arrow-dropdown"
                                  : "ion ion-md-arrow-dropright"
                              }
                              style={{
                                fontSize: 30,
                                color: color.primary,
                                cursor: "pointer",
                                padding: "10px 10px",
                              }}
                              onClick={() => setIsOpenCollapse(!isOpenCollapse)}
                            />
                          </div>
                          {yearImplementation.progress
                            .sort(
                              (a, b) =>
                                new Date(b.createdAt) - new Date(a.createdAt)
                            )
                            .filter(
                              (a) =>
                                a.status === "Perencanaan" ||
                                a.status === "Pelaksanaan" ||
                                a.status === "Selesai"
                            )
                            .map((progress, progressIndex, progressRow) => {
                              const { status } = progress;
                              const calculateBudged = {
                                APBN: { plan: 0, real: 0 },
                                APBD: { plan: 0, real: 0 },
                                Swasta: { plan: 0, real: 0 },
                                Lainnya: { plan: 0, real: 0 },
                                Total: { plan: 0, real: 0 },
                              };
                              progress?.budgets.forEach((budged) => {
                                const { name, planing, realization } = budged;
                                calculateBudged[name].plan += planing;
                                calculateBudged[name].real += realization;
                                calculateBudged.Total.plan += planing;
                                calculateBudged.Total.real += realization;
                              });
                              return (
                                <Collapse isOpen={isOpenCollapse}>
                                  <div key={progressIndex} className="">
                                    <div className="d-flex justify-content-between align-items-center border-top border-bottom pt-1 pb-1">
                                      <div className="d-flex align-items-center">
                                        <i
                                          className="mdi mdi-bookmark mdi-24px mr-2 mt-1 mb-1"
                                          style={{
                                            color:
                                              activityColors[
                                                status === "Pelaksanaan"
                                                  ? 1
                                                  : status === "Selesai"
                                                  ? 2
                                                  : 0
                                              ],
                                          }}
                                        />
                                        <p>{progress.status}</p>
                                      </div>
                                      {user?.role === "admin" && (
                                        <div
                                          data-tip={
                                            yearImplementation?.isLock === true
                                              ? "Tahun ini telah dikunci, jika ingin melakukan perubahan data silakan hubungi admin BPTJ"
                                              : ""
                                          }
                                          data-class="kpi-tooltip"
                                        >
                                          {(idx + 1 !==
                                            yearImplementationRow.length ||
                                            progressIndex + 1 !==
                                              progressRow.length) && (
                                            <Popconfirm
                                              title={
                                                "Ingin menghapus progress ini?"
                                              }
                                              onConfirm={onDeleteStatus(
                                                progress?._id
                                              )}
                                              okText="Hapus"
                                              cancelText="Batal"
                                              okButtonProps={{
                                                danger: true,
                                                icon: (
                                                  <i className="far fa-trash-alt mr-1" />
                                                ),
                                                disabled:
                                                  yearImplementation.isLock,
                                              }}
                                            >
                                              <button
                                                className="btn mr-2"
                                                style={{
                                                  color: "white",
                                                  backgroundColor: color.error,
                                                  height: 36,
                                                  width: 36,
                                                  padding: 0,
                                                }}
                                              >
                                                <i className="far fa-trash-alt" />
                                              </button>
                                            </Popconfirm>
                                          )}

                                          <button
                                            className="btn"
                                            style={{
                                              color: "white",
                                              backgroundColor: color.primary,
                                              height: 36,
                                              width: 36,
                                              padding: 0,
                                            }}
                                            onClick={toggleForm({
                                              ...progress,
                                              year: yearImplementation.year,
                                            })}
                                            disabled={yearImplementation.isLock}
                                          >
                                            <i className="fas fa-edit" />
                                          </button>
                                        </div>
                                      )}
                                    </div>
                                    {progress?.updateBy?.userName && (
                                      <p
                                        style={{
                                          fontSize: 13,
                                          color: color.primary,
                                        }}
                                      >
                                        {`Telah diperbaharui oleh ${progress?.updateBy?.userName}`}
                                        <br />
                                        {`Tanggal : ${moment(
                                          progress?.updatedAt
                                        )
                                          .local("id")
                                          .format("LL")}`}
                                        <br />
                                        {`Jam : ${moment(
                                          progress?.updatedAt
                                        ).format("LT")}`}
                                      </p>
                                    )}
                                    <p className="text-bold mt-3">
                                      Penjelasan Status dan Kendala
                                    </p>
                                    <p>{progress?.description || "-"}</p>

                                    <p className="text-bold mt-3 mb-2">
                                      Jumlah Anggaran (dalam Juta)
                                    </p>
                                    <table className="w-100">
                                      <thead>
                                        <tr
                                          style={{ height: 52 }}
                                          className="border-top border-bottom"
                                        >
                                          <th>Sumber</th>
                                          <th className="text-center">
                                            Rencana
                                          </th>
                                          <th className="text-center">
                                            Realisasi
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr style={{ height: 39 }}>
                                          <td>APBN</td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.APBN.plan
                                            )}
                                          </td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.APBN.real
                                            )}
                                          </td>
                                        </tr>
                                        <tr style={{ height: 39 }}>
                                          <td>APBD</td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.APBD.plan
                                            )}
                                          </td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.APBD.real
                                            )}
                                          </td>
                                        </tr>
                                        <tr style={{ height: 39 }}>
                                          <td>Swasta</td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.Swasta.plan
                                            )}
                                          </td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.Swasta.real
                                            )}
                                          </td>
                                        </tr>
                                        <tr style={{ height: 39 }}>
                                          <td>Lainnya</td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.Lainnya.plan
                                            )}
                                          </td>
                                          <td align="center">
                                            {formatCurrency(
                                              calculateBudged.Lainnya.real
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                      <thead>
                                        <tr style={{ height: 39 }}>
                                          <th>Total</th>
                                          <th className="text-center">
                                            {calculateBudged.Total.plan}
                                          </th>
                                          <th className="text-center">
                                            {calculateBudged.Total.real}
                                          </th>
                                        </tr>
                                      </thead>
                                    </table>
                                    <p className="text-bold mt-3">
                                      Lokasi Kegiatan
                                    </p>
                                    {progress?.isPinPoint &&
                                    progress?.locations?.length > 0 ? (
                                      <div
                                        style={{
                                          width: "100%",
                                          paddingTop: "56.25%",
                                          position: "relative",
                                          borderRadius: 6,
                                        }}
                                        className="mt-2 mb-2"
                                      >
                                        <div
                                          style={{
                                            position: "absolute",
                                            top: 0,
                                            right: 0,
                                            bottom: 0,
                                            left: 0,
                                          }}
                                        >
                                          <GMapsCardMultiple
                                            locations={progress?.locations}
                                          />
                                        </div>
                                      </div>
                                    ) : (
                                      progress?.noteLocation || "-"
                                    )}
                                    {progress?.documents?.length > 0 && (
                                      <button
                                        className="btn mt-2"
                                        style={{
                                          color: "white",
                                          backgroundColor: color.danger,
                                          height: 48,
                                          width: "100%",
                                          paddingTop: 0,
                                          paddingBottom: 0,
                                        }}
                                        onClick={toggleDownload(
                                          progress?.documents
                                        )}
                                      >
                                        <p>Download Dokumen</p>
                                      </button>
                                    )}
                                    <div
                                      className="border-bottom"
                                      style={{
                                        marginTop: 35,
                                        marginLeft: -13,
                                        marginRight: -13,
                                      }}
                                    />
                                  </div>
                                </Collapse>
                              );
                            })}
                        </div>
                      );
                    };
                    return <ProgressList />;
                  })}
              </div>

              <div className="col-lg-4 mb-5 border-left">
                <p className="text-bold mb-3">
                  Akumulasi Anggaran Kegiatan (dalam Juta)
                </p>
                <BudgetGrouping previewData={previewData} />
              </div>
            </div>
          )}
        </div>
        <Modal
          className="modal-md"
          isOpen={isOpenDownload}
          toggle={toggleDownload(null)}
          fade={false}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Download dokumen
            </h5>
            <div>
              <button
                onClick={toggleDownload(null)}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </div>
          <div className="modal-body">
            {documents.map((docx, idx) => (
              <div
                key={idx}
                style={{
                  backgroundColor: "#f6f6f6",
                  padding: 14,
                  borderRadius: 6,
                  display: "flex",
                  alignItems: "center",
                  marginTop: 18,
                }}
              >
                <p
                  style={{
                    flex: 1,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    marginRight: 14,
                  }}
                >
                  {docx.fileUrl.replace(/^.*\/\/[^/]+\/files\//, "")}
                </p>
                <a
                  href={docx.fileUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={true}
                >
                  <i
                    className="fas fa-file-download mt-1"
                    style={{ color: color.danger, fontSize: 20 }}
                  />
                </a>
              </div>
            ))}
          </div>
        </Modal>
      </Modal>
    );
  };

  const ModalForm = () => {
    const [isOpenMap, setIsOpenMap] = useState(false);

    const uploadRef = useRef(null);
    const [activityDetailId, setActivityDetailId] = useState(null);
    const [defaultYear, setDefaultYear] = useState(null);
    const [yearId, setYearId] = useState(null);
    const [description, setDescription] = useState("");
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [locationType, setLocationType] = useState(null);
    const [isPinPoint, setIsPinPoint] = useState(false);
    const [locations, setLocations] = useState([]);
    const [locationActiveIndex, setLocationActiveIndex] = useState(0);
    const [noteLocation, setNoteLocation] = useState("");
    const [documents, setDocuments] = useState([]);
    const [APBN, setAPBN] = useState({
      checked: false,
      plan: 0,
      real: 0,
    });
    const [APBD, setAPBD] = useState({
      checked: false,
      plan: 0,
      real: 0,
    });
    const [swasta, setSwasta] = useState({
      checked: false,
      plan: 0,
      real: 0,
    });
    const [lainnya, setLainnya] = useState({
      checked: false,
      plan: 0,
      real: 0,
    });

    // const [onChangedForm, setOnChangedForm] = useState(false);
    const [isChangedForm, setIsChangedForm] = useState(false);

    const toggleMap = (locationIndex) => {
      setIsOpenMap(!isOpenMap);
      setIsChangedForm(true);
      if (!isNaN(locationIndex)) {
        setLocationActiveIndex(locationIndex);
      }
    };

    const onUpload = ({ target }) => {
      const newFile = target.files[0];
      const isValidType =
        newFile.type === "application/pdf" ||
        newFile.type === "image/png" ||
        newFile.type === "image/jpeg";
      if (isValidType) {
        const sizeInMB = (newFile.size / (1024 * 1024)).toFixed(2);
        if (sizeInMB > 20) {
          Swal.fire({
            title: "Ukuran dokumen terlalu besar",
            text: `Dokumen yang anda upload berukuran ${sizeInMB}MB, pastikan dokumen yang akan anda upload berukuran dibawah 20MB!`,
            icon: "error",
            position: "top-end",
            confirmButtonColor: color.primary,
            timer: 10000,
          });
        } else {
          dispatch(
            uploadFileRequest(newFile, (err, resp) => {
              if (err) {
                Swal.fire({
                  title: "Oops.",
                  text: "Terjadi kesalahan saat mengupload dokumen, harap perhatikan ukuran dan jenis dokumen terlebih dahulu dan silakan coba lagi!",
                  icon: "error",
                  position: "top-end",
                  confirmButtonColor: color.primary,
                  timer: 10000,
                });
              } else {
                setDocuments((prev) => [
                  ...prev.filter((val) => val.fileUrl !== resp),
                  { fileUrl: resp },
                ]);
              }
            })
          );
        }
      } else {
        Swal.fire({
          title: "Format file tidak didukung",
          text: `Format dokumen yang anda upload tidak didukung, dokumen hanya mendukung format *.pdf, *.jpg, dan *.png`,
          icon: "error",
          position: "top-end",
          confirmButtonColor: color.primary,
          timer: 10000,
        });
      }
    };

    const onSubmitStatus = () => {
      const budgets = [];
      if (APBN.checked) {
        budgets.push({
          name: "APBN",
          planing: APBN.plan,
          realization: APBN.real,
        });
      }
      if (APBD.checked) {
        budgets.push({
          name: "APBD",
          planing: APBD.plan,
          realization: APBD.real,
        });
      }
      if (swasta.checked) {
        budgets.push({
          name: "Swasta",
          planing: swasta.plan,
          realization: swasta.real,
        });
      }
      if (lainnya.checked) {
        budgets.push({
          name: "Lainnya",
          planing: lainnya.plan,
          realization: lainnya.real,
        });
      }

      const activityStatusData = {
        description,
        status: selectedStatus?.value,
        locations: isPinPoint ? locations : [],
        isPinPoint,
        noteLocation: isPinPoint ? "" : noteLocation,
        budgets,
        documents,
      };

      const callback = (err, resp) => {
        // console.log({ err, resp });
        if (err) {
          // alert
        } else {
          toggleForm(false)();
          getAndSetPreviewDetail(previewData._id);
          onRefreshDetail();
        }
      };

      if (isEdit) {
        dispatch(
          updateActivityStatusRequest(isEdit?._id, activityStatusData, callback)
        );
      } else {
        dispatch(
          createActivityStatusRequest(
            activityDetailId,
            { ...activityStatusData, yearId },
            callback
          )
        );
      }
    };

    useEffect(() => {
      if (previewData) {
        setActivityDetailId(previewData._id);
        if (isEdit) {
          setDefaultYear(
            isEdit?.year ? { value: isEdit.year, label: isEdit.year } : null
          );
          setSelectedStatus(
            isEdit?.status
              ? { value: isEdit?.status, label: isEdit?.status }
              : null
          );
          setDescription(isEdit?.description || "");
          setDocuments(isEdit?.documents || []);
          setLocations(isEdit?.locations || []);

          if (isEdit?.isPinPoint === true && isEdit?.locations?.length) {
            setLocationType({ label: "Titik Lokasi", value: true });
          } else if (isEdit?.isPinPoint === false && isEdit?.noteLocation) {
            setLocationType({ label: "Deskripsi Lokasi", value: false });
            setNoteLocation(isEdit?.noteLocation || "");
          }

          isEdit?.budgets?.forEach((budged) => {
            const newBudged = {
              checked: true,
              plan: budged?.planing || 0,
              real: budged?.realization || 0,
            };
            switch (budged?.name) {
              case "APBN":
                setAPBN(newBudged);
                break;
              case "APBD":
                setAPBD(newBudged);
                break;
              case "Swasta":
                setSwasta(newBudged);
                break;
              case "Lainnya":
                setLainnya(newBudged);
                break;
              default:
                break;
            }
          });
        }
      } else {
        setActivityDetailId(null);
      }
    }, []);

    useEffect(() => {
      setIsPinPoint(locationType?.value);
    }, [locationType]);

    return (
      <Modal
        className="modal-xl"
        isOpen={isOpenForm}
        toggle={toggleForm(false, isChangedForm)}
        fade={false}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            {isEdit ? "Edit" : "Tambah"} Status Kegiatan
          </h5>
          <div>
            <button
              onClick={toggleForm(false, isChangedForm)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-xl-6 mb-4">
              <Input
                className="mb-3"
                value={previewData?.pilar?.name}
                disabled={true}
                style={{
                  backgroundColor: "#f2f2f2",
                  minHeight: 50,
                  padding: 14,
                  height: 48,
                }}
              />
              <Input
                className="mb-3"
                value={previewData?.strategy?.name}
                disabled={true}
                style={{
                  backgroundColor: "#f2f2f2",
                  minHeight: 50,
                  padding: 14,
                  height: 48,
                }}
              />
              <Input
                className="mb-3"
                value={previewData?.program?.name}
                disabled={true}
                style={{
                  backgroundColor: "#f2f2f2",
                  minHeight: 50,
                  padding: 14,
                  height: 48,
                }}
              />
              <Input
                className="mb-3"
                value={previewData?.activity?.name}
                disabled={true}
                style={{
                  backgroundColor: "#f2f2f2",
                  minHeight: 50,
                  padding: 14,
                  height: 48,
                }}
              />
              <FormGroup style={{ marginTop: 36 }}>
                <Label style={{ fontWeight: "bold" }} for="year">
                  Tahun Pelaksanaan
                </Label>
                <Select
                  id="year"
                  name="year"
                  className="action"
                  placeholder="Pilih Tahun"
                  options={previewData?.implementations.map(
                    ({ _id, year, progress, isLock }) => ({
                      value: _id,
                      label: (
                        <div
                          style={{
                            color:
                              isLock || progress.length > 2 ? "#ccd1d9" : "",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p>{year}</p>
                          <p>{isLock && <i className="ml-2 fas fa-lock" />}</p>
                          <p>
                            {progress.length > 2 && (
                              <i className="ml-1 mdi mdi-bookmark-check mdi-18px" />
                            )}
                          </p>
                        </div>
                      ),
                      isDisabled: isLock || progress.length > 2,
                      progress: progress.map(({ status }) => status),
                    })
                  )}
                  onChange={(opt) => {
                    if (yearId !== opt?.value) setSelectedStatus(null);
                    setYearId(opt?.value || null);
                    const newStatusOptions = [
                      { value: "Perencanaan", label: "Perencanaan" },
                      { value: "Pelaksanaan", label: "Pelaksanaan" },
                      { value: "Selesai", label: "Selesai" },
                    ].filter(({ value }) => !opt?.progress?.includes(value));
                    setStatusOptions(newStatusOptions);
                    setIsChangedForm(true);
                  }}
                  defaultValue={defaultYear}
                  styles={customColorSelect2}
                  isDisabled={isEdit}
                />
              </FormGroup>
              <FormGroup style={{ marginTop: 36 }}>
                <Label style={{ fontWeight: "bold" }} for="activityStatus">
                  Status Kegiatan
                </Label>
                <Select
                  id="activityStatus"
                  name="activityStatus"
                  className="action"
                  placeholder="Pilih Status"
                  isDisabled={!yearId || isEdit}
                  options={statusOptions}
                  onChange={(val) => {
                    setSelectedStatus(val);
                    setIsChangedForm(true);
                  }}
                  value={selectedStatus}
                  styles={customColorSelect2}
                />
              </FormGroup>
              <FormGroup style={{ marginTop: 36 }}>
                <Label style={{ fontWeight: "bold" }} for="desc">
                  Penjelasan Status dan Kendala (Jika Ada)
                </Label>
                <Input
                  id="desc"
                  type="textarea"
                  placeholder="Ketik Penjelasan Status"
                  value={description}
                  onChange={({ target }) => {
                    setDescription(target.value);
                    setIsChangedForm(true);
                  }}
                  style={{
                    minHeight: 50,
                    padding: 14,
                    height: 150,
                  }}
                />
              </FormGroup>
            </div>
            <div className="col-xl-6 mb-4">
              <Label style={{ fontWeight: "bold" }}>
                Jumlah Anggaran (Dalam Juta)
              </Label>
              <div className="row pr-5 mt-3">
                <div className="col-4">Sumber</div>
                <div className="col-4">Rencana</div>
                <div className="col-4">Realisasi</div>
              </div>
              <div className="row pr-5 mt-3 align-items-center">
                <div className="col-4">
                  <CustomInput
                    id="apbn"
                    name="apbn"
                    type="checkbox"
                    label={<p>APBN</p>}
                    checked={APBN.checked}
                    onChange={() => {
                      setAPBN({ ...APBN, checked: !APBN.checked });
                      setIsChangedForm(true);
                    }}
                  />
                </div>
                <div className="col-4">
                  <Input
                    disabled={!APBN.checked}
                    style={{
                      height: 48,
                      backgroundColor: !APBN.checked ? "#f2f2f2" : "",
                    }}
                    value={APBN.checked ? formatCurrency(APBN.plan) : ""}
                    onChange={({ target }) => {
                      const newFormat = formatCurrencyToNumber(target.value);
                      if (!isNaN(newFormat)) {
                        setAPBN({
                          ...APBN,
                          plan: newFormat,
                        });
                      }
                      setIsChangedForm(true);
                    }}
                  />
                </div>
                <div className="col-4">
                  <Input
                    disabled={!APBN.checked}
                    style={{
                      height: 48,
                      backgroundColor: !APBN.checked ? "#f2f2f2" : "",
                    }}
                    value={APBN.checked ? formatCurrency(APBN.real) : ""}
                    onChange={({ target }) => {
                      const newFormat = formatCurrencyToNumber(target.value);
                      if (!isNaN(newFormat)) {
                        setAPBN({
                          ...APBN,
                          real: newFormat,
                        });
                      }
                      setIsChangedForm(true);
                    }}
                  />
                </div>
              </div>
              <div className="row pr-5 mt-3 align-items-center">
                <div className="col-4">
                  <CustomInput
                    id="apbd"
                    name="apbd"
                    type="checkbox"
                    label={<p>APBD</p>}
                    checked={APBD.checked}
                    onChange={() => {
                      setAPBD({ ...APBD, checked: !APBD.checked });
                      setIsChangedForm(true);
                    }}
                  />
                </div>
                <div className="col-4">
                  <Input
                    disabled={!APBD.checked}
                    style={{
                      height: 48,
                      backgroundColor: !APBD.checked ? "#f2f2f2" : "",
                    }}
                    value={APBD.checked ? formatCurrency(APBD.plan) : ""}
                    onChange={({ target }) => {
                      const newFormat = formatCurrencyToNumber(target.value);
                      if (!isNaN(newFormat)) {
                        setAPBD({
                          ...APBD,
                          plan: newFormat,
                        });
                      }
                      setIsChangedForm(true);
                    }}
                  />
                </div>
                <div className="col-4">
                  <Input
                    disabled={!APBD.checked}
                    style={{
                      height: 48,
                      backgroundColor: !APBD.checked ? "#f2f2f2" : "",
                    }}
                    value={APBD.checked ? formatCurrency(APBD.real) : ""}
                    onChange={({ target }) => {
                      const newFormat = formatCurrencyToNumber(target.value);
                      if (!isNaN(newFormat)) {
                        setAPBD({
                          ...APBD,
                          real: newFormat,
                        });
                      }
                      setIsChangedForm(true);
                    }}
                  />
                </div>
              </div>
              <div className="row pr-5 mt-3 align-items-center">
                <div className="col-4">
                  <CustomInput
                    id="swasta"
                    name="swasta"
                    type="checkbox"
                    label={<p>Swasta</p>}
                    checked={swasta.checked}
                    onChange={() => {
                      setSwasta({ ...swasta, checked: !swasta.checked });
                      setIsChangedForm(true);
                    }}
                  />
                </div>
                <div className="col-4">
                  <Input
                    disabled={!swasta.checked}
                    style={{
                      height: 48,
                      backgroundColor: !swasta.checked ? "#f2f2f2" : "",
                    }}
                    value={swasta.checked ? formatCurrency(swasta.plan) : ""}
                    onChange={({ target }) => {
                      const newFormat = formatCurrencyToNumber(target.value);
                      if (!isNaN(newFormat)) {
                        setSwasta({
                          ...swasta,
                          plan: newFormat,
                        });
                      }
                      setIsChangedForm(true);
                    }}
                  />
                </div>
                <div className="col-4">
                  <Input
                    disabled={!swasta.checked}
                    style={{
                      height: 48,
                      backgroundColor: !swasta.checked ? "#f2f2f2" : "",
                    }}
                    value={swasta.checked ? formatCurrency(swasta.real) : ""}
                    onChange={({ target }) => {
                      const newFormat = formatCurrencyToNumber(target.value);
                      if (!isNaN(newFormat)) {
                        setSwasta({
                          ...swasta,
                          real: newFormat,
                        });
                      }
                      setIsChangedForm(true);
                    }}
                  />
                </div>
              </div>
              <div className="row pr-5 mt-3 align-items-center">
                <div className="col-4">
                  <CustomInput
                    id="lainnya"
                    name="lainnya"
                    type="checkbox"
                    label={<p>Lainnya</p>}
                    checked={lainnya.checked}
                    onChange={() => {
                      setLainnya({ ...lainnya, checked: !lainnya.checked });
                      setIsChangedForm(true);
                    }}
                  />
                </div>
                <div className="col-4">
                  <Input
                    disabled={!lainnya.checked}
                    style={{
                      height: 48,
                      backgroundColor: !lainnya.checked ? "#f2f2f2" : "",
                    }}
                    value={lainnya.checked ? formatCurrency(lainnya.plan) : ""}
                    onChange={({ target }) => {
                      const newFormat = formatCurrencyToNumber(target.value);
                      if (!isNaN(newFormat)) {
                        setLainnya({
                          ...lainnya,
                          plan: newFormat,
                        });
                      }
                      setIsChangedForm(true);
                    }}
                  />
                </div>
                <div className="col-4">
                  <Input
                    disabled={!lainnya.checked}
                    style={{
                      height: 48,
                      backgroundColor: !lainnya.checked ? "#f2f2f2" : "",
                    }}
                    value={lainnya.checked ? formatCurrency(lainnya.real) : ""}
                    onChange={({ target }) => {
                      const newFormat = formatCurrencyToNumber(target.value);
                      if (!isNaN(newFormat)) {
                        setLainnya({
                          ...lainnya,
                          real: newFormat,
                        });
                      }
                      setIsChangedForm(true);
                    }}
                  />
                </div>
              </div>
              <Label
                className="mt-5"
                style={{ fontWeight: "bold", display: "block" }}
              >
                Lokasi Kegiatan
              </Label>
              <Select
                id="locationType"
                name="locationType"
                className="action"
                placeholder="Pilih Tipe Lokasi"
                options={[
                  { label: "Deskripsi Lokasi", value: false },
                  { label: "Titik Lokasi", value: true },
                ]}
                onChange={setLocationType}
                value={locationType}
                // defaultValue={defaultYear}
                styles={customColorSelect2}
                // isDisabled={isEdit}
                isClearable
              />
              {locationType?.value === false && (
                <Input
                  id="noteLocation"
                  type="textarea"
                  placeholder="Ketik Deskripsi Lokasi"
                  value={noteLocation}
                  onChange={({ target }) => {
                    setNoteLocation(target.value);
                    setIsChangedForm(true);
                  }}
                  style={{
                    minHeight: 50,
                    padding: 14,
                    height: 150,
                    marginTop: 30,
                  }}
                />
              )}
              {locationType?.value === true && (
                <>
                  {Boolean(locations.length) && (
                    <div className="row mr-2 ml-1 align-items-center">
                      <div
                        style={{
                          width: "100%",
                          paddingTop: "56.25%",
                          position: "relative",
                          borderRadius: 6,
                        }}
                        className="mt-2"
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                          }}
                        >
                          <GMapsCardMultiple
                            locations={locations}
                            locationActiveIndex={locationActiveIndex}
                            setLocationActiveIndex={setLocationActiveIndex}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  {locations.map((loc, locIndex) => (
                    <div
                      key={locIndex}
                      style={{
                        backgroundColor: "#f6f6f6",
                        padding: 6,
                        borderRadius: 6,
                        display: "flex",
                        alignItems: "center",
                        marginTop: 18,
                        cursor: "pointer",
                      }}
                      onClick={() => setLocationActiveIndex(locIndex)}
                    >
                      <p
                        style={{
                          flex: 1,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          marginRight: 14,
                          marginLeft: 14,
                          color:
                            locIndex === locationActiveIndex
                              ? color.primary
                              : "#8e8e95",
                        }}
                      >
                        {`lat: ${loc.latitude} - lng: ${loc.longitude}`}
                      </p>
                      <div>
                        <Popconfirm
                          title={"Ingin menghapus lokasi ini?"}
                          onConfirm={() => {
                            setLocations((prev) => {
                              const newLocations = [...prev];
                              newLocations.splice(locIndex, 1);
                              return newLocations;
                            });
                            setLocationActiveIndex(locations.length);
                          }}
                          okText="Hapus"
                          cancelText="Batal"
                          okButtonProps={{
                            danger: true,
                            icon: <i className="far fa-trash-alt mr-1" />,
                          }}
                        >
                          <button
                            className="btn mr-2"
                            style={{
                              color: "white",
                              backgroundColor: color.error,
                              height: 36,
                              width: 36,
                              padding: 0,
                            }}
                          >
                            <i className="far fa-trash-alt" />
                          </button>
                        </Popconfirm>

                        <button
                          className="btn"
                          style={{
                            color: "white",
                            backgroundColor: color.primary,
                            height: 36,
                            width: 36,
                            padding: 0,
                          }}
                          onClick={() => toggleMap(locIndex)}
                        >
                          <i className="fas fa-edit" />
                        </button>
                      </div>
                    </div>
                  ))}
                  <button
                    className="btn mt-4"
                    style={{
                      backgroundColor: color.primary,
                      color: "white",
                      height: 42,
                      width: 150,
                    }}
                    onClick={() => toggleMap(locations.length)}
                  >
                    Tambah Lokasi
                  </button>
                </>
              )}
              <ModalMap
                location={
                  locations[locationActiveIndex] ||
                  locations[locationActiveIndex - 1]
                }
                isOpenMap={isOpenMap}
                toggleMap={toggleMap}
                setLocations={setLocations}
                locationActiveIndex={locationActiveIndex}
              />
              <Label className="mt-5 d-flex" style={{ fontWeight: "bold" }}>
                Dokumen Kegiatan
              </Label>
              <p>
                <small>
                  Masimal ukuran adalah 20MB/dokumen dengan format *.pdf / *.jpg
                  / *.png
                </small>
              </p>
              {documents.map((docx, idx) => (
                <div
                  key={idx}
                  style={{
                    backgroundColor: "#f6f6f6",
                    padding: 14,
                    borderRadius: 6,
                    display: "flex",
                    alignItems: "center",
                    marginTop: 18,
                  }}
                >
                  <p
                    style={{
                      flex: 1,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      marginRight: 14,
                    }}
                  >
                    {docx.fileUrl.replace(/^.*\/\/[^/]+\/files\//, "")}
                  </p>
                  <Popconfirm
                    placement="left"
                    title={"Ingin menghapus dokumen ini?"}
                    onConfirm={() => {
                      setDocuments((prev) =>
                        prev.filter((val) => val.fileUrl !== docx.fileUrl)
                      );
                      setIsChangedForm(true);
                    }}
                    okText="Hapus"
                    cancelText="Batal"
                    okButtonProps={{
                      danger: true,
                      icon: <i className="far fa-trash-alt mr-1" />,
                    }}
                  >
                    <div style={{ cursor: "pointer" }}>
                      <i
                        className="fas fa-window-close mt-1"
                        style={{ color: "red", fontSize: 20 }}
                      />
                    </div>
                  </Popconfirm>
                </div>
              ))}
              <input
                ref={uploadRef}
                type="file"
                name="document"
                placeholder="Upload"
                hidden
                onChange={onUpload}
              />
              <button
                className="btn mt-3"
                style={{
                  backgroundColor: color.primary,
                  color: "white",
                  height: 42,
                  width: 150,
                }}
                onClick={() => {
                  uploadRef.current.click();
                  setIsChangedForm(true);
                }}
              >
                Upload Dokumen
              </button>
            </div>
          </div>
          <div className="row mt-3 mb-4 justify-content-center">
            <button
              onClick={toggleForm(false)}
              className="btn mr-2"
              style={{
                backgroundColor: color.error,
                color: "white",
                height: 42,
                width: 250,
              }}
            >
              Batal
            </button>
            <button
              onClick={onSubmitStatus}
              className="btn ml-2"
              style={{
                backgroundColor: "#7fd051",
                color: "white",
                height: 42,
                width: 250,
              }}
              disabled={!selectedStatus}
            >
              Simpan
            </button>
          </div>
        </div>
      </Modal>
    );
  };

  const ModalMap = ({
    location,
    toggleMap,
    isOpenMap,
    setLocations,
    locationActiveIndex,
  }) => {
    return (
      <Modal
        fade={false}
        className="modal-lg"
        isOpen={isOpenMap}
        toggle={toggleMap}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Pilih Lokasi
          </h5>
          <div>
            <button
              onClick={toggleMap}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div style={{ width: "100%", height: 500 }}>
            <GMapsSelect
              lat={location?.latitude}
              lng={location?.longitude}
              getCenterCoordinate={(center) => {
                if (center) {
                  setLocations((prev) => {
                    const newLocations = [...prev];
                    newLocations.splice(locationActiveIndex, 1, {
                      latitude: center?.lat,
                      longitude: center.lng,
                    });
                    return newLocations;
                  });
                }
              }}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const Tooltip = () => <ReactTooltip />;

  return (
    <>
      <ButtonAndFilters />
      <TableDetailPilar />
      <ModalInChargeList />
      <ModalPreview />
      <ModalForm />
      <ModalReport
        user={user}
        isOpenReport={isOpenReport}
        setIsOpenReport={setIsOpenReport}
      />
    </>
  );
};

export default Detail;

const ModalReport = ({ user, isOpenReport, setIsOpenReport }) => {
  const dispatch = useDispatch();
  /* LOCAL STATE */
  const [defaultPilarValue] = useState({ value: 1, label: "Pilar 1" });
  const filterPilarOptions = useFilterPilarOptions(false);
  const [filterPilarValue, setFilterPilarValue] = useState(defaultPilarValue);

  const [defaultInCharge] = useSelector(
    (state) => state?.Institution?.institutions
  );
  const filterInChargeOptions = useFilterInChangeOptions(true);
  // const [defaultInChargerValue] = useState(filterInChargeOptions[1]);
  // const filterInChargeOptions = [defaultInChargerValue];
  // const [filterInChargeValue, setFilterInChargeValue] = useState(filterInChargeOptions[1]);
  const [filterInChargeValue, setFilterInChargeValue] = useState({
    value: defaultInCharge?._id,
    label: defaultInCharge?.name,
  });

  const nowYear = new Date().getFullYear();

  const filterStartYearOptions = useFilterYearOptions();
  const [filterStartYearValue, setFilterStartValue] = useState({
    value: nowYear,
    label: nowYear.toString(),
  });

  const filterEndYearOptions = useFilterYearOptions(
    filterStartYearValue?.value || 2018
  );
  const [filterEndYearValue, setFilterEndYearValue] = useState({
    value: nowYear,
    label: nowYear.toString(),
  });

  /* GLOBAL STATE */
  const { report, isLoadingReport } = useSelector((state) => state.Detail);

  /* FUNCTIONS */
  const onChangeFilter = (option, { name, action }) => {
    switch (name) {
      case "pilar":
        setFilterPilarValue(option || defaultPilarValue);
        break;
      case "in-charge":
        setFilterInChargeValue(option || filterInChargeOptions[1]);
        break;
      case "start-year":
        setFilterStartValue(option);
        if (!option) {
          setFilterEndYearValue(null);
        } else if (filterEndYearValue?.value < option?.value) {
          setFilterEndYearValue(option);
        }
        break;
      case "end-year":
        setFilterEndYearValue(option);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (isOpenReport) {
      const queryString = {
        page: filterPilarValue.value,
        unit:
          user.role === "admin"
            ? user?.institusi?._id
            : filterInChargeValue?.value || "none",
        start: filterStartYearValue?.value || 2018,
        end: filterEndYearValue?.value || 2029,
      };
      // console.log({ queryString });
      dispatch(getReportRequest(queryString));
    }
    // eslint-disable-next-line
  }, [
    isOpenReport,
    filterPilarValue,
    filterInChargeValue,
    filterStartYearValue,
    filterEndYearValue,
  ]);

  const ViewsAndFilters = () => (
    <div className="actions-group justify-space-between">
      <div className="action-container">
        <ReactHTMLTableToExcel
          id="test-table-xls-button"
          className="btn btn-warning"
          table="teport-table"
          filename="laporan"
          sheet="laporan"
          buttonText="Download"
        />
      </div>
      <div className="action-container">
        <p>Filter by :</p>
        <Select
          name="pilar"
          className="action filter-pilar-width mr-2"
          placeholder="Pilar"
          value={filterPilarValue}
          onChange={onChangeFilter}
          options={filterPilarOptions}
          styles={customColorSelect}
        />
        {user?.role !== "admin" && (
          <Select
            name="in-charge"
            className="action filter-in-change-width mr-2"
            placeholder="Pilih Penanggung Jawab"
            value={filterInChargeValue}
            onChange={onChangeFilter}
            options={filterInChargeOptions}
            // isClearable={filterInChargeValue?.value !== "all"}
            styles={customColorSelect}
          />
        )}
        <Select
          name="start-year"
          className="action filter-year mr-2"
          placeholder="Tahun Awal"
          value={filterStartYearValue}
          onChange={onChangeFilter}
          options={filterStartYearOptions}
          // isClearable
          styles={customColorSelect}
        />
        <p>-</p>
        <Select
          name="end-year"
          className="action filter-year"
          placeholder="Tahun Akhir"
          value={filterEndYearValue}
          onChange={onChangeFilter}
          options={filterEndYearOptions}
          // isClearable
          isDisabled={!filterStartYearValue}
          styles={customColorSelect}
        />
      </div>
    </div>
  );

  const RowHeader = () => (
    <>
      <tr>
        <td colSpan={3} />
        <td colSpan={12}>SPIRIT Jabodetabek</td>
      </tr>
      <tr>
        <td colSpan={3} />
        <td colSpan={12}>LAPORAN STATUS KEGIATAN RITJ</td>
      </tr>
      <tr>
        <td colSpan={15} />
      </tr>
    </>
  );

  const RowFilter = () => (
    <>
      <tr>
        <td colSpan={3} />
        <td colSpan={12}>{`Periode: Tahun ${
          filterStartYearValue?.label || "2018"
        } - ${filterEndYearValue?.label || "2029"}`}</td>
      </tr>
      <tr>
        <td colSpan={3} />
        <td colSpan={12}>{`Penanggung Jawab : ${
          user.role === "admin"
            ? user?.institusi?.name
            : filterInChargeValue?.label
        }`}</td>
      </tr>
    </>
  );

  const RowPilar = ({ pilarNumber, pilarName }) => (
    <tr>
      <td colSpan={3} />
      <td colSpan={12}>{`Pilar ${pilarNumber} : ${pilarName}`}</td>
    </tr>
  );

  const styles = {
    borderCollapse: {
      // border: "solid 0.5px black",
      border: "solid",
      borderWidth: 0.5,
      borderColor: "black",
      borderCollapse: "collapse",
    },
    borderLeft: {
      borderLeft: "solid 0.5px black",
    },
    borderVertical: {
      borderTop: "solid 0.5px black",
      borderBottom: "solid 0.5px black",
    },
    verticalAlign: {
      verticalAlign: "middle",
    },
    bgYear: {
      backgroundColor: "#8a7ebe",
    },
    bgGray: {
      backgroundColor: "#f3f3f3",
    },
    bgPlaning: {
      backgroundColor: "#7aa7d7",
    },
    bgDoing: {
      backgroundColor: "#fbe5a2",
    },
    bgDone: {
      backgroundColor: "#bdd5ac",
    },
  };

  const RowMainHeader = () => (
    <>
      <tr>
        <th style={styles.borderCollapse} colSpan={3}>
          NO
        </th>
        <th style={styles.borderCollapse} colSpan={12} align="left">
          STRATEGI / PROGRAM / KEGIATAN
        </th>
      </tr>
    </>
  );

  const RowStrategy = ({ strategyNumber, strategyName }) => (
    <tr>
      <td
        style={{ ...styles.borderLeft, ...styles.borderVertical }}
        align="center"
      >{`${strategyNumber}`}</td>
      <td style={styles.borderVertical} />
      <td style={styles.borderVertical} />
      <td style={styles.borderCollapse} colSpan={12} align="left">
        {strategyName}
      </td>
    </tr>
  );

  const RowProgram = ({ programNumber, programName }) => (
    <tr>
      <td style={{ ...styles.borderLeft, ...styles.borderVertical }} />
      <td style={styles.borderVertical} align="center">{`${String.fromCharCode(
        programNumber + 64
      )}`}</td>
      <td style={styles.borderVertical} />
      <td style={styles.borderCollapse} colSpan={12} align="left">
        {programName}
      </td>
    </tr>
  );

  const RowActivity = ({ activityNumber, activityName }) => (
    <tr>
      <td style={{ ...styles.borderLeft, ...styles.borderVertical }} />
      <td />
      <td
        style={styles.borderVertical}
        align="center"
      >{`${activityNumber})`}</td>
      <td
        style={{ ...styles.borderVertical, ...styles.borderCollapse }}
        colSpan={12}
        align="left"
      >
        {activityName}
      </td>
    </tr>
  );

  const RowInCharge = ({ inChargeName }) => (
    <>
      <tr>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          rowSpan={2}
          colSpan={3}
        />
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray, minWidth: 200 }}
          rowSpan={2}
          align="left"
        >
          {inChargeName}
        </th>
        <th style={{ ...styles.borderCollapse, ...styles.bgGray }} rowSpan={2}>
          TAHUN
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
          rowSpan={2}
        >
          STATUS
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
          rowSpan={2}
        >
          KETERANGAN STATUS / KENDALA
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
          colSpan={2}
        >
          APBN
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
          colSpan={2}
        >
          APBD
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
          colSpan={2}
        >
          Swasta
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
          colSpan={2}
        >
          Lainnya
        </th>
      </tr>
      <tr>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
        >
          RENCANA
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
        >
          REALISASI
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
        >
          RENCANA
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
        >
          REALISASI
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
        >
          RENCANA
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
        >
          REALISASI
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
        >
          RENCANA
        </th>
        <th
          style={{ ...styles.borderCollapse, ...styles.bgGray }}
          align="center"
        >
          REALISASI
        </th>
      </tr>
    </>
  );

  const YearRow = ({ year, progress, calculateFund }) => {
    const totalProgress = progress?.length || 1;
    const YearData = () => (
      <>
        <td style={styles.borderCollapse} colSpan={3} rowSpan={totalProgress} />
        <td style={styles.borderCollapse} rowSpan={totalProgress} />
        <td
          style={{
            ...styles.borderCollapse,
            ...styles.verticalAlign,
            ...styles.bgYear,
          }}
          align="center"
          rowSpan={totalProgress}
        >
          {year}
        </td>
      </>
    );

    const DataProgressEmpty = () => (
      <>
        <td style={styles.borderCollapse}></td>
        <td style={styles.borderCollapse}></td>
        <td style={styles.borderCollapse}></td>
        <td style={styles.borderCollapse}></td>
        <td style={styles.borderCollapse}></td>
        <td style={styles.borderCollapse}></td>
        <td style={styles.borderCollapse}></td>
        <td style={styles.borderCollapse}></td>
        <td style={styles.borderCollapse}></td>
        <td style={styles.borderCollapse}></td>
      </>
    );

    const DataProgress = ({ status, description, budgets }) => {
      const fund = {};
      if (budgets?.length) {
        budgets.forEach(({ name, planing, realization }) => {
          fund[name] = { planing, realization };
        });
      }

      let bgStatus =
        status === "Perencanaan"
          ? styles.bgPlaning
          : status === "Pelaksanaan"
          ? styles.bgDoing
          : status === "Selesai"
          ? styles.bgDone
          : {};

      return (
        isOpenReport && (
          <>
            <td
              style={{
                ...styles.borderCollapse,
                ...styles.verticalAlign,
                ...bgStatus,
              }}
              align="center"
            >
              {status}
            </td>
            <td style={{ ...styles.borderCollapse, minWidth: 300 }}>
              {description || "-"}
            </td>
            <td
              style={{ ...styles.borderCollapse, ...styles.verticalAlign }}
              align="center"
            >
              {formatCurrency(fund?.APBN?.planing || 0)}
            </td>
            <td
              style={{ ...styles.borderCollapse, ...styles.verticalAlign }}
              align="center"
            >
              {formatCurrency(fund?.APBN?.realization || 0)}
            </td>
            <td
              style={{ ...styles.borderCollapse, ...styles.verticalAlign }}
              align="center"
            >
              {formatCurrency(fund?.APBD?.planing || 0)}
            </td>
            <td
              style={{ ...styles.borderCollapse, ...styles.verticalAlign }}
              align="center"
            >
              {formatCurrency(fund?.APBD?.realization || 0)}
            </td>
            <td
              style={{ ...styles.borderCollapse, ...styles.verticalAlign }}
              align="center"
            >
              {formatCurrency(fund?.Swasta?.planing || 0)}
            </td>
            <td
              style={{ ...styles.borderCollapse, ...styles.verticalAlign }}
              align="center"
            >
              {formatCurrency(fund?.Swasta?.realization || 0)}
            </td>
            <td
              style={{ ...styles.borderCollapse, ...styles.verticalAlign }}
              align="center"
            >
              {formatCurrency(fund?.Lainnya?.planing || 0)}
            </td>
            <td
              style={{ ...styles.borderCollapse, ...styles.verticalAlign }}
              align="center"
            >
              {formatCurrency(fund?.Lainnya?.realization || 0)}
            </td>
          </>
        )
      );
    };

    return (
      <Fragment>
        {progress?.length > 0 ? (
          progress.map((progressDetail, progressDetailIndex) => {
            const { status, description, budgets } = progressDetail;
            return (
              <Fragment key={progressDetailIndex}>
                <tr>
                  {progressDetailIndex === 0 && <YearData />}
                  <DataProgress
                    status={status}
                    description={description}
                    budgets={budgets}
                  />
                </tr>
              </Fragment>
            );
          })
        ) : (
          <tr>
            <YearData />
            <DataProgressEmpty />
          </tr>
        )}
      </Fragment>
    );
  };

  const RowTotal = ({ totalFund }) => {
    return (
      <tr>
        <th style={styles.borderCollapse} colSpan={3} />
        <th style={styles.borderCollapse} colSpan={4} align="center">
          TOTAL ANGGARAN (DALAM JUTA)
        </th>
        <th style={styles.borderCollapse} align="center">
          {formatCurrency(totalFund?.APBN?.planing || 0)}
        </th>
        <th style={styles.borderCollapse} align="center">
          {formatCurrency(totalFund?.APBN?.realization || 0)}
        </th>
        <th style={styles.borderCollapse} align="center">
          {formatCurrency(totalFund?.APBD?.planing || 0)}
        </th>
        <th style={styles.borderCollapse} align="center">
          {formatCurrency(totalFund?.APBD?.realization || 0)}
        </th>
        <th style={styles.borderCollapse} align="center">
          {formatCurrency(totalFund?.Swasta?.planing || 0)}
        </th>
        <th style={styles.borderCollapse} align="center">
          {formatCurrency(totalFund?.Swasta?.realization || 0)}
        </th>
        <th style={styles.borderCollapse} align="center">
          {formatCurrency(totalFund?.Lainnya?.planing || 0)}
        </th>
        <th style={styles.borderCollapse} align="center">
          {formatCurrency(totalFund?.Lainnya?.realization || 0)}
        </th>
      </tr>
    );
  };

  return (
    <Modal
      fade={false}
      className="modal-xl"
      isOpen={isOpenReport}
      toggle={() => {
        setIsOpenReport(!isOpenReport);
      }}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Preview Laporan
        </h5>
        <div>
          <button
            onClick={() => {
              setIsOpenReport(!isOpenReport);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </div>
      <div className="modal-body">
        <ViewsAndFilters />
        <div
          className="border p-3"
          style={{
            overflow: isLoadingReport ? "hidden" : "auto",
            maxHeight: "60vh",
            position: "relative",
          }}
        >
          <table id="teport-table">
            <RowHeader />
            <RowFilter />
            {report?.length > 0 &&
              report.map((pilar, pilarIndex) => {
                const { pilarNumber, name: pilarName, strategies } = pilar;
                return (
                  <Fragment key={pilarIndex}>
                    <RowPilar pilarNumber={pilarNumber} pilarName={pilarName} />
                    <RowMainHeader />
                    {strategies?.length > 0 &&
                      strategies
                        .filter((strategy) => {
                          let filterResult = false;
                          for (let i = 0; i < strategy?.programs?.length; i++) {
                            for (
                              let j = 0;
                              j < strategy?.programs[i]?.activities?.length;
                              j++
                            ) {
                              if (
                                strategy?.programs[i]?.activities[j]
                                  ?.activityDetail?.length
                              ) {
                                filterResult = true;
                                break;
                              }
                            }
                          }
                          return filterResult;
                        })
                        .map((strategy, strategyIndex) => {
                          const { name: strategyName, programs } = strategy;
                          return (
                            <Fragment key={strategyIndex}>
                              <RowStrategy
                                strategyNumber={strategyIndex + 1}
                                strategyName={strategyName}
                              />
                              {programs?.length > 0 &&
                                programs
                                  .filter((program) => {
                                    let result = false;
                                    for (
                                      let i = 0;
                                      i < program?.activities?.length;
                                      i++
                                    ) {
                                      if (
                                        program?.activities?.[i]?.activityDetail
                                          ?.length
                                      ) {
                                        result = true;
                                        break;
                                      }
                                    }
                                    return result;
                                  })
                                  .map((program, programIndex) => {
                                    const { name: programName, activities } =
                                      program;
                                    return (
                                      <Fragment key={programIndex}>
                                        <RowProgram
                                          programNumber={programIndex + 1}
                                          programName={programName}
                                        />
                                        {activities?.length > 0 &&
                                          activities
                                            .filter(
                                              (activity) =>
                                                activity?.activityDetail?.length
                                            )
                                            .map((activity, activityIndex) => {
                                              const {
                                                name: activityName,
                                                activityDetail,
                                              } = activity;
                                              return (
                                                <Fragment key={activityIndex}>
                                                  <RowActivity
                                                    activityNumber={
                                                      activityIndex + 1
                                                    }
                                                    activityName={activityName}
                                                  />
                                                  {activityDetail?.length > 0 &&
                                                    activityDetail.map(
                                                      (
                                                        {
                                                          implementations,
                                                          inCharge,
                                                        },
                                                        activityDetailIndex
                                                      ) => {
                                                        const {
                                                          name: inChargeName,
                                                        } = inCharge;
                                                        const totalFund = {
                                                          APBN: {
                                                            planing: 0,
                                                            realization: 0,
                                                          },
                                                          APBD: {
                                                            planing: 0,
                                                            realization: 0,
                                                          },
                                                          Swasta: {
                                                            planing: 0,
                                                            realization: 0,
                                                          },
                                                          Lainnya: {
                                                            planing: 0,
                                                            realization: 0,
                                                          },
                                                        };
                                                        implementations?.forEach(
                                                          ({ progress }) => {
                                                            progress?.forEach(
                                                              ({ budgets }) => {
                                                                budgets?.forEach(
                                                                  ({
                                                                    name,
                                                                    planing,
                                                                    realization,
                                                                  }) => {
                                                                    totalFund[
                                                                      name
                                                                    ].planing +=
                                                                      planing;
                                                                    totalFund[
                                                                      name
                                                                    ].realization +=
                                                                      realization;
                                                                  }
                                                                );
                                                              }
                                                            );
                                                          }
                                                        );
                                                        return (
                                                          <Fragment
                                                            key={
                                                              activityDetailIndex
                                                            }
                                                          >
                                                            <RowInCharge
                                                              inChargeName={
                                                                inChargeName
                                                              }
                                                            />
                                                            {implementations?.length >
                                                              0 &&
                                                              implementations.map(
                                                                (
                                                                  implementation,
                                                                  implementationIndex
                                                                ) => {
                                                                  const {
                                                                    year,
                                                                    progress,
                                                                  } = implementation;
                                                                  return (
                                                                    <Fragment
                                                                      key={
                                                                        implementationIndex
                                                                      }
                                                                    >
                                                                      <YearRow
                                                                        year={
                                                                          year
                                                                        }
                                                                        progress={
                                                                          progress
                                                                        }
                                                                      />
                                                                    </Fragment>
                                                                  );
                                                                }
                                                              )}
                                                            <RowTotal
                                                              totalFund={
                                                                totalFund
                                                              }
                                                            />
                                                          </Fragment>
                                                        );
                                                      }
                                                    )}
                                                </Fragment>
                                              );
                                            })}
                                      </Fragment>
                                    );
                                  })}
                            </Fragment>
                          );
                        })}
                    <tr />
                  </Fragment>
                );
              })}
          </table>
          <BlockSpinner show={isLoadingReport} />
        </div>
      </div>
    </Modal>
  );
};

export const BudgetGrouping = ({ previewData }) => {
  const initialFundGroup = {
    APBN: {
      Perencanaan: { plan: 0, real: 0 },
      Pelaksanaan: { plan: 0, real: 0 },
      Selesai: { plan: 0, real: 0 },
      Total: { plan: 0, real: 0 },
    },
    APBD: {
      Perencanaan: { plan: 0, real: 0 },
      Pelaksanaan: { plan: 0, real: 0 },
      Selesai: { plan: 0, real: 0 },
      Total: { plan: 0, real: 0 },
    },
    Swasta: {
      Perencanaan: { plan: 0, real: 0 },
      Pelaksanaan: { plan: 0, real: 0 },
      Selesai: { plan: 0, real: 0 },
      Total: { plan: 0, real: 0 },
    },
    Lainnya: {
      Perencanaan: { plan: 0, real: 0 },
      Pelaksanaan: { plan: 0, real: 0 },
      Selesai: { plan: 0, real: 0 },
      Total: { plan: 0, real: 0 },
    },
  };

  const [fundGroup, setFundGroup] = useState(initialFundGroup);

  useEffect(() => {
    if (previewData) {
      const newFundGroup = { ...initialFundGroup };
      previewData?.implementations
        .filter((val) => val.progress.length)
        .forEach(({ progress }) => {
          progress
            .filter(
              (a) =>
                a.status === "Perencanaan" ||
                a.status === "Pelaksanaan" ||
                a.status === "Selesai"
            )
            .forEach(({ status, budgets }) => {
              budgets.forEach(({ name, planing, realization }) => {
                newFundGroup[name][status].plan += planing;
                newFundGroup[name][status].real += realization;
                newFundGroup[name].Total.plan += planing;
                newFundGroup[name].Total.real += realization;
              });
            });
        });
      setFundGroup(newFundGroup);
    }
  }, [previewData]); // eslint-disable-line

  return (
    <>
      {Object.entries(fundGroup).map(([key, value], index) => (
        <div key={index}>
          <table className="w-100">
            <thead>
              <tr style={{ height: 52 }} className="border-top border-bottom">
                <th>
                  <div className="d-flex align-items-center">
                    <i
                      className="mdi mdi-bookmark mdi-24px mr-2"
                      style={{ color: fundColors[index] }}
                    />
                    <p>{key}</p>
                  </div>
                </th>
                <th className="text-center">Rencana</th>
                <th className="text-center">Realisasi</th>
              </tr>
            </thead>
            <tbody>
              <tr style={{ height: 39 }}>
                <td>Perencanaan</td>
                <td align="center">{value.Perencanaan.plan}</td>
                <td align="center">{value.Perencanaan.real}</td>
              </tr>
              <tr style={{ height: 39 }}>
                <td>Pelaksanaan</td>
                <td align="center">{value.Pelaksanaan.plan}</td>
                <td align="center">{value.Pelaksanaan.real}</td>
              </tr>
              <tr style={{ height: 39 }}>
                <td>Selesai</td>
                <td align="center">{value.Selesai.plan}</td>
                <td align="center">{value.Selesai.real}</td>
              </tr>
            </tbody>
            <thead>
              <tr style={{ height: 39 }}>
                <th>Total</th>
                <th className="text-center">{value.Total.plan}</th>
                <th className="text-center">{value.Total.real}</th>
              </tr>
            </thead>
          </table>
        </div>
      ))}
    </>
  );
};
