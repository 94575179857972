import axios from "axios";
import store from "./index";
import { baseUrl } from "../helpers/config";
import { USER_LOGOUT_SUCCESS } from "./auth/actionTypes";
import Swal from "sweetalert2";
import { color } from "../helpers/colors";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = store.getState()?.Auth?.user?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/api/user/login" && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const rs = await axios.post(`${baseUrl}/api/user/refresh-token`, {
            refreshToken: store.getState()?.Auth?.user?.refreshToken,
          });
          const { token } = rs.data;
          store.dispatch({ type: "REFRESH_TOKEN", payload: token });
          return instance(originalConfig);
        } catch (_error) {
          store.dispatch({ type: USER_LOGOUT_SUCCESS });
          Swal.fire({
            title: "Waktu Sesi Habis.",
            text: "Waktu sesi telah habis, silakan login kembali untuk melanjutkan aktivitas!",
            icon: "error",
            position: "top-end",
            confirmButtonColor: color.primary,
          });
          return Promise.reject(_error);
        }
      }
    }
    return Promise.reject(err);
  }
);

export default instance;
